//#region react import
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//#endregion
//#region component import
//#endregion

//#region function import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { FOOTER_LINE_COUNT, PDF_FOOTER_PLACEHOLDER } from "./EditPdfFooter.constants";
//#endregion

//#region styles import
import "./EditPdfFooter.style.scss";
//#endregion

/**
 * A component to modify the pdf footer
 */
const EditPdfFooter = ({ pdfFooter, setPdfFooter }) => {
  //#region useState
  const [editMode, setEditMode] = useState(false);
  const [ligneValues, setLigneValues] = useState(["", "", "", "", ""]);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const handleChangeEdditMode = () => {
    setEditMode(!editMode);
  };

  const setInput = (index, newValue) => {
    const newLigneValues = copyObject(ligneValues);
    newLigneValues[index] = newValue;
    setLigneValues(newLigneValues);
  };

  const handleValidateClick = async () => {
    let finalePdfFooter = "";
    ligneValues.forEach((lignValue) => {
      finalePdfFooter += lignValue + "\n";
    });
    await setPdfFooter(finalePdfFooter.trim());
    handleChangeEdditMode();
  };

  const renderPdfFooterInput = () => {
    if (editMode) {
      const split = PDF_FOOTER_PLACEHOLDER.split("\n");
      return (
        <>
          <form
            className="input-container"
            onSubmit={(event) => {
              event.preventDefault();
              handleValidateClick();
            }}
          >
            {ligneValues.map((lignValue, index) => (
              <div
                key={`input-${index}`}
                className="gsta-input gsta-input_row"
              >
                <label>{`${getTranslations("lign", translations)} ${index}`}</label>
                <input
                  value={lignValue}
                  onChange={(event) => setInput(index, event.target.value)}
                  placeholder={split[index]}
                />
              </div>
            ))}
          </form>
          <div className="button-container">
            <button
              className="gsta-button-outlined"
              onClick={handleChangeEdditMode}
            >
              <span>{getTranslations("common_cancel", translations)}</span>
              <i className="icon-undo" />
            </button>
            <button
              className="gsta-button-fill"
              onClick={handleValidateClick}
            >
              <span>{getTranslations("common_validate", translations)}</span>
              <i className="icon-check" />
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="lign-container"
            onClick={handleChangeEdditMode}
          >
            {(!pdfFooter || pdfFooter.trim().length === 0 ? PDF_FOOTER_PLACEHOLDER : pdfFooter)
              .split("\n")
              .map((lign) => (
                <span>{lign}</span>
              ))}
          </div>
          <div className="button-container">
            <button
              className="gsta-button-fill"
              onClick={handleChangeEdditMode}
            >
              <span>{getTranslations("modify", translations)}</span>
              <i class="icon-edit" />
            </button>
          </div>
        </>
      );
    }
  };

  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (pdfFooter) {
      const newLinesValues = pdfFooter.split("\n");
      for (let index = newLinesValues.length; index < FOOTER_LINE_COUNT; index++) {
        newLinesValues.push("");
      }
      setLigneValues(newLinesValues);
    }
  }, [pdfFooter]);
  //#endregion
  return <section className="edit-pdf-footer">{renderPdfFooterInput()}</section>;
};
export default EditPdfFooter;
