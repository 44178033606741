import axios from "axios";
import {
  MONTHLY_ACTIVITY,
  CENTERS,
  OPTION_NUMBER_OF_ELEMENT_PER_PAGE,
  FILTER_PAGE_NUMBER,
  CUSTOMERS,
  CURRENT_ACTIVITY,
} from "../Constants/ApiConstants";
import { getCenterResponse1, getCenterResponse2, getCenterResponse3 } from "./MockService/CenterServiceMock";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

let responseSended = 2;

export const getCenter = async (customerId, pageNumber, numberOfElementPerPage) => {
  if (process.env.NODE_ENV === "test") {
    if (responseSended === 2) {
      responseSended = 1;
      return getCenterResponse1;
    } else {
      responseSended = 2;
      return getCenterResponse2;
    }
  }

  const { data } = await axios.get(
    getApiServerProPath() +
    CUSTOMERS +
    "/" +
    customerId +
    CENTERS +
    FILTER_PAGE_NUMBER +
    pageNumber +
    OPTION_NUMBER_OF_ELEMENT_PER_PAGE +
    numberOfElementPerPage
  );
  return data;
};

export const getMonthlyActivity = async (centerId, date) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  const { data } = await axios.get(getApiServerProPath() + CENTERS + "/" + centerId + MONTHLY_ACTIVITY + "/" + date);
  return data;
};

export const getCurrentActivity = async (centerId) => {
  if (process.env.NODE_ENV === "test") {
    if (centerId === 2) {
      return getCenterResponse3;
    }
  }

  const { data } = await axios.get(getApiServerProPath() + CENTERS + "/" + centerId + CURRENT_ACTIVITY);
  return data;
};
