//#region react import
import PropTypes from "prop-types";
//#endregion

//#region component import
//#endregion

//#region service import
//#endregion

//#region constants import
//#endregion

//#region styles import
import "./ReorderCell.style.scss";
//#endregion

/**
 * component created to be used in a gstaTable, allowing to switch row's place
 */

const ReorderCell = ({ value, overrideProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div
      className="reorder-cell"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <button
        disabled={overrideProps.getIndex(value) === 0}
        onClick={() => {
          overrideProps.changeOrder(overrideProps.getIndex(value), overrideProps.getIndex(value) - 1);
        }}
      >
        <i className="icon-expend" />
      </button>
      <input
        type="number"
        defaultValue={overrideProps.getIndex(value) + 1}
        onBlur={(event) => {
          let targetint = parseInt(event.target.value);
          if (!isNaN(targetint) && targetint !== overrideProps.getIndex(value) + 1) {
            targetint = Math.max(0, targetint - 1);
            targetint = Math.min(overrideProps.valuesLength - 1, targetint);
            event.target.value = overrideProps.getIndex(value) + 1;
            overrideProps.changeOrder(overrideProps.getIndex(value), targetint);
          } else {
            event.target.value = overrideProps.getIndex(value) + 1;
          }
        }}
        onKeyDown={(event) => event.key === "Enter" && event.target.blur()}
      />
      <button
        disabled={overrideProps.getIndex(value) === overrideProps?.valuesLength - 1}
        onClick={() => {
          overrideProps.changeOrder(overrideProps.getIndex(value), overrideProps.getIndex(value) + 1);
        }}
      >
        <i className="icon-expend" />
      </button>
    </div>
  );
};
ReorderCell.propTypes = {
  value: PropTypes.shape({
    description: PropTypes.string,
    exercices: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number,
        exerciseOrder: PropTypes.number,
        orderInStep: PropTypes.number,
        serieOrder: PropTypes.number,
      })
    ),
    mandatory: PropTypes.bool,
    moduleId: PropTypes.number,
    name: PropTypes.string,
    order: PropTypes.number,
    picturePath: PropTypes.string,
    skills: PropTypes.array,
  }).isRequired,
  overrideProps: PropTypes.shape({
    changeOrder: PropTypes.func,
    getIndex: PropTypes.func,
    valuesLength: PropTypes.number,
  }).isRequired,
};
export default ReorderCell;
