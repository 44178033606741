//#region react import
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatePicker from "../../../Components/AcreosDatePicker/AcreosDatePicker.component";
import AcreosDatePickerInputRange from "../../../Components/AcreosDatePickerInputRange/AcreosDatePickerInputRange.component";
import GstaInputWithValidation from "../../../Components/GstaInputWithValidation/GstaInputWithValidation.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
import Loader from "../../../Components/Loader/Loader.component";
import GstaCalendar from "../../Home/GstaCalendar/GstaCalendar.component";
import StudentPersonnalInformationInput from "../StudentPersonnalInformationInput/StudentPersonnalInformationInput.component";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import { DateTimeToIsoString, getFirstDayOfMonth } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
import { setStudent } from "../../../ReduxStore/studentsSlice";
//#endregion

//#region service import
import { addCustomFieldToStudents } from "../../../Services/CustomFieldService";
import { getCenterSettings } from "../../../Services/SettingsService";
import {
  SetDatesAccess,
  getStudentById,
  getStudentCalendar,
  setCodePin,
  setFirstName,
  setLastName,
} from "../../../Services/StudentService";
//#endregion

//#region constants import
import { DATEPICKER_TYPE } from "../../../Components/AcreosDatePicker/AcreosDatePicker.constants";
import { INPUT_PASSWORD, INPUT_TYPE } from "../../../Components/GstaInput/GstaInput.constants";
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST, CUSTOM_FIELD_STRING } from "../../../Constants/CustomFieldType";
import { STUDENT } from "../../../Constants/DataConstants";
import { DAYS, MONTHS } from "../../../Constants/DateConstants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
//#endregion

//#region style import
import "./StudentPersonnalInformations.style.scss";
//#endregion

const StudentPersonnalInformations = () => {
  //#region others use...
  const dispatch = useDispatch();
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const studentRow = useSelector((state) => state.studentSlice.student);
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFieldsDefinitions = useSelector((state) => state.studentSlice.customFields);
  const refButton = useRef();
  //#endregion

  //#region state
  const [studentFirstName, setStudentFirstName] = useState(studentRow.firstName);
  const [studentLastName, setStudentLastName] = useState(studentRow.name);
  const [studentPinCode, setStudentPinCode] = useState(studentRow.pinCode);
  const [monthlyActivity, setMonthlyActivity] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [customFields, setCustomFields] = useState(studentRow.customValues);
  const [studentState, setStudentState] = useState();
  const [manageWithDates, setManageWithDates] = useState(false);
  const [startDateTime, setStartSelectedDateTime] = useState(new Date(studentRow.accessStart));
  const [endDateTime, setEndSelectedDateTime] = useState(new Date(studentRow.accessEnd));
  const [loading, setLoading] = useState(false);
  //#endregion

  //#region functions
  const getSettings = async () => {
    try {
      const manageWithDatesValue = await getCenterSettings(activeCenterId, MANAGE_STUDENTS_ACCESS_WITH_DATES);
      setManageWithDates(manageWithDatesValue.value === "true");
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const updateStudentLastName = async (newStudentLastName) => {
    await setLastName(activeCenterId, studentRow.id, newStudentLastName);
  };

  const updateStudentFirstName = async (newStudentFirstName) => {
    await setFirstName(activeCenterId, studentRow.id, newStudentFirstName);
  };

  const updateStudentPinCode = async (newStudentPinCode) => {
    await setCodePin(activeCenterId, studentRow.id, newStudentPinCode);
  };

  const UpdateAccessDateEnd = async (newDatetime) => {
    const PatchDates = {
      AccessStart: newDatetime.StartDate,
      AccessEnd: newDatetime.EndDate,
    };
    await SetDatesAccess(activeCenterId, studentRow.id, PatchDates);
  };

  const setNewStudentLastName = (newStudent, newLastName) => {
    newStudent.name = newLastName;
    setStudentLastName(newLastName);
  };

  const setNewStudentFirstName = (newStudent, newFirstName) => {
    newStudent.firstName = newFirstName;
    setStudentFirstName(newFirstName);
  };

  const setNewStudentPinCode = (newStudent, newPinCode) => {
    newStudent.pinCode = newPinCode;
    setStudentPinCode(newPinCode);
  };

  const setNewAccessDates = (newStudent, newDates) => {
    newStudent.accessEnd = newDates.EndDate;
    newStudent.accessStart = newDates.StartDate;
  };

  const handleValidateLastName = async (newLastName) => {
    handleValidate(
      setNewStudentLastName,
      updateStudentLastName,
      newLastName,
      getTranslations("notification_profil_name_updated", translations)
    );
  };

  const handleValidateFirstName = async (newFirstName) => {
    handleValidate(
      setNewStudentFirstName,
      updateStudentFirstName,
      newFirstName,
      getTranslations("notification_profil_first_name_updated", translations)
    );
  };

  const handleValidatePinCode = (newPinCode) => {
    handleValidate(
      setNewStudentPinCode,
      updateStudentPinCode,
      newPinCode,
      getTranslations("notification_profil_pin_code_updated", translations)
    );
  };

  const handleValidateEndDate = async () => {
    if (
      startDateTime != null &&
      endDateTime != null &&
      (DateTimeToIsoString(startDateTime) !== studentRow.accessStart ||
        DateTimeToIsoString(endDateTime) !== studentRow.accessEnd) &&
      startDateTime <= endDateTime
    ) {
      handleValidate(
        setNewAccessDates,
        UpdateAccessDateEnd,
        { StartDate: DateTimeToIsoString(startDateTime), EndDate: DateTimeToIsoString(endDateTime) },
        getTranslations("notification_profil_start_date_updated", translations)
      );
    }
  };

  const handleValidate = async (setStudentValue, updateStudent, newValue, notificationMessage) => {
    let updatePinCodeSucceed = false;
    setLoading(true);
    try {
      await updateStudent(newValue);
      updatePinCodeSucceed = true;
      dispatch(setSuccessNotification(notificationMessage));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    if (updatePinCodeSucceed) {
      const newStudent = { ...studentRow };
      setStudentValue(newStudent, newValue);
      dispatch(
        setStudent({
          newStudent,
        })
      );
    } else {
      setStudentPinCode(studentState.pinCode);
      setStudentLastName(studentState.name);
      setStudentFirstName(studentState.firstName);
      setStartSelectedDateTime(studentState.accessStart);
      setEndSelectedDateTime(studentState.accessEnd);
    }
    setLoading(false);
  };

  const checkCustomFieldValid = (customFieldDefinition, value) => {
    return !customFieldDefinition?.isMandatory || value?.length > 0 || value === undefined;
  };

  const handleSetCustomFields = async (customFieldDefinition, newValue) => {
    setLoading(true);
    const newCustomFields = copyObject(customFields);
    const index = newCustomFields.findIndex((customValue) => customValue.name === customFieldDefinition.name);
    if (index >= 0) {
      const compareCurrentValueToNewValue = newCustomFields.find((customValue) => customValue.value === newValue);
      if (compareCurrentValueToNewValue) {
        setLoading(false);
        return;
      }
      newCustomFields.splice(index, 1);
    }
    newCustomFields.push({
      id: customFieldDefinition.id,
      name: customFieldDefinition.name,
      value: newValue,
      fieldType: customFieldDefinition.fieldType,
    });
    try {
      let valueForApi =
        customFieldDefinition.fieldType === CUSTOM_FIELD_DATETIME.value ? DateTimeToIsoString(newValue) : newValue;
      valueForApi ??= "";
      await addCustomFieldToStudents(activeCenterId, customFieldDefinition.id, valueForApi, [studentRow.id]);
      dispatch(
        setSuccessNotification(
          "1 " + getTranslations("personnalized_field_pop_up_confirm_grouped_action_end", translations)
        )
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setCustomFields(newCustomFields);
    setLoading(false);
  };

  const renderCustomFields = () => {
    const customFieldsForm = [];
    customFieldsDefinitions?.forEach((customFieldDefinition, index) => {
      let type;
      let value = customFields?.find((customValue) => customValue.name === customFieldDefinition.name)?.value ?? "";
      switch (customFieldDefinition.fieldType) {
        case CUSTOM_FIELD_STRING.value:
          type = INPUT_TYPE.TEXT;
          break;
        case CUSTOM_FIELD_DATETIME.value:
          type = INPUT_TYPE.DATEPICKER;
          value = value?.toString().length > 0 ? new Date(value) : undefined;
          break;
        case CUSTOM_FIELD_LIST.value:
          type = INPUT_TYPE.SELECT;
          break;
        default:
          break;
      }
      customFieldsForm.push(
        <GstaInputWithValidation
          key={index}
          errorMessage={getTranslations("student_create_student_name_error_message", translations)}
          invalid={!checkCustomFieldValid(customFieldDefinition, value)}
          label={customFieldDefinition.name + (customFieldDefinition.isMandatory ? " *" : "")}
          setValue={(newValue) => handleSetCustomFields(customFieldDefinition, newValue)}
          placeholder={getTranslations("personnalized_field_confirm_exemple", translations)}
          value={value}
          dataTestId={process.env.NODE_ENV === "test" ? customFieldDefinition.name : null}
          overrideClass={"gsta-input--black"}
          mandatory={customFieldDefinition.isMandatory}
          type={type}
          options={customFieldDefinition.listValues?.map((value) => ({ label: value.value, value: value.value }))}
          inputStyle={"gsta-input--black"}
        />
      );
    });
    return customFieldsForm;
  };
  //#endregion

  //#region useQuery
  useQuery({
    queryFn: getSettings,
  });

  useQuery({
    queryKey: ["student", activeCenterId, studentRow.id],
    queryFn: async () => {
      try {
        const student = await getStudentById(activeCenterId, studentRow.id);
        setStudentState(student);
        setCustomFields(student.customValues);
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    enabled: !studentState,
    refetchOnWindowFocus: false,
  });

  const { isFetching } = useQuery({
    queryKey: ["studentCalendar", selectedDateTime],
    queryFn: async () => {
      try {
        const currentDate = selectedDateTime;
        const studentCalendar = await getStudentCalendar(
          activeCenterId,
          studentRow.id,
          getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth() + 1)
        );
        setMonthlyActivity(studentCalendar);
      } catch (error) {
        dispatch(setErrorNotification({ message: getTranslations("student_connexion_history", translations) }));
      }
    },
    refetchOnWindowFocus: false,
  });
  //#endregion

  return (
    <>
      {loading && <GstaLoaderPage />}
      <div className="student-personnal-info">
        <div
          isLoading={isFetching.toString()}
          className="student-personnal-info-container-calendar"
        >
          <h2>{getTranslations("student_detail_personnal_informations_connexion_history", translations)}</h2>
          <div className="student-personnal-info-calendar">
            {isFetching ? (
              <Loader />
            ) : (
              <>
                <div>
                  <div className="student-personnal-info-calendar-datepicker">
                    <AcreosDatePicker
                      type={DATEPICKER_TYPE.SELECT_MONTH}
                      months={MONTHS.map((month) => ({
                        name: getTranslations(month.traductionKey, translations),
                        number: month.number,
                      }))}
                      days={DAYS.map((day) => ({
                        name: getTranslations(day.traductionKey, translations),
                        number: day.number,
                      }))}
                      selectedDateTime={selectedDateTime}
                      setSelectedDateTime={setSelectedDateTime}
                      disabledFutureDate
                      datePickerOpen={datePickerOpen}
                      setDatePickerOpen={setDatePickerOpen}
                      refOpenDatePicker={refButton}
                    >
                      <button
                        className="gsta-date-picker"
                        onClick={() => setDatePickerOpen(!datePickerOpen)}
                        ref={refButton}
                      >
                        <span>
                          {`${getTranslations(
                            MONTHS.find((month) => month.number === selectedDateTime.getMonth() + 1).traductionKey,
                            translations
                          )} ${selectedDateTime.getFullYear()}`}
                        </span>
                        <i className={`icon-expend ${datePickerOpen ? "gsta-date-picker-icon-down" : ""}`} />
                      </button>
                    </AcreosDatePicker>
                  </div>
                  <GstaCalendar
                    month={selectedDateTime.getMonth() + 1}
                    year={selectedDateTime.getFullYear()}
                    activities={monthlyActivity}
                    simulatorOrStudent={STUDENT}
                    overrideLegend
                  />
                </div>
                <div className="student-personnal-info-calendar-legend">
                  <div className="calendar-legend-label">{getTranslations("calendar_legend", translations)}</div>
                  <div>
                    <div className="legend-detail">
                      <div className="calendar-day highlight legend-icon" />
                      <span>{getTranslations("calendar_simulator_connected", translations)}</span>
                    </div>
                    <div className="legend-detail">
                      <div className="calendar-day legend-icon"></div>
                      <span>{getTranslations("calendar_simulator_unconnected", translations)}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="student-personnal-info-profil">
          <h2>{getTranslations("common_profil", translations)}</h2>
          <div className="student-personnal-info-profil-identity">
            <section>
              <span className="student-personnal-info-profil-identity-label">
                {getTranslations("student_detail_personnal_informations_student_identity", translations)}
              </span>
              <span className="color-disabled">
                {getTranslations("student_create_student_required_field", translations)}
              </span>
            </section>
            <hr />
            <div className="student-personnal-info-profil-identity-input">
              <GstaInputWithValidation
                label={getTranslations("common_name", translations)}
                value={studentLastName}
                setValue={handleValidateLastName}
                placeholder={getTranslations("common_name_example", translations)}
                overrideClass={"gsta-input--black"}
                toUpper
                mandatory
                type={INPUT_TYPE.TEXT}
                inputStyle={"gsta-input--black"}
              />
              <GstaInputWithValidation
                label={getTranslations("common_first_name", translations)}
                value={studentFirstName}
                setValue={handleValidateFirstName}
                placeholder={getTranslations("common_first_name_example", translations)}
                overrideClass={"gsta-input--black"}
                mandatory
                type={INPUT_TYPE.TEXT}
                inputStyle={"gsta-input--black"}
              />
              {manageWithDates && (
                <div>
                  <b>{getTranslations("session_date_student_profile", translations)}</b>
                  <div className="student-access-dates-pickers">
                    <AcreosDatePickerInputRange
                      labelStart={getTranslations("session_date_student_start", translations)}
                      labelEnd={getTranslations("session_date_student_end", translations)}
                      startDate={startDateTime}
                      setStartDate={setStartSelectedDateTime}
                      endDate={endDateTime}
                      setEndDate={setEndSelectedDateTime}
                      actionOnClickOutside={handleValidateEndDate}
                      useCurrentDates={true}
                      isDateValid={true}
                    />
                  </div>
                </div>
              )}
              {renderCustomFields()}
            </div>
          </div>
          <h2>
            {getTranslations("student_detail_personnal_informations_student_connexion_informations", translations)}
          </h2>
          <div className="student-personnal-info-profil-identity">
            <span className="student-personnal-info-profil-identity-label">
              {getTranslations("profil_security", translations)}
            </span>
            <hr />
            <div className="student-personnal-info-profil-identity-password">
              <StudentPersonnalInformationInput
                label={getTranslations("student_detail_personnal_informations_student_pin", translations)}
                type={INPUT_PASSWORD}
                inputValue={studentPinCode}
                setInputValue={setStudentPinCode}
                handleValidatePinCode={handleValidatePinCode}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentPersonnalInformations;
