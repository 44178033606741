//#region style import
import "./SwitchWithText.style.scss";
//#endregion

const SwitchWithText = ({
  label,
  onText,
  offText,
  checked,
  handleChange,
  overrideClass,
  dataTestId,
  appearanceClass,
  layoutClass,
}) => {
  return (
    <div className={"switch-with-text " + (layoutClass ? layoutClass : "")}>
      {label && <label>{label}</label>}
      <label
        className="switch"
        data-testid={dataTestId}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        <div className={(overrideClass ? overrideClass : "slider") + " round"}>
          <span className={"on" + (checked ? "" : " hide")}>{onText}</span>
          <span className={"off" + (checked ? " hide" : "")}>{offText}</span>
        </div>
      </label>
    </div>
  );
};

export default SwitchWithText;
