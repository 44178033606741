//#region react import
import PropTypes from "prop-types";
//#endregion

//#region component import
import AcreosCheckBox from "../../../Components/AcreosCheckBox/AcreosCheckBox.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region style import
import "./CheckBoxCell.style.scss";
//#endregion

/**
 *
 *
 *
 */

const CheckBoxCell = ({ value, overrideProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  const getContent = () => {
    const checkbox = (
      <AcreosCheckBox
        checked={overrideProps.getValue(value)}
        onChange={() =>
          value.setIsInGridOverride
            ? value.setIsInGridOverride(!overrideProps.getValue(value))
            : overrideProps.onChange(value)
        }
        disabled={!value.setIsInGridOverride && !overrideProps.getValue(value) && overrideProps.disabled(value)}
      />
    );
    if (overrideProps.errorMessage && !overrideProps.getValue(value) && overrideProps.disabled(value)) {
      return (
        <GstaToolTip
          overrideClass={"color-invalid"}
          toolTipContent={overrideProps.errorMessage}
          disabled={value.setIsInGridOverride}
        >
          {checkbox}
        </GstaToolTip>
      );
    } else {
      return checkbox;
    }
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div
      className="custom-field_type-cell"
      onClick={(event) => event.stopPropagation()}
    >
      {(!overrideProps.isVisible || overrideProps.isVisible(value)) && getContent()}
    </div>
  );
};
CheckBoxCell.propTypes = {
  value: PropTypes.object.isRequired,
  overrideProps: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }),
};
export default CheckBoxCell;
