//#region react import
import { Text, View, StyleSheet, Svg, Circle, Image } from "@react-pdf/renderer";
//#endregion

//#region component import
import PdfTitle, { SUBTITLE, TITLE } from "./PdfTitle";
//#endregion

//#region functions import
import { formatSecondToStringTime } from "../../Helper/TimeConverter";
import { getNotePdf } from "../../Helper/NoteConverter";
import { getSkillNotationPdf } from "../../Helper/CalculateNote";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { inProgress, invalid, notEvaluated, valid } from "./pdfSkillsFunctions";
//#endregion

const styles = StyleSheet.create({
  ResultByMachineSummary: {
    marginVertical: 20,
    width: "100%",
    flexDirection: "row",
  },
  headerThreeColumn: {
    textAlign: "center",
    width: "33%",
    fontSize: "12px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  MachineAverage: {
    textAlign: "center",
    width: "33%",
    fontSize: "12px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  OrangeText: {
    color: "orange",
  },
  TextAlignRight: {
    textAlign: "right",
  },
  MachineSimulationTime: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  MachineFlexContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingVertical: "10",
    paddingBottom: "10",
  },
  MachineFlexContainerColored: {
    backgroundColor: "#F6DEBB",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingVertical: "10",
    paddingBottom: "10",
  },
  TableHeader: {
    marginTop: "20",
    backgroundColor: "#FA9600",
    color: "#FFFFFF",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingVertical: "10",
    paddingBottom: "10",
  },
  MachineSkill: {
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    paddingLeft: "5",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    marginVertical: "2px",
  },
  MachineSkillName: {
    width: "100%",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    fontSize: "10px",
  },
  MachineSkillNote: {
    width: "20%",
    textAlign: "center",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  MachineSkillNoteValid: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: valid,
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  MachineSkillNoteInvalid: {
    width: "100%",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: invalid,
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  MachineSkillNoteInProgress: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: inProgress,
    borderRadius: "5px",
    color: "#FFFFFF",
  },
  MachineSkillNotEvaluated: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: notEvaluated,
    borderRadius: "5px",
    padding: "5px",
    color: "#FFFFFF",
  },
  TrainingResultHeader: {
    width: "25%",
    textAlign: "center",
    fontWeight: "bold",
  },
  TrainingResultRow: {
    width: "25%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  TrainingResultRowValid: {
    color: "#FFFFFF",
    textAlign: "center",
    backgroundColor: valid,
    borderRadius: "5px",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
  },
  TrainingResultRowInValid: {
    color: "#FFFFFF",
    textAlign: "center",
    backgroundColor: invalid,
    borderRadius: "5px",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
  },
  TrainingResultRowInProgress: {
    color: "#FFFFFF",
    textAlign: "center",
    backgroundColor: inProgress,
    borderRadius: "5px",
    paddingHorizontal: "10px",
    paddingVertical: "5px",
  },
  Placeholder: {
    fontStyle: "italic",
    textAlign: "center",
    width: "100%",
  },
  EngineSummary: {
    paddingTop: "10px",
    marginTop: "10px",
  },
});

const PdfResultByMachine = ({ machineResult, notationType, translations }) => {
  const getNoteColor = () => {
    const noteChecked = machineResult.averagePercent ?? 0;
    if (noteChecked >= 50) {
      return valid;
    } else {
      return invalid;
    }
  };

  const getExerciceClass = (note) => {
    if (note <= 35) {
      return styles.TrainingResultRowInValid;
    } else if (note > 35 && note <= 70) {
      return styles.TrainingResultRowInProgress;
    } else {
      return styles.TrainingResultRowValid;
    }
  };

  const getSkillClass = (note, totalPoint) => {
    if (totalPoint === 0) {
      return styles.MachineSkillNotEvaluated;
    }
    if (note <= 35) {
      return styles.MachineSkillNoteInvalid;
    } else if (note > 35 && note <= 70) {
      return styles.MachineSkillNoteInProgress;
    } else {
      return styles.MachineSkillNoteValid;
    }
  };

  const renderMachineSkills = () => {
    let color = true;
    return machineResult.machineSkills.map((machineSkill, index) => {
      const skillNote = getSkillNotationPdf(machineSkill.acquiredPoint, machineSkill.totalPoint);
      const skillNoteString =
        machineSkill.totalPoint !== 0 ? skillNote + "%" : getTranslations("skill_not_evaluated", translations);
      if (machineSkill.totalPoint !== 0) {
        color = !color;
        return (
          <View
            style={{ ...styles.MachineSkill, backgroundColor: color ? "#F6DEBB" : "#FFFFFF" }}
            key={"skill-" + index}
            wrap={false}
          >
            <View style={styles.MachineSkillName}>
              <Text style={styles.MachineSkillName}>{getTranslations(machineSkill.languageKey, translations)}</Text>
            </View>
            <View style={styles.MachineSkillNote}>
              <View style={getSkillClass(skillNote, machineSkill.totalPoint)}>
                {machineSkill.totalPoint !== 0 && (
                  <Text style={{ fontFamily: "OpenDyslexic", color: "#FFFFFF" }}>{skillNote > 30 ? "★ " : "☆ "}</Text>
                )}
                <Text style={machineSkill.totalPoint === 0 ? { fontSize: "10px" } : {}}>{skillNoteString}</Text>
              </View>
            </View>
          </View>
        );
      }
      return undefined;
    });
  };

  const note = getNotePdf(machineResult.averagePercent, notationType);

  return (
    <View>
      <View
        style={styles.EngineSummary}
        wrap={false}
      >
        <PdfTitle
          type={TITLE}
          label={getTranslations("pdf_result_per_machine_title", translations)}
        />
        <View style={styles.ResultByMachineSummary}>
          <View style={styles.headerThreeColumn}>
            <Image
              style={{ height: "70", width: "70" }}
              src={process.env.PUBLIC_URL + `/Images/icons/${machineResult.machine.code}_orange.png`}
            />
            <Text>{getTranslations(machineResult.machine.languageKey, translations)}</Text>
          </View>
          <View style={styles.headerThreeColumn}>
            <Text style={styles.MachineSimulationTime}>
              {formatSecondToStringTime(machineResult.totalSecondElapsed, translations)}
            </Text>
            <Text>{getTranslations("pdf_result_per_machine_formation_time", translations)}</Text>
          </View>
          <View style={styles.MachineAverage}>
            <Svg style={{ height: "70", width: "70" }}>
              <Circle
                cx="30"
                cy="40"
                r="25"
                stroke={getNoteColor()}
                strokeWidth="4"
              />
              <Text
                x={`${15 + (5 - note.length) * 3}`}
                y="44"
                fill={getNoteColor()}
                style={{ fontSize: "12px" }}
              >
                {note}
              </Text>
            </Svg>
            <Text style={styles.TextAlignRight}>
              {getTranslations("pdf_result_per_machine_machine_average", translations)}
            </Text>
          </View>
        </View>
      </View>

      {machineResult.machineSkills.length === 0 ||
        (machineResult.machineSkills.filter((machineSkill) => machineSkill.totalPoint !== 0).length && (
          <View>
            <PdfTitle
              label={getTranslations("pdf_result_per_machine_job_skills", translations)}
              type={SUBTITLE}
            />
            <View style={styles.MachineFlexContainer}>{renderMachineSkills()}</View>
          </View>
        ))}

      <PdfTitle
        label={getTranslations("pdf_result_per_machine_training_title", translations)}
        type={SUBTITLE}
      />
      <View wrap={false}>
        <View
          style={styles.TableHeader}
          fixed
        >
          <Text style={styles.TrainingResultHeader}>
            {getTranslations("pdf_result_per_machine_training", translations)}
          </Text>
          <Text style={styles.TrainingResultHeader}>{getTranslations("home_simulator_time", translations)}</Text>
          <Text style={styles.TrainingResultHeader}>
            {getTranslations("pdf_result_per_machine_progress", translations)}
          </Text>
          <Text style={styles.TrainingResultHeader}>{getTranslations("student_detail_average", translations)}</Text>
        </View>
      </View>

      {machineResult.trainingResults
        .filter((trainingResult) => trainingResult.currentStepOrder > 0)
        .map((trainingResult, index) => {
          return (
            <View
              style={index % 2 === 0 ? styles.MachineFlexContainer : styles.MachineFlexContainerColored}
              key={"trainingResult-" + index}
              wrap={false}
            >
              <View style={styles.TrainingResultRow}>
                <Text>{getTranslations(trainingResult.customName ?? trainingResult.languageKey, translations)}</Text>
              </View>
              <View style={styles.TrainingResultRow}>
                <Text>{formatSecondToStringTime(trainingResult.totalSecondElapsed, translations)}</Text>
              </View>
              <View style={styles.TrainingResultRow}>
                <Text>{`${trainingResult.currentStepOrder}/${trainingResult.stepCount}`}</Text>
              </View>
              <View style={styles.TrainingResultRow}>
                <Text style={getExerciceClass(trainingResult.averagePercent)}>
                  {getNotePdf(trainingResult.averagePercent, notationType)}
                </Text>
              </View>
            </View>
          );
        })}
    </View>
  );
};

export default PdfResultByMachine;
