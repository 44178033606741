//#region react import
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region functions
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//endregion

//#region constants import
import { gstaLogo } from "../../Constants/Images";
//#endregion

// Create styles
const styles = StyleSheet.create({
  headerContainer: {
    fontFamily: "Roboto",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 35,
    paddingTop: 15,
    marginBottom: 20,
  },
  HeaderThreeColumnFirst: {
    textAlign: "right",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "17%",
    fontSize: "10px",
  },
  HeaderThreeColumn: {
    textAlign: "right",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "66%",
    fontSize: "10px",
  },
  HeaderThreeColumnImage: {
    textAlign: "right",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "50px",
    fontSize: "10px",
  },
  HeaderThreeColumnLast: {
    textAlign: "right",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "17%",
    fontSize: "10px",
  },
  TextAlignRight: {
    textAlign: "right",
  },
  TextAlignRightBold: {
    textAlign: "right",
    fontWeight: "bold",
  },
  Bold: {
    paddingLeft: "4px",
    fontWeight: "bold",
    textAlign: "left",
  },
  StudentName: {
    fontSize: "14px",
    textAlign: "left",
  },
  StudentNameBold: {
    fontSize: "14px",
    paddingLeft: "4px",
    fontWeight: "bold",
    textAlign: "left",
    textOverflow: "...",
  },
});

const PdfHeader = ({
  center,
  lastName,
  firstName,
  translations,
  accessStart,
  accessEnd,
  sessionDates,
  pdfTemplate,
  pdfInfos,
}) => {
  return (
    <View style={styles.headerContainer} fixed>
      <View style={styles.HeaderThreeColumnFirst}>
        <Image style={styles.HeaderThreeColumnImage} src={gstaLogo.img} />
      </View>
      <View style={styles.HeaderThreeColumn}>
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <View style={styles.StudentName}>
            <Text style={styles.StudentName}>{getTranslations("pdf_header_student_name", translations)}</Text>
          </View>
          <View style={styles.StudentNameBold}>
            <Text>{lastName}</Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <View style={styles.StudentName}>
            <Text style={styles.StudentName}>{getTranslations("pdf_header_student_firstname", translations)}</Text>
          </View>
          <View style={styles.StudentNameBold}>
            <Text>{firstName}</Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <Text>{getTranslations("pdf_header_center", translations)}</Text>
          <Text style={styles.Bold}>{center}</Text>
        </View>
        {sessionDates && (
          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
            <Text>{getTranslations("session_date_pdf", translations)}</Text>
            <Text style={styles.Bold}>
              {DateTimeToIsoString(new Date(accessStart)) +
                " " +
                getTranslations("session_date_to", translations) +
                " " +
                DateTimeToIsoString(new Date(accessEnd))}
            </Text>
          </View>
        )}
        {pdfTemplate && (
          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
            <Text>{getTranslations("model_used_for_export", translations)}</Text>
            <Text style={styles.Bold}>{pdfTemplate.label}</Text>
          </View>
        )}
      </View>
      <Image
        style={{
          height: "50px",
          width: "auto",
        }}
        src={`data:${pdfInfos?.pdfCustomLogo.imageExtension};base64,${pdfInfos?.pdfCustomLogo.image}`}
      />
    </View>
  );
};

export default PdfHeader;
