//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getNoteClass } from "./CustomPdf.functions";
import { getTranslations } from "../../../Helper/TranslationController";
import { getSkillNotationPdf } from "../../../Helper/CalculateNote";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
//#endregion

const MachineSkills = ({ machineSkills, translations }) => {
  //#region styles
  const styles = StyleSheet.create({
    machineSkills: {
      width: "100%",
      flexDirection: "column",
    },
    mainTitle: {
      width: "100%",
      backgroundColor: COLOR.orange,
      textAlign: "center",
      justifyContent: "center",
      fontSize: FONT_SIZE.subtitle,
      paddingVertical: "5px",
      flexDirection: "row",
      gap: "6px",
    },
    skillLign: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "11px",
      padding: "10px",
    },
    skillName: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "90%",
    },
    skillNote: {
      minWidth: "55px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      gap: "5px",
      borderRadius: "5px",
      paddingVertical: "2px",
      paddingHorizontal: "5px",
      color: COLOR.textColorInversed,
      width: "10%",
    },
    starImage: {
      width: "12px",
    },
  });
  //#endregion
  return (
    <View style={styles.machineSkills}>
      {machineSkills && machineSkills.length > 0 && (
        <>
          <View
            style={styles.mainTitle}
            wrap={false}
          >
            <Text>{getTranslations("pdf_result_per_machine_job_skills", translations)}</Text>
          </View>
          {machineSkills.map((machineSkill, index) => {
            const skillAverage = getSkillNotationPdf(machineSkill.acquiredPoint, machineSkill.totalPoint);
            return (
              <View
                key={machineSkill.languageKey}
                style={{ ...styles.skillLign, backgroundColor: index % 2 === 0 ? COLOR.white : COLOR.orangeLight }}
                wrap={false}
              >
                <Text style={styles.skillName}>{getTranslations(machineSkill.languageKey, translations)}</Text>
                <View style={getNoteClass(styles.skillNote, skillAverage)}>
                  <Text style={{ fontFamily: "OpenDyslexic" }}>{`${skillAverage > 30 ? "★" : "☆"}`}</Text>
                  <Text>{`${skillAverage}%`}</Text>
                </View>
              </View>
            );
          })}
        </>
      )}
    </View>
  );
};

export default MachineSkills;
