//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
//#endregion

//#region functions import
import { formatSecondToStringTime, FORMAT_TIMESTRING } from "../../../Helper/TimeConverter";
import { ConvertNote } from "../../../Helper/NoteConverter";
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import StepProgressTimeline from "../../../Components/StepProgressTimeline/StepProgressTimeline.component";
import Loader from "../../../Components/Loader/Loader.component";
//#endregion

//#region services import
import { getStudentPreview } from "../../../Services/StudentService";
//#endregion

//#region constants import
import { STUDENTS_TRAINING } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./StudentDetail.style.scss";
//#endregion

/**
 * Component created to render the student detail progress
 */

const StudentDetail = ({ value }) => {
  //#region state
  const [machineResults, setMachineResults] = useState([]);
  //#endregion

  //#region useQuery

  const getStudentResults = async () => {
    return await getStudentPreview(centerId, value.id);
  };

  const { isFetching, isSuccess } = useQuery({
    queryKey: ["studentResults"],
    queryFn: getStudentResults,
    onSuccess: (response) => {
      response.forEach((machineResult) => setCurrentStepState(machineResult.subscriptions));
      setMachineResults(response);
    },
  });
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);

  const dispatch = useDispatch();

  useNotationType(dispatch, centerId, notationType);
  //#endregion

  //#region functions
  const setCurrentStepState = (trainingResults) => {
    for (let i = 0; i < trainingResults?.length - 1; i++) {
      for (let j = 0; j < trainingResults[i]?.stepAverages.length - 1; j++) {
        if (
          trainingResults[i]?.stepAverages[j]?.trainingStepOrder === trainingResults[i]?.currentStepOrder &&
          !trainingResults[i]?.currentStepFinished
        ) {
          trainingResults[i].stepAverages[j].stepState = 1;
          return;
        }
      }
    }
  };
  const renderContent = () => {
    if (isFetching) {
      return <Loader />;
    } else if (isSuccess && machineResults.length === 0) {
      return (
        <div className="student-detail-container-no-content">
          <span>{getTranslations("student_detail_no_training_inscription", translations)}</span>
        </div>
      );
    } else if (isSuccess && machineResults) {
      return (
        <>
          {isFetching && <Loader />}
          {machineResults?.length === 0 && (
            <div className="student-detail-container-no-content">
              <span>{getTranslations("student_detail_no_training_inscription", translations)}</span>
            </div>
          )}
          {machineResults?.map((machineResult, index) => (
            <div
              key={`${machineResult.machineCode}${index}`}
              className="student-detail-container"
            >
              <div className="student-detail-machine-icon-container">
                <i className={`icon-${machineResult.code}`} />
                <p className="engine-name">{getTranslations(machineResult.languageKey, translations)}</p>
              </div>
              <div className="student-detail-training-container">
                <div className="student-detail-training-container-header">
                  <p />
                  <p>{getTranslations(STUDENTS_TRAINING.pageTraductionKey, translations)}</p>
                  <p>{getTranslations("student_detail_training_time", translations)}</p>
                  <p>{getTranslations("student_detail_average", translations)}</p>
                  <p>{getTranslations("student_detail_training_progression", translations)}</p>
                </div>
                {machineResult.subscriptions.map((trainingResult, index) => (
                  <div
                    key={index}
                    className="student-detail-training-container-row"
                  >
                    <div className="cell-container">
                      <span className={getSpanStyle(trainingResult)}>{getSpanContent(trainingResult)}</span>
                    </div>
                    <div className="cell-container">
                      <p>{getTranslations(trainingResult.customName ?? trainingResult.languageKey, translations)}</p>
                    </div>
                    <div className="student-detail-cell">
                      <div className="gsta-span">
                        <i className="icon-hourglass" />
                        <span>
                          {formatSecondToStringTime(
                            trainingResult.totalSecondElapsed,
                            translations,
                            FORMAT_TIMESTRING.HHMMSS
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="student-detail-cell">
                      {trainingResult.totalSecondElapsed === null || !trainingResult.averagePercent ? (
                        <span className={getSpanStyle(trainingResult)}>
                          {getTranslations("result_detail_not_started", translations)}
                        </span>
                      ) : (
                        <div className={trainingResult.averagePercent > 50 ? "gsta-span-valid" : "gsta-span-invalid"}>
                          <i className="icon-Gauge" />
                          <span>{ConvertNote(trainingResult.averagePercent, notationType)}</span>
                        </div>
                      )}
                    </div>
                    <div className="student-detail-cell">
                      <StepProgressTimeline
                        key={index}
                        data={{
                          steps: trainingResult.stepAverages,
                          subscriptionId: trainingResult.subscriptionId,
                          student: value,
                        }}
                        nbDisplay={4}
                        toolTips
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  const getSpanStyle = (trainingResult) => {
    if (trainingResult.currentStepOrder === trainingResult.stepCount && trainingResult.currentStepFinished) {
      return "gsta-span-valid";
    } else if (trainingResult.totalSecondElapsed !== null) {
      return "gsta-span-in-progress";
    } else {
      return "gsta-span-disabled";
    }
  };

  const getSpanContent = (trainingResult) => {
    if (trainingResult.currentStepOrder === trainingResult.stepCount && trainingResult.currentStepFinished) {
      return getTranslations("student_detail_finished", translations);
    } else if (trainingResult.totalSecondElapsed !== null) {
      return getTranslations("student_detail_in_progress", translations);
    } else {
      return getTranslations("result_detail_not_started_tag", translations);
    }
  };
  //#endregion
  return renderContent();
};
StudentDetail.propTypes = {
  value: PropTypes.shape({
    accessEnd: PropTypes.any,
    accessStart: PropTypes.any,
    customValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        value: PropTypes.string,
        fieldType: PropTypes.number,
      })
    ),
    firstName: PropTypes.string,
    guid: PropTypes.string,
    id: PropTypes.number,
    isActive: PropTypes.bool,
    lastActivityDate: PropTypes.any,
    machineSubscribes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        softwareCode: PropTypes.string,
        versionId: PropTypes.number,
        version: PropTypes.string,
        languageKey: PropTypes.string,
      })
    ),
    name: PropTypes.string,
    pinCode: PropTypes.string,
  }).isRequired,
};
export default StudentDetail;
