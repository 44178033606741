//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import SwitchWithText from "../SwitchWithText/SwitchWithText.component";
//#endregion

//#region function import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
//#endregion

//#region style import
import "./CreateCustomPdfStep3.style.scss";
//#endregion

/**
 *
 */
const CreateCustomPdfStep3 = ({ pdfTemplate, setPdfTemplate }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const setShowWorkSkill = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showSkills = !pdfTemplate.showSkills;
    setPdfTemplate(newPdfTemplate);
  };

  const setShowStepDetail = () => {
    const newPdfTemplate = copyObject(pdfTemplate);
    newPdfTemplate.showTrainingSteps = !pdfTemplate.showTrainingSteps;
    setPdfTemplate(newPdfTemplate);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <section className="create-custom-pdf-step-3">
      <header>
        <h1>{`${getTranslations("step_progress_step", translations)} 3 - ${getTranslations(
          "common_results",
          translations
        )}`}</h1>
        <span>{getTranslations("student_create_student_required_field", translations)}</span>
      </header>
      <SwitchWithText
        label={getTranslations("show_work_skills", translations)}
        onText={getTranslations("common_yes", translations)}
        offText={getTranslations("common_no", translations)}
        checked={pdfTemplate.showSkills}
        handleChange={setShowWorkSkill}
      />
      <SwitchWithText
        label={getTranslations("show_step_detail", translations)}
        onText={getTranslations("common_yes", translations)}
        offText={getTranslations("common_no", translations)}
        checked={pdfTemplate.showTrainingSteps}
        handleChange={setShowStepDetail}
      />
    </section>
  );
};
CreateCustomPdfStep3.propTypes = {};
export default CreateCustomPdfStep3;
