//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import PdfTemplateSummary from "../PdfTemplateSummary/PdfTemplateSummary.component";
//#endregion

//#region service import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./CreateCustomPdfStep4.style.scss";
import { SELECTED_PERIOD } from "../ExportPdfModal/ExportPdfModal.constants";
//#endregion

/**
 * A summary of all setting checked for the current pdf template
 */
const CreateCustomPdfStep4 = ({ pdfTemplate, setCurrentStep }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const getSettings = () => {
    const dateSetting = { name: getTranslations("date_for_results", translations) };
    switch (pdfTemplate.showResultDates) {
      case SELECTED_PERIOD.PERSONALIZED_DATES:
        dateSetting.value = getTranslations("custom_dates", translations);
        break;
      case SELECTED_PERIOD.SINCE_STUDENT_CREATION:
        dateSetting.value = getTranslations("since_student_creation", translations);
        break;
      case SELECTED_PERIOD.SESSION_DATES:
        dateSetting.value = getTranslations("session_dates", translations);
        break;
      default:
        break;
    }
    return [
      dateSetting,
      {
        name: getTranslations("show_connexion_calendar", translations),
        isActived: pdfTemplate.showCalendar,
      },
      {
        name: getTranslations("show_generic_skills", translations),
        isActived: pdfTemplate.showGenericSkills,
      },
    ];
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <section className="create-custom-pdf-step4">
      <header>
        <h1>{`${getTranslations("step_progress_step", translations)} 4 - ${getTranslations(
          "summary",
          translations
        )}`}</h1>
        <span>{getTranslations("student_create_student_required_field", translations)}</span>
      </header>
      <PdfTemplateSummary
        icon={"icon-profile_picture"}
        title={getTranslations("training_layout_detail_general_informations", translations)}
        settings={[
          { name: getTranslations("model_name", translations), value: pdfTemplate.name },
          {
            name: getTranslations("export_used_by_default", translations),
            isActived: pdfTemplate.isDefault,
          },
        ]}
        action={() => setCurrentStep(1)}
      />
      <PdfTemplateSummary
        icon={"icon-profile_picture"}
        title={getTranslations("pdf_driver_profile_no_title", translations)}
        settings={getSettings()}
        action={() => setCurrentStep(2)}
      />
      <PdfTemplateSummary
        icon={"icon-success_trophy"}
        title={getTranslations("common_results", translations)}
        settings={[
          {
            name: getTranslations("show_work_skills", translations),
            isActived: pdfTemplate.showSkills,
          },
          {
            name: getTranslations("show_step_detail", translations),
            isActived: pdfTemplate.showTrainingSteps,
          },
        ]}
        action={() => setCurrentStep(3)}
      />
    </section>
  );
};
CreateCustomPdfStep4.propTypes = {};
export default CreateCustomPdfStep4;
