export const responseMockNote = {
  settingType: 1,
  value: "true",
  isDefault: false,
};

export const responseMock2 = {
  settingType: 1,
  value: "1",
  isDefault: false,
};

export const responseMock3 = {
  settingType: 1,
  value: "1",
  isDefault: false,
};

export const responseMockSimulatorPinCode = {
  settingType: 3,
  value: "0000",
  isDefault: false,
};
export const responseGetPdfTemplate = [
  {
    id: 1,
    name: "Acreos Template",
    showSkills: true,
    showCalendar: false,
    showTrainingSteps: true,
    showResultDates: false,
    showGenericSkills: true,
    isAcreos: true,
    isDefault: false,
  },
  {
    id: 8,
    name: "test mon pdf",
    showSkills: false,
    showCalendar: false,
    showTrainingSteps: false,
    showResultDates: true,
    showGenericSkills: false,
    isAcreos: false,
    isDefault: true,
  },
];

export const responseCustomPdfTemplate = {
  id: 0,
  name: "test",
  showSkills: true,
  showCalendar: true,
  showTrainingSteps: true,
  showResultDates: true,
  isDefault: false,
};
