//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import {
  CUSTOM_FIELD_DATETIME,
  CUSTOM_FIELD_LIST,
  CUSTOM_FIELD_STRING,
  FIELD_MANAGE_ACCESS_DATE,
  FIELD_PIN_CODE,
} from "../../../Constants/CustomFieldType";
//#endregion

//#region style import
import "./CustomFieldTypeCell.style.scss";
//#endregion

/**
 *
 *
 *
 */

const CustomFieldTypeCell = ({ value, overrideClass }) => {
  //#region useState
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getIcon = () => {
    switch (value.fieldType) {
      case CUSTOM_FIELD_LIST.value:
        return <i className="icon-list_field" />;
      case CUSTOM_FIELD_STRING.value:
        return <i className="icon-text_field" />;
      case CUSTOM_FIELD_DATETIME.value:
        return <i className="icon-date_field" />;
      case FIELD_MANAGE_ACCESS_DATE.value:
        return <i className="icon-date_field" />;
      case FIELD_PIN_CODE.value:
        return <i className="icon-key" />;
      default:
        break;
    }
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion
  return (
    <div className={`custom-field_type-cell ${overrideClass}`}>
      {getIcon()}
      <span>{getTranslations(value.label, translations)}</span>
    </div>
  );
};
CustomFieldTypeCell.propTypes = {};
export default CustomFieldTypeCell;
