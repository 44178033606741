//#region react import
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region custom import
import GstaTable from "../../../Components/GstaTable/GstaTable.component";
import ImportStudentsModal from "../../../Components/ImportStudentsModal/ImportStudentsModal.component";
import StudentActions from "../StudentActions/StudentActions.component";
import StudentDetail from "../StudentDetail/StudentDetail.component";
import StudentGroupedActions from "../StudentGroupedActions/StudentGroupedActions.component";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import { DateTimeToIsoString } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { getCustomField } from "../../../Services/CustomFieldService";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import {
  resetStudentList,
  setCustomFields,
  setStudents,
  setStudentsGettedDate,
} from "../../../ReduxStore/studentsSlice";
//#endregion

//#region services import
import { getTemplatePdf } from "../../../Services/SettingsService.js";
import { getStudentsNotarchived } from "../../../Services/StudentService";
//#endregion

//#region constants import
import { GSTA_TABLE_IDS } from "../../../Components/GstaTable/GstaTable.constants";
import { ELEMENT_PER_PAGE } from "../../../Constants/ApiConstants";
import KeyTrad_NumberPerPage from "../../../Constants/PaginationConstants.js";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
import { STUDENTS_ARCHIVED, STUDENTS_CREATE } from "../../../Routing/PageNames";
import { ACREOS_TEMPLATE } from "../../Center/InscriptionManagementPage/IncriptionManagementPage.constants.js";
import { getHeaderDefinition } from "./StudentPage.constants";
//#endregion

//#region style import
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component.jsx";
import RedirectionLink from "../../../Components/RedirectionLink/RedirectionLink.component.jsx";
import "./StudentPage.scss";
//#endregion

const StudentPage = () => {
  //#region State
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [studentState, setStudentState] = useState([]);
  const [getStudentsLaunched, setGetStudentsLaunched] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [pdfTemplates, setPdfTemplates] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState();
  const [personalizedStartDate, setPersonalizedStartDate] = useState();
  const [personalizedEndDate, setPersonalizedEndDate] = useState();
  const [isLoading, setIsLoading] = useState();
  //#endregion

  //#region useSelector
  const totalElementsCount = useSelector((state) => state.studentSlice.totalElement);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const settingDictionary = useSelector((state) => state.settingSlice.settingDictionary);
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useNotationType(dispatch, centerId, notationType);
  //#rendregion
  //#region constants
  const headerDefinition = getHeaderDefinition(
    translations,
    centerId,
    settingDictionary,
    customFields,
    pdfTemplates,
    setPdfTemplates,
    selectedPdfTemplate,
    setSelectedPdfTemplate,
    selectedPeriod,
    setSelectedPeriod,
    personalizedStartDate,
    setPersonalizedStartDate,
    personalizedEndDate,
    setPersonalizedEndDate
  );
  //#endregion

  //#region functions
  const RedirectToCreateStudent = () => {
    navigate(STUDENTS_CREATE.url);
  };

  const setValue = (newStudent) => {
    setValues([newStudent]);
  };

  const setValues = (newStudents) => {
    const newStudentsState = copyObject(studentState);
    for (let newStudent of newStudents) {
      const index = newStudentsState.findIndex((student) => student.id === newStudent.id);
      newStudentsState[index] = newStudent;
    }
    setStudentState(newStudentsState);
    dispatch(setStudents(newStudentsState));
  };

  const deleteStudent = (studentToDelete) => {
    removeStudents([studentToDelete]);
  };

  const removeStudents = (studentsToDelete) => {
    const newStudents = [...studentState];
    let index;
    for (let studentToDelete of studentsToDelete) {
      index = newStudents.findIndex((student) => student.id === studentToDelete.id || student.id === studentToDelete);
      newStudents.splice(index, 1);
    }
    setStudentState(newStudents);
  };

  const addStudents = (newStudents) => {
    let newStudentsState = [...newStudents];
    newStudentsState = newStudentsState.concat([...studentState]);
    setStudentState(newStudentsState);
    dispatch(setStudents(newStudentsState));
  };

  const onGetPdfTemplateSuccess = (pdfTemplates) => {
    return pdfTemplates?.map((pdfTemplate) => {
      let pdfTemplateCopied = copyObject(pdfTemplate);
      pdfTemplateCopied.value = pdfTemplateCopied.id;
      pdfTemplateCopied.label = pdfTemplateCopied.name;
      return pdfTemplateCopied;
    });
  };

  const getDefaultPdfTemplateIndex = (pdfTemplates) => {
    return pdfTemplates.indexOf(pdfTemplates.find((template) => template.isDefault));
  };
  //#endregion

  // #region useQuery
  useQuery({
    queryKey: ["pdfTemplates"],
    queryFn: async () => {
      try {
        const templatesPdfGetted = await getTemplatePdf(centerId);
        const pdfTemplatesModified = onGetPdfTemplateSuccess(templatesPdfGetted);
        const acreosTemplate = pdfTemplatesModified.find((pdfTemplate) => pdfTemplate.name === ACREOS_TEMPLATE);
        setPdfTemplates(pdfTemplatesModified);
        setSelectedPdfTemplate(
          pdfTemplatesModified[getDefaultPdfTemplateIndex(pdfTemplatesModified)] ?? acreosTemplate
        );
        setSelectedPeriod(
          pdfTemplatesModified[getDefaultPdfTemplateIndex(pdfTemplatesModified)]
            ? pdfTemplatesModified[getDefaultPdfTemplateIndex(pdfTemplatesModified)].showResultDates
            : acreosTemplate.showResultDates
        );
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
    refetchOnWindowFocus: false,
  });
  useQuery({
    queryKey: ["customFields", centerId],
    queryFn: async () => {
      try {
        const customFields = await getCustomField(centerId);
        dispatch(setCustomFields(customFields));
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnWindowFocus: false,
    enabled: !customFields,
  });

  useQuery({
    queryKey: ["students", centerId],
    queryFn: async () => {
      try {
        setGetStudentsLaunched(true);
        dispatch(resetStudentList());
        const date = new Date();
        const firstPaginationResponse = await getStudentsNotarchived(centerId, 0, ELEMENT_PER_PAGE);
        let allStudents = [...firstPaginationResponse.datas];
        setStudentState(allStudents);
        for (
          let index = 1;
          index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
          index++
        ) {
          const paginationResponse = await getStudentsNotarchived(centerId, index, ELEMENT_PER_PAGE);
          allStudents = allStudents.concat(paginationResponse.datas);
          setStudentState(allStudents);
        }
        dispatch(setStudents(allStudents));
        dispatch(setStudentsGettedDate(DateTimeToIsoString(date)));
      } catch (error) {
        if (localStorage.getItem("isLogIn")) dispatch(setErrorNotification(error));
      }
    },
    enabled: !getStudentsLaunched && mounted,
    refetchInterval: 10,
  });

  //#endregion

  //#region useEffect
  useEffect(() => {
    setMounted(true);
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);
  //#endregion

  return (
    <main className="student-page">
      {isLoading && <GstaLoaderPage />}
      {importModalIsOpen && (
        <ImportStudentsModal
          modalOpen={importModalIsOpen}
          setModalOpen={setImportModalIsOpen}
          addStudents={addStudents}
        />
      )}
      <ScrollToTopController />
      <header>
        <h1>{getTranslations("student_all_students", translations)}</h1>
        <div>
          <button
            className="gsta-button-fill create-student-button"
            data-testid={"redirect-to-create-student"}
            onClick={RedirectToCreateStudent}
          >
            <i className="icon-add_user" />
            <span>{getTranslations("student_add_students", translations)}</span>
          </button>
          <button
            className="gsta-button-outlined"
            onClick={() => {
              setImportModalIsOpen(true);
            }}
          >
            <i className="icon-excel_icon" />
            {getTranslations("excel_import_button_import", translations)}
          </button>
        </div>
      </header>
      <GstaTable
        values={studentState}
        selectable
        headerDefinitions={headerDefinition}
        GroupedAction={StudentGroupedActions}
        GroupedActionProps={{
          removeStudents: removeStudents,
          setValues: setValues,
          pdfTemplates: pdfTemplates,
          setPdfTemplates: setPdfTemplates,
          selectedPeriod: selectedPeriod,
          setSelectedPeriod: setSelectedPeriod,
          selectedPdfTemplate: selectedPdfTemplate,
          setSelectedPdfTemplate: setSelectedPdfTemplate,
          personalizedStartDate: personalizedStartDate,
          setPersonalizedStartDate: setPersonalizedStartDate,
          personalizedEndDate: personalizedEndDate,
          setPersonalizedEndDate: setPersonalizedEndDate,
        }}
        totalElementsCount={totalElementsCount}
        setValue={setValue}
        ExpandedComponent={StudentDetail}
        Actions={StudentActions}
        ActionsProps={{
          manageWithDates: settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES] === "true",
          deleteStudent: deleteStudent,
          setValues: setValues,
          pdfTemplates: pdfTemplates,
          setPdfTemplates: setPdfTemplates,
          selectedPdfTemplate: selectedPdfTemplate,
          setSelectedPdfTemplate: setSelectedPdfTemplate,
          selectedPeriod: selectedPeriod,
          setSelectedPeriod: setSelectedPeriod,
          personalizedStartDate: personalizedStartDate,
          setPersonalizedStartDate: setPersonalizedStartDate,
          personalizedEndDate: personalizedEndDate,
          setPersonalizedEndDate: setPersonalizedEndDate,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
        }}
        SearchInputPlaceHolder={getTranslations("student_find_student_placeholder", translations)}
        noResultPlaceHolder={getTranslations("student_find_student_no_result", translations)}
        numberPerPageText={KeyTrad_NumberPerPage.Student}
        pagination
        globalSearch
        tableId={GSTA_TABLE_IDS.STUDENT_TABLE}
      />
      <RedirectionLink
        onLinkClick={() => {
          navigate(STUDENTS_ARCHIVED.url);
        }}
        translation={getTranslations("link_archived_student", translations)}
        icon={"icon-log_in"}
      />
    </main>
  );
};

export default StudentPage;
