//#region react import
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
//#endregion

//#region component import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region store import
import { setStudent } from "../../../ReduxStore/studentsSlice";
//#endregion

//#region functions import
import { stringFormatter } from "../../../Helper/StringFormatter";
import { copyObject } from "../../../Helper/CopyObject";
//#endregion

//#region constants import
import { STANDARD_FORMAT_STRING_LENGTH, STANDARD_FORMAT_STRING_MAX_LENGTH } from "../../../Constants/StringConstants";
import { STUDENTS_TRAINING } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./StudentName.style.scss";
//#endregion

const StudentName = ({ value, overrideProps }) => {
  //#region constant
  const studentName = value.name + " " + value.firstName;
  const exportPdfData = {
    pdfTemplates: overrideProps.pdfTemplates,
    selectedPdfTemplate: overrideProps.selectedPdfTemplate,
    selectedPeriod: overrideProps.selectedPeriod,
  };
  //#endregion

  //#region other use
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region functions

  const redirectToStudentDetail = () => {
    dispatch(setStudent({ newStudent: value }));
    navigate(STUDENTS_TRAINING.url, {
      state: {
        exportPdfData: copyObject(exportPdfData),
      },
    });
  };

  const renderContent = () => {
    if (studentName.length < STANDARD_FORMAT_STRING_MAX_LENGTH)
      return (
        <p
          className="acreos-link"
          onClick={redirectToStudentDetail}
        >
          {studentName}
        </p>
      );
    else
      return (
        <GstaToolTip toolTipContent={studentName}>
          <p
            className="acreos-link"
            onClick={redirectToStudentDetail}
          >
            {stringFormatter(studentName, STANDARD_FORMAT_STRING_LENGTH)}
          </p>
        </GstaToolTip>
      );
  };

  //#endregion

  return <>{renderContent()}</>;
};
export default StudentName;
