//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosNavigation from "../../Components/AcreosNavigation/AcreosNavigation.component";
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import GlobalLayout from "../GlobalLayout/GlobalLayout.component";
//#endregion

//#region function import
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import HomeNav from "../../Helper/Navigation/HomeNavigation";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { HOME } from "../../Routing/PageNames";
//#endregion

const HomeGlobalLayout = ({ children }) => {
  //#region constants
  const dataHomeMenu = HomeNav();
  //#endregion

  //#region other use
  const trainerFirstName = useSelector((state) => state.connexionSlice.trainer?.firstName);
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  return (
    <GlobalLayout
      icon="dashboard"
      title={HOME.pageTraductionKey}
    >
      <ScrollToTopController />
      <PageWithTitle title={`${getTranslations("home_hello", translations)} ${trainerFirstName}`}>
        <AcreosNavigation
          maxNavElement={6}
          navData={dataHomeMenu}
          structureClass={"gsta-sub-navigation"}
          overrideCustomLinkClass={"gsta-sub-navigation-link"}
        />
        <div className="padding-bottom-3rem">{children}</div>
      </PageWithTitle>
    </GlobalLayout>
  );
};

export default HomeGlobalLayout;
