//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import GstaTableHeaderFilter from "../GstaTableHeaderFilter/GstaTableHeaderFilter.component";
import GstaTableHeaderFilterDate from "../GstaTableHeaderFilterDate/GstaTableHeaderFilterDate.component";
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region constants import
import { ASCENDING } from "../../Constants/OrderedType";
import { FILTERED, FILTERED_BY_DATE, ORDERED } from "../../Constants/HeaderType";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

const GstaTableHeader = ({
  headerDefinitions,
  action,
  orderedColumn,
  orderedType,
  selectable,
  handleOrderedColumn,
  modifyFilter,
  modifyFilterDate,
  filters,
  getColumnValues,
  setSelectAll,
  allSelected,
}) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  const handleChange = () => {
    setSelectAll(!allSelected);
  };

  const getSortedIcon = (columnName) => {
    if (columnName !== orderedColumn) {
      return (
        <button
          className="icon-button"
          onClick={() => handleOrderedColumn(columnName)}
        >
          <i className="icon-down_arrow"></i>
        </button>
      );
    } else {
      return orderedType === ASCENDING ? (
        <button
          className="icon-button"
          onClick={() => handleOrderedColumn(columnName)}
        >
          <i className="icon-up_arrow active"></i>
        </button>
      ) : (
        <button
          className="icon-button"
          onClick={() => handleOrderedColumn(columnName)}
        >
          <i className="icon-down_arrow active"></i>
        </button>
      );
    }
  };

  const renderFilter = (columnDefinition) => {
    return (
      <GstaTableHeaderFilter
        columnDefinition={columnDefinition}
        modifyFilter={modifyFilter}
        filter={filters[columnDefinition.columnName] ?? []}
        getColumnValues={getColumnValues}
      />
    );
  };

  const renderFilterDates = (columnDefinition) => {
    return (
      <GstaTableHeaderFilterDate
        columnDefinition={columnDefinition}
        modifyFilter={modifyFilterDate}
        filter={filters[columnDefinition.columnName] ?? []}
        filters={filters}
      />
    );
  };

  const renderIcon = (columnDefinition) => {
    switch (columnDefinition.type) {
      case FILTERED:
        return renderFilter(columnDefinition);
      case FILTERED_BY_DATE:
        return renderFilterDates(columnDefinition);
      case ORDERED:
        return getSortedIcon(columnDefinition.columnName);
      default:
        return;
    }
  };

  return (
    <thead>
      <tr>
        {selectable && (
          <th
            className="gsta-table-complete-select-column"
            width={"2.5%"}
          >
            <AcreosCheckBox
              checked={allSelected}
              onChange={handleChange}
            />
          </th>
        )}
        {headerDefinitions.map((columnDefinition, index) => (
          <th
            key={index}
            width={columnDefinition.width}
          >
            <div className="gsta-table-complete-header">
              <span className="gsta-table-complete-header-filter-column-name">{columnDefinition.columnName}</span>
              <div>{renderIcon(columnDefinition)}</div>
            </div>
          </th>
        ))}
        {action && <th className="action">{getTranslations("common_action", translations)}</th>}
      </tr>
    </thead>
  );
};

export default GstaTableHeader;
