export const responseMorhange = {
  datas: [
    {
      id: 52,
      simulatorSerial: "SME-66668",
      centerId: 3,
      centerName: "Morhange",
      language: "",
    },
    {
      id: 51,
      simulatorSerial: "SME-66667",
      centerId: 3,
      centerName: "Morhange",
      language: "",
    },
    {
      id: 50,
      simulatorSerial: "SME-66666",
      centerId: 3,
      centerName: "Morhange",
      language: "",
    },
  ],
  totalNumberOfElements: 33,
  pageNumber: 0,
  elementPerPage: 10,
  metaDatas: [
    {
      rel: "NextPage",
      href: "http://localhost:5003/v1.0/Centers/1/Journeys?pageNumber=1&numberOfElementByPage=5",
    },
  ],
};

export const responseHauconcourt = {
  datas: [
    {
      id: 3,
      simulatorSerial: "SME-10020",
      centerId: 2,
      centerName: "hauconccourt",
      language: "Français",
    },
    {
      id: 17,
      simulatorSerial: "SME-88128",
      centerId: 2,
      centerName: "hauconccourt",
      language: "Français",
    },
    {
      id: 16,
      simulatorSerial: "TestStefanApiPro",
      centerId: 2,
      centerName: "hauconccourt",
      language: "",
    },
    {
      id: 14,
      simulatorSerial: "MSI-S-DORIDAT",
      centerId: 2,
      centerName: "hauconccourt",
      language: "",
    },
    {
      id: 8,
      simulatorSerial: "SME-10064",
      centerId: 2,
      centerName: "hauconccourt",
      language: "",
    },
    {
      id: 7,
      simulatorSerial: "SME-10560",
      centerId: 2,
      centerName: "hauconccourt",
      language: "",
    },
    {
      id: 20,
      simulatorSerial: "SME-12345",
      centerId: 2,
      centerName: "hauconccourt",
      language: "",
    },
  ],
  totalNumberOfElements: 33,
  pageNumber: 0,
  elementPerPage: 10,
  metaDatas: [],
};
