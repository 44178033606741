//#region react import
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
//#endregion

//#region component import
import ActivityCard from "../ActivityCard/ActivityCard.component";
import GstaCalendar from "../GstaCalendar/GstaCalendar.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import AcreosDatePicker from "../../../Components/AcreosDatePicker/AcreosDatePicker.component";
//#endregion

//#region service import
import { getMonthlyActivity } from "../../../Services/CenterService";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region functions import
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
import { getTranslations } from "../../../Helper/TranslationController";
import { formatSecondToStringTime, getFirstDayOfMonth, FORMAT_TIMESTRING } from "../../../Helper/TimeConverter";
//#endregion

//#region constants import
import { MONTH_ACTIVITY } from "../../../Routing/PageNames";
import {
  AVERAGE_TIME_BY_STUDENT,
  CURRENT_VALUE,
  PER_ENTITY,
  SIMULATOR,
  STUDENT_COUNT,
  TIME_SIMULATION,
  TIME_SIMULATION_PER_SIMULATOR,
} from "../../../Constants/DataConstants";
import { DAYS, MONTHS } from "../../../Constants/DateConstants";
import { DATEPICKER_TYPE } from "../../../Components/AcreosDatePicker/AcreosDatePicker.constants";
//#endregion

//#region style import
import "./MonthActivity.style.scss";
//#endregion

const MonthActivity = () => {
  //#region state
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [monthlyActivity, setMonthlyActivity] = useState();
  const [loading, setLoading] = useState(false);
  const [datepickerOpen, setDatePickerOpen] = useState(false);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenter = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const dispatch = useDispatch();
  const refButton = useRef();
  //#endregion

  //#region functions
  const handleSetSelectedDateTime = async (newDateTime) => {
    setSelectedDateTime(newDateTime);
    await getMonthActivity(newDateTime.getFullYear(), newDateTime.getMonth() + 1);
  };

  const getMonthActivity = async (year, month) => {
    try {
      setLoading(true);
      let monthlyActivityFromApi;
      try {
        monthlyActivityFromApi = JSON.parse(await getMonthlyActivity(activeCenter, getFirstDayOfMonth(year, month)));
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
      setMonthlyActivity(monthlyActivityFromApi);
      setLoading(false);
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const getMonthlyActivityQuery = async () => {
    const currentDate = new Date();
    let monthlyActivityFromApi;
    monthlyActivityFromApi = JSON.parse(
      await getMonthlyActivity(activeCenter, getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth() + 1))
    );
    return monthlyActivityFromApi;
  };
  //#endregion

  //#region useQuery
  const { isFetching } = useQuery({
    queryKey: ["monthActivity"],
    queryFn: async () => {
      try {
        const monthActivity = await getMonthlyActivityQuery();
        setMonthlyActivity(monthActivity);
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
    refetchOnWindowFocus: false,
  });
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="month-activity">
      <ScrollToTopController />
      {(isFetching || loading) && <GstaLoaderPage LoadingText={getTranslations("home_loading", translations)} />}
      <div className="home-title">
        <h1>{getTranslations(MONTH_ACTIVITY.pageTraductionKey, translations)}</h1>
        <AcreosDatePicker
          type={DATEPICKER_TYPE.SELECT_MONTH}
          months={MONTHS.map((month) => ({
            name: getTranslations(month.traductionKey, translations),
            number: month.number,
          }))}
          days={DAYS.map((day) => ({
            name: getTranslations(day.traductionKey, translations),
            number: day.number,
          }))}
          selectedDateTime={selectedDateTime}
          setSelectedDateTime={handleSetSelectedDateTime}
          disabledFutureDate
          datePickerOpen={datepickerOpen}
          setDatePickerOpen={setDatePickerOpen}
          refOpenDatePicker={refButton}
        >
          <button
            className="gsta-date-picker"
            onClick={() => setDatePickerOpen(!datepickerOpen)}
            ref={refButton}
          >
            <span>
              {`${getTranslations(
                MONTHS.find((month) => month.number === selectedDateTime.getMonth() + 1).traductionKey,
                translations
              )} ${selectedDateTime.getFullYear()}`}
            </span>
            <i className={`icon-expend ${datepickerOpen ? "gsta-date-picker-icon-down" : ""}`} />
          </button>
        </AcreosDatePicker>
      </div>

      <hr className="activity-separator" />
      <h3>
        <b>{getTranslations("home_month_activity_history", translations)}</b>
        <GstaToolTip toolTipContent={getTranslations("home_month_activity_history_informations", translations)}>
          <i className="icon-question" />
        </GstaToolTip>
      </h3>
      <div className="activity-container">
        <div className="calendar-container">
          <GstaCalendar
            month={selectedDateTime.getMonth() + 1}
            year={selectedDateTime.getFullYear()}
            activities={monthlyActivity}
            simulatorOrStudent={SIMULATOR}
          />
        </div>
        <div className="activity-card-grid">
          <ActivityCard
            value={
              monthlyActivity
                ? formatSecondToStringTime(
                    monthlyActivity[SIMULATOR][TIME_SIMULATION][CURRENT_VALUE],
                    translations,
                    FORMAT_TIMESTRING.HHMM
                  )
                : getTranslations("common_default_time", translations)
            }
            legend={getTranslations("home_simulator_time", translations)}
            loading={loading}
          />
          <ActivityCard
            value={monthlyActivity ? monthlyActivity[SIMULATOR][STUDENT_COUNT][CURRENT_VALUE].toString() : "--"}
            legend={getTranslations("home_student_connected", translations)}
          />

          <ActivityCard
            value={
              monthlyActivity
                ? formatSecondToStringTime(
                    monthlyActivity[SIMULATOR][AVERAGE_TIME_BY_STUDENT][CURRENT_VALUE],
                    translations,
                    FORMAT_TIMESTRING.HHMM
                  )
                : getTranslations("common_default_time", translations)
            }
            legend={getTranslations("home_student_simulator_time_average", translations)}
          />
        </div>
      </div>

      <h3>
        <b>{getTranslations("home_simulator_activity", translations)}</b>
      </h3>
      <div className="activity-card-grid-simulator">
        {monthlyActivity &&
          Object.keys(monthlyActivity[SIMULATOR][PER_ENTITY]).map((key) => (
            <ActivityCard
              key={key}
              simulatorName={key}
              value={formatSecondToStringTime(
                monthlyActivity[SIMULATOR][PER_ENTITY][key][TIME_SIMULATION_PER_SIMULATOR][CURRENT_VALUE],
                translations,
                FORMAT_TIMESTRING.HHMM
              )}
            />
          ))}
      </div>
    </div>
  );
};

export default MonthActivity;
