//#region react import
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import { DisableOverflow, EnableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModalConfirmation from "../../../Components/GstaModalConfirmation/GstaModalConfirmation.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import { deleteTraining, setSelectedTrainingId, setTrainings } from "../../../ReduxStore/trainingSlice";
//#endregion

//#region services import
import { deleteCustomTraining, setArchivedtrainings } from "../../../Services/TrainingService";
//#endregion

//#region constants import
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
import { TRAININGS_CHOOSE_MACHINE, TRAININGS_DETAILS } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./TrainingAction.style.scss";
//#endregion

/**
 * All the user actions of a training table
 */

const TrainingAction = ({ value, ActionsProps }) => {
  //#region useState import
  const [modalOpen, setModalOpen] = useState(false);
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false);
  //#endregion

  //#region useSelector import
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  //#endregion
  //#region others use import
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const showTrainingDetails = () => {
    navigate(TRAININGS_DETAILS.url, { state: { training: value } });
    dispatch(setSelectedTrainingId(value.id));
  };

  const redirectChooseMachine = () => {
    navigate(TRAININGS_CHOOSE_MACHINE.url, { state: { training: value } });
  };

  const openModal = () => {
    setModalOpen(true);
    DisableOverflow();
  };

  const openArchiveModal = () => {
    setModalArchiveOpen(true);
    DisableOverflow();
  };

  const closeModal = () => {
    setModalOpen(false);
    EnableOverflow();
  };

  const closeArchiveModal = () => {
    setModalArchiveOpen(false);
    EnableOverflow();
  };

  const modalValidate = async () => {
    if (value.studentCount > 0) {
      dispatch(
        setErrorNotification({
          message: getTranslations("notification_delete_training_failed", translations),
        })
      );
    } else {
      try {
        await deleteCustomTraining(centerId, value.id);
        dispatch(deleteTraining(value.id));
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    }
    closeModal();
    ActionsProps.isArchivedTrainingPage && ActionsProps.refetchArchivedTrainings();
  };

  const addModifiedTrainingToSlice = () => {
    let trainingsCopied = copyObject(trainings);
    let valueCopied = copyObject(value);
    valueCopied.isArchivedByCusForCenter = false;
    trainingsCopied.push(valueCopied);
    return trainingsCopied;
  };

  const modalArchiveUnarchiveValidate = async () => {
    closeArchiveModal();
    ActionsProps.setLoading(true);
    try {
      if (ActionsProps.isArchivedTrainingPage) {
        await setArchivedtrainings(centerId, value.id, false);
        dispatch(setTrainings(addModifiedTrainingToSlice()));
        ActionsProps.isArchivedTrainingPage && ActionsProps.refetchArchivedTrainings();
      } else {
        await setArchivedtrainings(centerId, value.id, true);
        ActionsProps.setTrainingState(ActionsProps.trainingsState.filter((training) => training.id !== value.id));
        //deleting the training in store
        dispatch(deleteTraining(value.id));
      }
    } catch (e) {
      dispatch(
        setErrorNotification({ ...e, message: getTranslations("archive_unarchive_training_error", translations) })
      );
    }
    ActionsProps.setLoading(false);
  };
  //#endregion
  return (
    <div className="training-table-actions">
      <GstaToolTip toolTipContent={getTranslations("training_layout_training_detail", translations)}>
        <button
          className="gsta-table-action-button"
          data-testid={"training-step-layout-action-" + value.id}
          onClick={showTrainingDetails}
        >
          <i className="icon-file_search"></i>
        </button>
      </GstaToolTip>
      {!ActionsProps.isArchivedTrainingPage && (
        <GstaToolTip toolTipContent={getTranslations("personnalized_training_duplicate", translations)}>
          <button
            className="gsta-table-action-button"
            data-testid={"training-step-layout-action-choose-machine-" + value.id}
            onClick={redirectChooseMachine}
          >
            <i className="icon-copy"></i>
          </button>
        </GstaToolTip>
      )}
      <GstaToolTip
        toolTipContent={getTranslations(
          ActionsProps.isArchivedTrainingPage ? "Unarchive_training" : "archive_training",
          translations
        )}
      >
        <button
          className="gsta-table-action-button"
          data-testid={"training-step-layout-action-archive-training-" + value.id}
          onClick={openArchiveModal}
        >
          <i className={ActionsProps.isArchivedTrainingPage ? "icon-unarchive" : "icon-archive"}></i>
        </button>
      </GstaToolTip>
      {value.isDeletable && (
        <GstaToolTip toolTipContent={getTranslations("personnalized_training_delete_title_popup", translations)}>
          <button
            className="gsta-table-action-button"
            data-testid={"training-step-layout-action-delete-" + value.id}
            onClick={openModal}
          >
            <i className="icon-poubelle"></i>
          </button>
        </GstaToolTip>
      )}
      <GstaModalConfirmation
        modalOpen={modalOpen}
        handleCloseModal={closeModal}
        handleValidate={modalValidate}
        title={getTranslations("personnalized_training_delete_title_popup", translations)}
        messages={[
          `${getTranslations("personnalized_training_delete_text_start_popup", translations)} ${value.trainingName}`,
          getTranslations("personnalized_training_delete_text_end_popup", translations),
          getTranslations("personnalized_field_delete_confirm", translations),
        ]}
        cancelText={getTranslations(NO, translations)}
        validText={getTranslations(YES, translations)}
      />
      <GstaModalConfirmation
        modalOpen={modalArchiveOpen}
        handleCloseModal={closeArchiveModal}
        handleValidate={modalArchiveUnarchiveValidate}
        title={getTranslations(
          ActionsProps.isArchivedTrainingPage ? "Unarchive_training_title_popup" : "archive_training_title_popup",
          translations
        )}
        messages={[
          `${getTranslations(
            ActionsProps.isArchivedTrainingPage ? "about_to_unarchive_training" : "about_to_archive_training",
            translations
          )} ${value.trainingName}`,
          getTranslations(
            ActionsProps.isArchivedTrainingPage ? "training_unarchive_confirm" : "training_archive_confirm",
            translations
          ),
        ]}
        cancelText={getTranslations(NO, translations)}
        validText={getTranslations(YES, translations)}
      />
    </div>
  );
};

export default TrainingAction;
