//#region react import
import { Text, View, StyleSheet } from "@react-pdf/renderer";
//#endregion

//#region component import
import PdfTitle, { TITLE } from "../Pdf/PdfTitle";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
import { Fragment } from "react";
//#endregion

const styles = StyleSheet.create({
  mainContainer: {
    margin: "24px",
    width: "100%",
  },
  twooColumns: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  textContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginVertical: "2px",
  },
  boldText: {
    fontWeight: "bold",
  },
  boldTextCustomValue: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontWeight: "bold",
    marginLeft: "5px",
  },
});

const GroupedPdfInformation = ({ groupInfos, translations, groupWithDate, groupedPdfData, customFields }) => {
  const allStudents = [];
  groupedPdfData.forEach((machineResult) => {
    machineResult.students.forEach((machineStudent) => {
      if (!allStudents.find((student) => student.id === machineStudent.id)) {
        allStudents.push(machineStudent);
      }
    });
  });

  return (
    <View>
      <PdfTitle
        type={TITLE}
        label={getTranslations("grouped_pdf_group_infos", translations)}
      />
      <View style={styles.mainContainer}>
        <View style={styles.twooColumns}>
          <View style={styles.textContainer}>
            <Text>{`${getTranslations("grouped_pdf_group_name", translations)} `}</Text>
            <Text style={styles.boldText}>{groupInfos.groupName}</Text>
          </View>
          <View style={styles.textContainer}>
            <Text>{`${getTranslations("grouped_pdf_student_count", translations)} `}</Text>
            <Text style={styles.boldText}>{groupInfos.students.length}</Text>
          </View>
          {groupWithDate && (
            <View style={styles.textContainer}>
              <Text>{`${getTranslations("session_date_pdf", translations)} `}</Text>
              <Text style={styles.boldText}>{`${DateTimeToIsoString(groupInfos.startGroup)} ${getTranslations(
                "session_date_to",
                translations
              )} ${DateTimeToIsoString(groupInfos.endGroup)}`}</Text>
            </View>
          )}
          {customFields.map((customField) => {
            if (customField.isInPDF) {
              return (
                <View
                  key={customField.name}
                  style={styles.textContainer}
                >
                  <Text style={styles.textContainer}>{customField.name}</Text>
                  {allStudents.map((student) => {
                    const customValue = student.customValues.find((customValue) => customValue.id === customField.id);
                    return customValue ? (
                      <Text
                        style={styles.boldTextCustomValue}
                        key={`${customField.name}-${student.firstName}-${student.name}`}
                      >{`${student.name} ${student.firstName} - ${customValue.value}`}</Text>
                    ) : (
                      <Fragment key={`${customField.name}-${student.firstName}-${student.name}`} />
                    );
                  })}
                </View>
              );
            } else return <></>;
          })}
        </View>
      </View>
    </View>
  );
};

export default GroupedPdfInformation;
