//#region react import
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import GstaInput from "../../../Components/GstaInput/GstaInput.component";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { SetDataTestId } from "../../../Helper/DataTestId";
//#endregion

//#region constants import
import { CANCEL, VALIDATE } from "../../../Components/GstaModal/GstaModal.constants";
import { INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
//#endregion

//#region style import
import "./EditDeleteActions.style.scss";
//#endregion

/**
 * The actions button to delete and edit a custom field
 */
const EditDeleteActions = ({ ActionsProps, value }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  //#region useState
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState("");
  //#endregion
  //#region others use...
  //#endregion

  //#region functions
  const handleDeleteClick = () => {
    ActionsProps.deleteAction(value);
    setFieldToDelete("");
    setModalConfirmationOpen(false);
  };

  const handleEditClick = () => {
    ActionsProps.openModalForEditing(value);
  };

  const closeModalConfiramtion = () => {
    setModalConfirmationOpen(false);
    setFieldToDelete("");
  };

  const renderMessages = () => {
    return (
      <>
        {ActionsProps.messages?.map((message, index) => {
          if (index === 0)
            return (
              <span key={`message-${index}`}>
                {message} {value.name}
              </span>
            );
          return (
            <span
              key={`message-${index}`}
              className="margin-bottom"
            >
              {message}
            </span>
          );
        })}
      </>
    );
  };
  //#endregion

  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div className="custom-field_actions">
      {(!ActionsProps.isVisible || ActionsProps.isVisible(value)) && (
        <>
          <GstaModal
            modalOpen={modalConfirmationOpen}
            handleCloseModal={closeModalConfiramtion}
            cancelText={getTranslations(CANCEL, translations)}
            validText={getTranslations(VALIDATE, translations)}
            title={ActionsProps.title}
            handleValidate={handleDeleteClick}
            validateDisabled={fieldToDelete !== value.name}
          >
            {renderMessages()}
            <span className="color-invalid not_selectable">{`${getTranslations(
              "personnalized_field_confirm_type_start",
              translations
            )} "${value.name}" ${getTranslations("personnalized_field_confirm_type_end", translations)}`}</span>
            <GstaInput
              placeholder={getTranslations("personnalized_field_confirm_exemple", translations)}
              isValid={fieldToDelete === value.name}
              value={fieldToDelete}
              setValue={setFieldToDelete}
              appearanceClass={"gsta-input--black mid-size"}
              type={INPUT_TEXT}
            />
          </GstaModal>

          <GstaToolTip
            toolTipContent={getTranslations("delete", translations)}
            disabled={value.setIsInGridOverride !== undefined}
          >
            <button
              className="gsta-table-action-button"
              onClick={setModalConfirmationOpen}
              disabled={value.setIsInGridOverride !== undefined}
            >
              <i
                data-testid={SetDataTestId("delete")}
                className="icon-poubelle"
              />
            </button>
          </GstaToolTip>
          <GstaToolTip
            toolTipContent={getTranslations("personnalized_field_edit_over", translations)}
            disabled={value.setIsInGridOverride !== undefined}
          >
            <button
              className="gsta-table-action-button"
              onClick={handleEditClick}
              disabled={value.setIsInGridOverride !== undefined}
            >
              <i
                data-testid={SetDataTestId("edit")}
                className="icon-edit"
              />
            </button>
          </GstaToolTip>
        </>
      )}
    </div>
  );
};
EditDeleteActions.propTypes = {
  ActionsProps: PropTypes.shape({
    openModalForEditing: PropTypes.func.isRequired,
    deleteAction: PropTypes.func.isRequired,
    isVisible: PropTypes.func.isRequired,
  }),
};
export default EditDeleteActions;
