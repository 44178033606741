//#region component import
import React from "react";
import PropTypes from "prop-types";
//#endregion

//#region style import
import "./ActivityCard.style.scss";
//#endregion

const ActivityCard = ({ simulatorName, value, legend }) => {
  return (
    <div className="activity-card">
      <p className="activity-card-simulator-name">{simulatorName && <b>{simulatorName}</b>}</p>
      <p className="activity-card-main-text">
        <b>{value}</b>
      </p>
      <p className="activity-card-legend">{legend}</p>
    </div>
  );
};

ActivityCard.propTypes = {
  simulatorName: PropTypes.string,
  value: PropTypes.string.isRequired,
  legend: PropTypes.string,
};

export default ActivityCard;
