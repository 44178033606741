export const getCenterResponse1 = {
  datas: [
    {
      id: 5,
      name: "center1",
      simulatorCount: 1,
      studentCount: 0,
    },
    {
      id: 6,
      name: "Name0",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 8,
      name: "Name2",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 9,
      name: "name2",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 1,
      name: "Hauconcourt0",
      simulatorCount: 0,
      studentCount: 2,
    },
    {
      id: 10,
      name: "name4",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 11,
      name: "name5",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 12,
      name: "name12",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 13,
      name: "testModified",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 4,
      name: "testName3",
      simulatorCount: 3,
      studentCount: 17,
    },
  ],
  totalNumberOfElements: 35,
  pageNumber: 0,
  elementPerPage: 10,
  metaDatas: [
    {
      rel: "NextPage",
      href: "http://localhost:5003/v1.0/Centers/1/Journeys?pageNumber=1&numberOfElementByPage=5",
    },
  ],
};

export const getCenterResponse2 = {
  datas: [
    {
      id: 22,
      name: "name14",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 23,
      name: "centre13",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 24,
      name: "name15",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 26,
      name: "Name26Modified",
      simulatorCount: 0,
      studentCount: 0,
    },
    {
      id: 27,
      name: "name27",
      simulatorCount: 0,
      studentCount: 0,
    },
  ],
  totalNumberOfElements: 35,
  pageNumber: 1,
  elementPerPage: 10,
  metaDatas: [
    {
      rel: "PrevPage",
      href: "http://localhost:5003/v1.0/Centers/1/Journeys?pageNumber=1&numberOfElementByPage=5",
    },
  ],
};

export const getCenterResponse3 = [
  {
    simulatorSerial: "SME-10592",
    firstName: "BEJACH",
    isActive: true,
    launchedMachine: { code: "VL1" },
    name: "Laure",
    trainingLanguageKey: "jrn_VL1_journey_name",
  },
  {
    simulatorSerial: "SME-10593",
    isActive: false,
  },
  {
    simulatorSerial: "SME-10594",
    isActive: false,
  },
  {
    simulatorSerial: "SME-10595",
    isActive: false,
  },
];
