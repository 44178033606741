//#region react import
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region style import
import "./BackButton.style.scss";
//#endregion

const BackButton = ({ backFunction, text }) => {
  //#region other use
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region state
  const [previousPage] = useState(location?.state?.previousPage);
  //#endregion

  //#region function
  const handleOnclick = () => {
    if (previousPage) {
      navigate(previousPage);
      return;
    } else if (backFunction) {
      backFunction();
      return;
    } else navigate(-1);
  };
  //#endregion

  return (
    <div
      className="back-button"
      onClick={handleOnclick}
    >
      <button>
        <i className="icon-navigation_back" />
      </button>
      <span>{text}</span>
    </div>
  );
};

export default BackButton;
