//#region react import
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Fragment } from "react";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { DateTimeToIsoString, formatSecondToStringTime } from "../../../Helper/TimeConverter";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
import { MONTHS } from "../../../Constants/DateConstants";
//#endregion

const SimulationPlanning = ({ calendar, translations }) => {
  //#region style
  const styles = StyleSheet.create({
    planningSection: {
      borderColor: COLOR.orange,
      borderStyle: "solid",
      borderWidth: "2px",
      marginBottom: "30px",
    },

    simulationPlanning: {
      width: "100%",
    },
    mainTitle: {
      width: "100%",
      backgroundColor: COLOR.orangeLight,
      textAlign: "center",
      fontSize: FONT_SIZE.mainTitle,
      paddingVertical: "5px",
    },
    monthTitle: {
      width: "100%",
      backgroundColor: COLOR.orange,
      textAlign: "center",
      paddingVertical: "8px",
      fontSize: FONT_SIZE.subtitle,
      flexDirection: "row",
      justifyContent: "center",
      gap: "5px",
    },
    monthTitleBold: {
      fontWeight: "bold",
    },
    calendarHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: COLOR.grey,
      color: COLOR.textColorInversed,
      paddingVertical: "8px",
      fontWeight: "bold",
    },
    tableLign: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingVertical: "12px",
    },
    tableCell: {
      width: "100%",
      textAlign: "center",
      fontSize: "11px",
    },
  });
  //#endregion
  //#region functions
  const getCalendarGroupByYearAndMounth = () => {
    const calendarGroupByYearMonth = {};
    calendar?.forEach((calendarDate) => {
      const date = new Date(calendarDate.activityDate);
      if (!calendarGroupByYearMonth[date.getFullYear()]) {
        calendarGroupByYearMonth[date.getFullYear()] = {};
      }
      if (!calendarGroupByYearMonth[date.getFullYear()][date.getMonth()]) {
        calendarGroupByYearMonth[date.getFullYear()][date.getMonth()] = [];
      }
      calendarGroupByYearMonth[date.getFullYear()][date.getMonth()].unshift(calendarDate);
    });
    return calendarGroupByYearMonth;
  };

  const getTotalMonthTimeSimulation = (timesSmulations) => {
    let totalSimulation = 0;
    timesSmulations.forEach((time) => (totalSimulation += time.duration));
    return formatSecondToStringTime(totalSimulation, translations);
  };
  //#endregion

  //#region constants
  const calendarGroupByYearAndMonth = getCalendarGroupByYearAndMounth();
  //#endregion

  return (
    <View style={styles.planningSection}>
      <Text style={styles.mainTitle}>{getTranslations("simulation_planning", translations)}</Text>
      <View style={styles.simulationPlanning}>
        {Object.keys(calendarGroupByYearAndMonth).map((year) =>
          Object.keys(calendarGroupByYearAndMonth[year]).map((month) => (
            <Fragment key={`${year}-${month}`}>
              <View style={styles.monthTitle}>
                <Text style={styles.monthTitleBold}>{`${getTranslations(
                  MONTHS.find((monthTrad) => parseInt(month) + 1 === monthTrad.number)?.traductionKey ?? month,
                  translations
                ).toUpperCase()} `}</Text>
                <Text>{`${year} - ${getTotalMonthTimeSimulation(
                  calendarGroupByYearAndMonth[year][month]
                )} ${getTranslations("of_simulation", translations)}`}</Text>
              </View>
              <View style={styles.calendarHeader}>
                <Text style={styles.tableCell}>{getTranslations("personnalized_field_date", translations)}</Text>
                <Text style={styles.tableCell}>
                  {getTranslations("student_detail_training_machine_total_time_elapsed", translations)}
                </Text>
              </View>
              {calendarGroupByYearAndMonth[year][month].map((calendarValue, index) => (
                <View
                  style={{ ...styles.tableLign, backgroundColor: index % 2 === 0 ? COLOR.white : COLOR.orangeLight }}
                  key={calendarValue.activityDate}
                >
                  <Text style={styles.tableCell}>{DateTimeToIsoString(new Date(calendarValue.activityDate))}</Text>
                  <Text style={styles.tableCell}>{formatSecondToStringTime(calendarValue.duration, translations)}</Text>
                </View>
              ))}
            </Fragment>
          ))
        )}
      </View>
    </View>
  );
};

export default SimulationPlanning;
