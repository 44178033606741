//#region react import
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import { AcreosButton } from "acreos-ui";
import GstaModal from "../GstaModal/GstaModal.component";
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
import SessionToolTipContent from "../SessionToolTipContent/SessionToolTipContent.component";
import StepProgressTimeline from "../StepProgressTimeline/StepProgressTimeline.component";
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./SimulatorActivityCard.style.scss";
//#endregion

/**
 * Render the activity of a simulator
 */

const SimulatorActivityCard = ({ simulator, networkSessionInfo }) => {
  //#region state
  const [modalLaunchOpen, setModalLaunchOpen] = useState(false);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getText = (simulator) => {
    return simulator?.trainingLanguageKey
      ? `${getTranslations(simulator.launchedMachine?.languageKey, translations)} - ${getTranslations(
          simulator.trainingLanguageKey,
          translations
        )}`
      : `${getTranslations(simulator.launchedMachine?.languageKey, translations)}`;
  };

  const getName = (simulator) => {
    return simulator.name ? (
      <>
        <i className="icon-helmet"></i>
        <span className="card-item_text">
          {simulator.name} {simulator.firstName}
        </span>
      </>
    ) : (
      <></>
    );
  };

  const launchNetworkSessionObserver = () => {
    window.location.href = `acreos-btp://host=${networkSessionInfo.hostIp}`;
    setModalLaunchOpen(false);
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion
  return (
    <article className="card-item">
      <GstaModal
        modalOpen={modalLaunchOpen}
        handleValidate={launchNetworkSessionObserver}
        handleCloseModal={() => setModalLaunchOpen(false)}
        title={getTranslations("collaboration_observ_button", translations)}
        validText={getTranslations("common_yes", translations)}
        cancelText={getTranslations("common_cancel", translations)}
        disableUseClickOutside
      >
        <span>{getTranslations("observ_session_warn_launch", translations)}</span>
        <span>{getTranslations("continue_confirm", translations)}</span>
      </GstaModal>
      {simulator?.isActive ? (
        <>
          <header className="card-item_title">
            {simulator.simulatorSerial}
            <div className="card-item_circle-activity" />
          </header>
          <section className="card-item_description-name">{getName(simulator)}</section>
          <section className="card-item_description-details">
            <div className="card-item_icon">
              <i className="icon-list" />
            </div>
            <div className="card-item_text">
              <span>{getText(simulator)}</span>
            </div>
          </section>
          <StepProgressTimeline
            data={{
              steps: simulator.steps,
              subscriptionId: simulator.subscriptionId,
              student: {
                firstName: simulator.firstName,
                id: simulator.studentId,
                name: simulator.name,
                guid: simulator.studentGuid,
              },
            }}
            nbDisplay={3}
            toolTips
          />
          {simulator.networkSession && networkSessionInfo && navigator.userAgent.includes("Win") && (
            <GstaToolTip
              key={networkSessionInfo.index}
              toolTipContent={
                <SessionToolTipContent
                  sessionNumber={networkSessionInfo.networkSessionId}
                  studentConnectedNumber={networkSessionInfo.students.length}
                  spaceAvailableNumber={networkSessionInfo.maxPlace}
                  students={networkSessionInfo.students}
                />
              }
            >
              <AcreosButton
                onClick={() => setModalLaunchOpen(true)}
                appearanceClass={"filled black-text"}
              >
                <i className="icon-Action_DroneView" />
                <span>{getTranslations("collaboration_observ_button", translations)}</span>
              </AcreosButton>
            </GstaToolTip>
          )}
        </>
      ) : (
        <>
          <section className="card-item_title">
            {simulator.simulatorSerial}
            <div className="card-item_circle-inactivity" />
          </section>
          <p className="card-item_description-inactivity">
            {getTranslations("home_simulator_inactivity", translations)}
          </p>
        </>
      )}
    </article>
  );
};
export default SimulatorActivityCard;
