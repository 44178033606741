//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
//#endregion

//#region component import
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import GstaModalConfirmation from "../../../Components/GstaModalConfirmation/GstaModalConfirmation.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import AcreosSelect from "../../../Components/AcreosSelect/AcreosSelect.component";
import UndisplayFeature from "../../../Components/UndisplayFeature/UndisplayFeature.component";
//#region

//#region function import
import { getTranslations } from "../../../Helper/TranslationController";
import { DisableOverflow, EnableOverflow } from "../../../Helper/Overflow";
//#endregion

//#region service import
import { updateCenterSimulator } from "../../../Services/SimulatorService";
//#endregion

//#region store import
import { updateSimulatorCenter } from "../../../ReduxStore/simulatorsSlice";
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { CANCEL, VALIDATE } from "../../../Components/GstaModal/GstaModal.constants";
//#endregion

//#region style import
import "./SimulatorsTable.style.scss";
//#endregion

const SimulatorsTable = ({ simulators, centers, tableOpened }) => {
  const [previousCenter, setPreviousCenter] = useState(0);
  const [selectedCenter, setSelectedCenter] = useState(0);
  const [selectedSimulator, setSelectedSimulator] = useState("");
  const [modalChooseCenterOpen, setModalChooseCenterOpen] = useState(false);
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);

  const translations = useSelector((state) => state.translationSlice.translations);

  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const dispatch = useDispatch();

  const getCenterName = (centerId) => {
    const center = trainer?.centers.filter((trainerCenter) => trainerCenter.id === centerId);
    return center.length > 0 ? center[0].name : centerId;
  };

  const handleChangeCenter = (value) => {
    setPreviousCenter(parseInt(selectedCenter));
    setSelectedCenter(parseInt(value));
  };

  const handleCloseModalChooseCenter = () => {
    setModalChooseCenterOpen(false);
    EnableOverflow();
  };

  const handleOpenModalChooseCenter = (simulator) => {
    setPreviousCenter(simulator.centerId);
    setSelectedCenter(simulator.centerId);
    setSelectedSimulator(simulator);
    setModalChooseCenterOpen(true);
    DisableOverflow();
  };

  const handleValidateModalChooseCenter = () => {
    if (previousCenter !== selectedCenter) {
      setModalConfirmationOpen(true);
    } else {
      handleCloseModalChooseCenter();
      EnableOverflow();
    }
  };

  const handleCancelConfirmationClick = () => {
    setModalConfirmationOpen(false);
    EnableOverflow();
  };

  const handleValidateConfirmationClick = async () => {
    try {
      const response = await updateCenterSimulator(
        trainer?.customerId,
        selectedSimulator.simulatorSerial,
        selectedCenter
      );
      const newCenterName = centers.find((center) => center.id === response.dataModified).name;
      dispatch(
        updateSimulatorCenter({
          simulatorSerial: selectedSimulator.simulatorSerial,
          centerId: selectedCenter,
          centerName: newCenterName,
        })
      );
      dispatch(
        setSuccessNotification(
          `${getTranslations("notification_simulator", translations)} ${
            selectedSimulator.simulatorSerial
          } ${getTranslations("notification_simulator_attachment", translations)} ${newCenterName}`
        )
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setModalChooseCenterOpen(false);
    setModalConfirmationOpen(false);
    EnableOverflow();
  };
  return (
    <div>
      {modalChooseCenterOpen && (
        <GstaModal
          modalOpen={modalChooseCenterOpen}
          handleCloseModal={handleCloseModalChooseCenter}
          title={`${getTranslations("center_modify_simulator", translations)} ${selectedSimulator.simulatorSerial}`}
          handleValidate={handleValidateModalChooseCenter}
          cancelText={getTranslations(CANCEL, translations)}
          validText={getTranslations(VALIDATE, translations)}
        >
          <AcreosSelect
            title={getTranslations("common_center", translations)}
            setNewValue={handleChangeCenter}
            options={centers.map((center) => ({ label: center.name, value: center.id }))}
            value={selectedCenter}
            dataTestId={"acreos-select-testid"}
            addClassCustom={"acreos-select_row"}
            isValid={selectedCenter}
          />
        </GstaModal>
      )}

      {modalConfirmationOpen && (
        <GstaModalConfirmation
          modalOpen={modalConfirmationOpen}
          handleCloseModal={handleCancelConfirmationClick}
          handleValidate={handleValidateConfirmationClick}
          cancelText={getTranslations(CANCEL, translations)}
          validText={getTranslations(VALIDATE, translations)}
          title={`
            ${getTranslations("center_modify_simulator", translations)}         
            ${selectedSimulator.simulatorSerial}
          `}
          messages={[
            `
            ${getTranslations("center_simulator_move", translations)} 
            
            ${selectedSimulator.simulatorSerial}
            
            ${getTranslations("common_of", translations)}
            
            ${centers.find((center) => center.id === previousCenter).name}
            
            ${getTranslations("common_to", translations)}
            
            ${centers.find((center) => center.id === selectedCenter).name}
            
            ?
          `,
          ]}
        />
      )}

      <div
        className="table-container"
        table-opened={tableOpened.toString()}
      >
        <table className="gsta-table gsta-table-complete">
          <thead>
            <tr>
              <th>{getTranslations("common_simulators", translations)}</th>
              <th>{getTranslations("common_center", translations)}</th>
              {/* <th>{getTranslations("center_language", translations)}</th> */}
              <UndisplayFeature isLocal={process.env.REACT_APP_LOCAL}>
                <th>{getTranslations("common_action", translations)}</th>
              </UndisplayFeature>
            </tr>
          </thead>
          <tbody>
            {simulators.map((simulator) => {
              return (
                <tr
                  key={simulator.simulatorSerial}
                  className={
                    simulators.indexOf(simulator) === simulators.length - 1 ? "gsta-table-row-no-border" : undefined
                  }
                >
                  <td>
                    <div className="simulator-table-content">
                      <i className="icon-D1B_Picto simulator-table-content-simulator-icon" />
                      <span>{simulator.simulatorSerial}</span>
                    </div>
                  </td>
                  <td>
                    <div className="simulator-table-content">
                      <i className="icon-center" />
                      <span>{getCenterName(simulator.centerId)}</span>
                    </div>
                  </td>
                  <UndisplayFeature isLocal={process.env.REACT_APP_LOCAL}>
                    <td className="simulator-table-content">
                      <GstaToolTip toolTipContent={getTranslations("center_move_simulator", translations)}>
                        <button
                          data-testid={"open-change-center-" + simulator.simulatorSerial}
                          className="gsta-button-outlined"
                          onClick={() => {
                            handleOpenModalChooseCenter(simulator);
                          }}
                        >
                          <i className="icon-edit" />
                        </button>
                      </GstaToolTip>
                    </td>
                  </UndisplayFeature>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SimulatorsTable;
