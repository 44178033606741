//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useQuery } from "react-query";
//#endregion

//#region component import
import BackButton from "../../../Components/BackButton/BackButton.component";
import GstaTable from "../../../Components/GstaTable/GstaTable.component";
import GlobalLayout from "../../../Layout/GlobalLayout/GlobalLayout.component";
import ArchivedStudentActions from "../../../Components/ArchivedStudentActions/ArchivedStudentActions.component";
import StudentDetail from "../StudentDetail/StudentDetail.component";
import StudentGroupedActions from "../StudentGroupedActions/StudentGroupedActions.component";
//#endregion

//#region service import
import { getStudentsArchived } from "../../../Services/StudentService";
//#endregion

//#region functions import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import { getTranslations } from "../../../Helper/TranslationController";
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region constants import
import KeyTrad_NumberPerPage from "../../../Constants/PaginationConstants";
import { ELEMENT_PER_PAGE } from "../../../Constants/ApiConstants";
import { STUDENTS_ARCHIVED } from "../../../Routing/PageNames";
import { getHeaderDefinition } from "../StudentPage/StudentPage.constants";
//#endregion

//#region styles import
import "./ArchivedStudentPage.style.scss";
//#endregion

/**
 * The page where all archived student are shown
 */
const ArchivedStudentStudentPage = () => {
  //#region useState
  const [archivedStudents, setArchivedStudents] = useState([]);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const settingDictionary = useSelector((state) => state.settingSlice.settingDictionary);
  const customFields = useSelector((state) => state.studentSlice.customFields);

  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const deleteArchivedStudent = (student) => {
    const newStudents = archivedStudents.filter((studentArchived) => studentArchived.id !== student.id);
    setArchivedStudents(newStudents);
  };

  const removeStudents = (studentIds) => {
    const newStudents = archivedStudents.filter((studentArchived) => !studentIds.includes(studentArchived.id));
    setArchivedStudents(newStudents);
  };

  const setValues = (newStudents) => {
    const newArchivedStudents = [...archivedStudents];
    for (let newStudent of newStudents) {
      const index = newArchivedStudents.findIndex((student) => student.id === newStudent.id);
      newArchivedStudents[index] = newStudent;
    }
    setArchivedStudents(newArchivedStudents);
  };
  //#endregion

  //#region constants
  const headerDefinition = getHeaderDefinition(translations, activeCenterId, settingDictionary, customFields, false);
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["archivedStudents", activeCenterId],
    queryFn: async () => {
      try {
        const firstPaginationResponse = await getStudentsArchived(activeCenterId, 0, ELEMENT_PER_PAGE);
        const students = firstPaginationResponse.datas;
        setArchivedStudents(students);
        for (
          let index = 1;
          index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
          index++
        ) {
          getStudentsArchived(activeCenterId, index, ELEMENT_PER_PAGE).then((paginationResponse) => {
            paginationResponse.datas.forEach((student) => students.push(student));
            setArchivedStudents([...students]);
          });
        }
      } catch (error) {
        dispatch(setErrorNotification(error));
      }
    },
  });
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <main className="archived-student-page">
      <GlobalLayout
        icon="helmet"
        title={STUDENTS_ARCHIVED.pageTraductionKey}
      >
        <BackButton text={getTranslations("back_to_student_page", translations)} />
        <ScrollToTopController />
        <section className="table-container">
          <h1>{getTranslations("archived_students", translations)}</h1>
          <GstaTable
            selectable
            GroupedAction={StudentGroupedActions}
            GroupedActionProps={{ archived: true, removeStudents: removeStudents, setValues: setValues }}
            values={archivedStudents}
            headerDefinitions={headerDefinition}
            totalElementsCount={archivedStudents.length}
            ExpandedComponent={StudentDetail}
            Actions={ArchivedStudentActions}
            ActionsProps={{ deleteArchivesStudent: deleteArchivedStudent, setValues: setValues }}
            SearchInputPlaceHolder={getTranslations("student_find_student_placeholder", translations)}
            noResultPlaceHolder={getTranslations("student_find_student_no_result", translations)}
            numberPerPageText={KeyTrad_NumberPerPage.Student}
            pagination
            globalSearch
          />
        </section>
      </GlobalLayout>
    </main>
  );
};
export default ArchivedStudentStudentPage;
