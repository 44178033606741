//#region react import
import React from "react";
//#endregion

//#region custom import
import AcreosNavigation from "../../Components/AcreosNavigation/AcreosNavigation.component";
import GlobalLayout from "../GlobalLayout/GlobalLayout.component";
//#endregion

//#region function import
import DataMenu from "../../Helper/Navigation/GlobalMenu";
//#endregion

//#region constants import
import { CENTER } from "../../Routing/PageNames";
//#endregion

const CenterGlobalLayout = ({ children }) => {
  const dataHomeMenu = DataMenu();

  return (
    <GlobalLayout
      icon="center"
      title={CENTER.pageTraductionKey}
    >
      <div className="margin-left-right margin-bottom margin-top">
        <AcreosNavigation
          maxNavElement={6}
          navData={dataHomeMenu}
          structureClass={"gsta-sub-navigation"}
          overrideCustomLinkClass={"gsta-sub-navigation-link"}
        />
        <div className="margin-left-right">{children}</div>
      </div>
    </GlobalLayout>
  );
};

export default CenterGlobalLayout;
