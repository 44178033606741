//#region react import
import PropTypes from "prop-types";
//#endregion

//#region style import
import "./IconCheckCross.style.scss";
//#endregion

/**
 *
 * A component to be used in a table to represent a boolean by a green checked if it's true and a red cross if it's false
 */

const IconCheckCross = ({ value, overrideProps }) => {
  //#region useState
  //#endregion
  //#region others use...
  //#endregion
  //#region functions
  const getIcon = () => {
    if (overrideProps.getValue(value) != null) {
      return overrideProps.getValue(value) ? (
        <i className="icon-check color-valid" />
      ) : (
        <i className="icon-cross color-invalid" />
      );
    }
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return <div>{getIcon()}</div>;
};

IconCheckCross.propTypes = {
  value: PropTypes.object.isRequired,
  overrideProps: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }),
};

export default IconCheckCross;
