//#region react import
import { Text, View, StyleSheet } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { getNoteClass } from "./CustomPdf.functions";
import { getNotePdf } from "../../../Helper/NoteConverter";
import { DateTimeToIsoString, formatSecondToStringTime } from "../../../Helper/TimeConverter";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
//#endregion

const styles = StyleSheet.create({
  machineHistory: {
    width: "100%",
    flexDirection: "column",
  },
  mainTitle: {
    width: "100%",
    backgroundColor: COLOR.orange,
    textAlign: "center",
    fontSize: FONT_SIZE.mainTitle,
    paddingVertical: "5px",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: COLOR.grey,
    color: COLOR.textColorInversed,
    paddingVertical: "5px",
  },
  tableLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingVertical: "5px",
  },
  tableCell: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  tableCellText: {
    fontSize: "11px",
    textAlign: "center",
    width: "100%",
  },
  stepNote: {
    fontSize: "11px",
    borderRadius: "5px",
    padding: "5px",
    color: COLOR.textColorInversed,
  },
});

const MachineHistory = ({ translations, trainingHistories, notationType }) => {
  //#region functions
  //#endregion

  //#region constants
  //#endregion
  return (
    <View style={styles.machineHistory}>
      <View
        style={styles.mainTitle}
        wrap={false}
      >
        <Text>{getTranslations("pedagogy_theme_history", translations)}</Text>
      </View>
      <View
        style={styles.tableHeader}
        wrap={false}
      >
        <Text style={styles.tableCellText}>{getTranslations("personnalized_field_date", translations)}</Text>
        <Text style={styles.tableCellText}>{getTranslations("pdf_result_per_machine_training", translations)}</Text>
        <Text style={styles.tableCellText}>
          {getTranslations("personnalized_training_table_name_step", translations)}
        </Text>
        <Text style={styles.tableCellText}>{getTranslations("common_duration", translations)}</Text>
        <Text style={styles.tableCellText}>{getTranslations("student_detail_average", translations)}</Text>
      </View>
      {trainingHistories.map((trainingHistory, index) => (
        <View
          wrap={false}
          style={{ ...styles.tableLine, backgroundColor: index % 2 === 0 ? COLOR.white : COLOR.orangeLight }}
          key={trainingHistory.trainingStepId}
        >
          <View style={styles.tableCell}>
            <Text style={styles.tableCellText}>{DateTimeToIsoString(new Date(trainingHistory.trainingDate))}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.tableCellText}>{getTranslations(trainingHistory.trainingName, translations)}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.tableCellText}>{`${getTranslations(
              "step_progress_step",
              translations
            )} ${index} : ${getTranslations(trainingHistory.trainingStepName, translations)}`}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.tableCellText}>{formatSecondToStringTime(trainingHistory.duration, translations)}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={getNoteClass(styles.stepNote, trainingHistory.averagePercent)}>
              {trainingHistory.averagePercent || trainingHistory.averagePercent !== null
                ? getNotePdf(trainingHistory.averagePercent, notationType)
                : getTranslations("result_detail_in_progress", translations)}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export default MachineHistory;
