import { COLOR } from "./CustomPdf.constants";

export const getNoteClass = (baseClass, averagePercent) => {
 if (!averagePercent && averagePercent !== 0) return { ...baseClass, backgroundColor: COLOR.orange };
 if (averagePercent <= 35) {
  return { ...baseClass, backgroundColor: COLOR.red };
 } else if (averagePercent > 35 && averagePercent <= 70) {
  return { ...baseClass, backgroundColor: COLOR.orange };
 } else {
  return { ...baseClass, backgroundColor: COLOR.green };
 }
};

export const getIconPath = (iconName) => {
 return `${process.env.PUBLIC_URL}/Images/icons/${iconName}.png`
}
