import { setErrorNotification } from "../ReduxStore/notificationSlice";
import { addTraining, setAllTrainingGetted } from "../ReduxStore/trainingSlice";
import { getCenterTraining } from "../Services/TrainingService";
import { GET_TRAINING } from "./ApiConstants";

export const getTrainingLoop = async (centerId, archived, dispatch, setTrainingState) => {
  try {
    const firstPageRequest = await getCenterTraining(centerId, archived, 0, 30);
    let allTrainings = firstPageRequest.datas;
    setTrainingState(allTrainings);
    if (archived !== GET_TRAINING.ARCHIVED) {
      dispatch(addTraining({ paginationResponse: firstPageRequest.datas, page: 0, numberOfElementPerPage: 30 }));
      for (let i = 1; i < Math.ceil(firstPageRequest.totalNumberOfElements / 30); i++) {
        allTrainings = allTrainings.concat((await getCenterTraining(centerId, archived, i, 30)).datas);
        setTrainingState(allTrainings);
      }
      dispatch(setAllTrainingGetted(true));
    }
    return allTrainings;
  } catch (e) {
    if (localStorage.getItem("isLogIn")) dispatch(setErrorNotification(e));
  }
};
