//#region react import
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { formatSecondToStringTime, FORMAT_TIMESTRING } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./TrainingDetailStepItem.style.scss";
//#endregion

const TrainingDetailStepItem = ({ step }) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <div className="cursus-step">
      {step.mandatory && (
        <div className="circle-centered">
          <div>
            <div className="circle circle-mandatory">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
              >
                <text
                  className="step-progress-text"
                  x="50%"
                  y="50%"
                  dy="0.35em"
                >
                  {step && step.trainingStepOrder ? step.trainingStepOrder : "-"}
                </text>
              </svg>
            </div>
          </div>
        </div>
      )}
      {!step.mandatory && (
        <div className="circle-centered">
          <div>
            <div className="circle circle-optionnal">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
              >
                <text
                  className="step-progress-text"
                  x="50%"
                  y="50%"
                  dy="0.35em"
                >
                  {step && step.trainingStepOrder ? step.trainingStepOrder : "-"}
                </text>
              </svg>
            </div>
          </div>
        </div>
      )}
      <div className="step-timeline">
        <div />
        <div />
      </div>
      <div className="step-info">
        <div className="step-info-header">
          <div className="step-info-header-title">
            <h3 className="title3">{step.customName ? step.customName : getTranslations(step.name, translations)}</h3>
            {!step.mandatory && (
              <div className="step-progress-tooltip-optionnal step-info-header-title-optionnal">
                {getTranslations("training_layout_detail_optionnal", translations)}
              </div>
            )}
          </div>
          <div className="step-info-header-extra-title">
            <div className="text-icon">
              <i className="icon-traficlight"></i>
              <span>{step.exerciceCount}</span>
            </div>
            <div className="text-icon">
              <i className="icon-timer"></i>
              <span>{formatSecondToStringTime(step.duration, translations, FORMAT_TIMESTRING.HHMM)}</span>
            </div>
          </div>
        </div>
        <p />
        <span className="step-info-description">
          {step.customDescription ? step.customDescription : getTranslations(step.description, translations)}
        </span>
      </div>
    </div>
  );
};

TrainingDetailStepItem.propTypes = {
  step: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    time: PropTypes.string,
    nbExercices: PropTypes.number,
    mandatory: PropTypes.bool,
  }).isRequired,
};

export default TrainingDetailStepItem;
