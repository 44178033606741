//#region react import
import { useSelector } from "react-redux";
import { useRef } from "react";
//#endregion

//#region component import
import Skills from "../../Pages/Student/Skills/Skills.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import { ConvertNote } from "../../Helper/NoteConverter";
import useIsVisible from "../../Helper/CustomHook/IsVisible";
//#endregion

//#region service import
//#endregion

//#region constants import
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
//#endregion

//#region styles import
import "./MachineResultSummary.style.scss";
//#endregion

/**
 * Component with the timing, image, and skills return
 */
const MachineResultSummary = ({ machineResult, translations }) => {
  //#region useState
  //#endregion
  //#region others use...
  const notationType = useSelector((state) => state.settingSlice.notationType);

  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  //#endregion
  //#region functions
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div
      className="result-per-machine"
      ref={ref}
    >
      {isVisible ? (
        <>
          <div className="result-per-machine-title">
            <h1 className="text-icon">
              <i className="icon-circle_fill" />
              <i className={`icon-${machineResult.machine.code} machine-icon`} />
              {`- ${getTranslations(machineResult.machine.languageKey, translations)}`}
            </h1>
            <div className="result-per-machine-title-spans">
              <div className="title-part">
                <span className="gsta-span">
                  <i className="icon-hourglass" />
                  {formatSecondToStringTime(machineResult.totalSecondElapsed, translations, FORMAT_TIMESTRING.HHMMSS)}
                </span>
                <span className="span-title">
                  {getTranslations("student_detail_training_machine_total_time_elapsed", translations)}
                </span>
              </div>
              <div className="title-part">
                <span className={machineResult.averagePercent >= 50 ? "gsta-span-valid" : "gsta-span-invalid"}>
                  <i className="icon-Gauge" />
                  {ConvertNote(machineResult.averagePercent, notationType)}
                </span>
                <span className="span-title">
                  {getTranslations("student_detail_training_machine_average", translations)}
                </span>
              </div>
              <div className="title-part">
                <span className="gsta-span-disabled">
                  <i className="icon-add_info" />
                  {machineResult.trainingResults.length}
                </span>
                <span className="span-title">
                  {getTranslations("student_detail_training_subscriptions", translations)}
                </span>
              </div>
            </div>
          </div>
          <hr className="student-training--separator full-width" />
          <div className="result-per-machine-skills">
            <Skills
              title={getTranslations("pdf_result_per_machine_job_skills", translations)}
              noSkillPlaceHolder={getTranslations("pdf_result_per_machine_no_skills", translations)}
              skills={machineResult.machineSkills}
            />
          </div>
        </>
      ) : (
        <div className="machine-summary-placholder">
          <div className="placholder-animation" />
        </div>
      )}
    </div>
  );
};

export default MachineResultSummary;
