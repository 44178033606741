//#region react import
import axios from "axios";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//#endregion

//#region component import
import Notifications from "./Components/Notifications/Notifications.component";
import MainRouting from "./Routing/MainRouting";
// import RecetteWatermark from "./Components/RecetteWatermark/RecetteWatermark";
//#endregion

//#region functions import
import { writeApiPathToLocaleStore } from "./Helper/ReadWriteApiPath.js";
//#endregion

//#region
//endregion

//#region import scss
import "acreos-ui/style.css";
import "react-tooltip/dist/react-tooltip.css";
import "./styles/main.scss";
//#endregion

function App() {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();

  axios.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");

  useEffect(() => {
    const functionLoadUrls = async () => {
      let apiServerPro, apiIdentity;
      if (process.env.REACT_APP_LOCAL) {
        const urls = await fetch(`${process.env.PUBLIC_URL}url.json`)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            return data;
          });
        apiServerPro = `${urls.ApiServerPro}v1.0`;
        apiIdentity = urls.ApiIdentity.substring(0, urls.ApiIdentity.length - 1);
      } else {
        apiServerPro = process.env.REACT_APP_API_SERVER_PRO_URL;
        apiIdentity = process.env.REACT_APP_API_IDENTITY_URL;
      }
      writeApiPathToLocaleStore(apiServerPro, apiIdentity);
    };
    functionLoadUrls();
  }, [dispatch]);

  return (
    <main className={navigator.userAgent.toLowerCase().includes("firefox") ? "firefox" : "chrome"}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* <RecetteWatermark /> */}
          <Notifications />
          <div id="modal-root" />
          <MainRouting />
        </BrowserRouter>
      </QueryClientProvider>
    </main>
  );
}

export default App;
