//#region react import
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
//#endregion

//#region constants import
import { gstaLogo } from "../../Constants/Images";
//#endregion

// Create styles
const styles = StyleSheet.create({
  headerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 35,
    paddingTop: 15,
    marginBottom: 20,
  },
  headerThreeColumnLeft: {
    alignItems: "flex-start",
    justifyContent: "center",
    width: "17%",
  },
  headerThreeColumnRight: {
    alignItems: "center",
    justifyContent: "center",
  },
  headerThreeColumnMiddle: {
    display: "flex",
    width: "63%",
    flexDirection: "column",
  },
  headerFlexSameRow: {
    display: "flex",
    justifyContent: "fex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  textBold: {
    fontSize: "12px",
    paddingLeft: "4px",
    fontWeight: "bold",
    textAlign: "left",
    textOverflow: "...",
  },
  headerImageGstaPro: {
    alignItems: "flex-start",
    height: "50px",
  },
});

const GroupedPdfHeader = ({ centerName, groupInfos, translations, groupWithDate, pdfInfos }) => {
  return (
    <View
      style={styles.headerContainer}
      fixed
    >
      <View style={styles.headerThreeColumnLeft}>
        <Image
          style={styles.headerImageGstaPro}
          src={gstaLogo.img}
        />
      </View>
      <View style={styles.headerThreeColumnMiddle}>
        <View style={styles.headerFlexSameRow}>
          <Text>{`${getTranslations("grouped_pdf_group_name", translations)}`}</Text>
          <Text style={styles.textBold}>{groupInfos.groupName}</Text>
        </View>
        <View style={styles.headerFlexSameRow}>
          <Text>{getTranslations("grouped_pdf_period", translations)}</Text>
          {groupWithDate ? (
            <Text style={styles.textBold}>{`${DateTimeToIsoString(groupInfos.startGroup)} ${getTranslations(
              "session_date_to",
              translations
            )} ${DateTimeToIsoString(groupInfos.endGroup)}`}</Text>
          ) : (
            <Text style={styles.textBold}>{getTranslations("since_student_creation", translations)}</Text>
          )}
        </View>
        <View style={styles.headerFlexSameRow}>
          <Text>{`${getTranslations("pdf_header_center", translations)}`}</Text>
          <Text style={styles.textBold}>{centerName}</Text>
        </View>
      </View>
      <Image
        style={{ height: "50px", width: "auto", uri: `data:image/png;base64,${pdfInfos?.pdfCustomLogo.image}` }}
        src={`data:image/png;base64,${pdfInfos?.pdfCustomLogo.image}`}
      />
    </View>
  );
};

export default GroupedPdfHeader;
