//#region react import
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { CURRENT_VALUE, SIMULATOR, STUDENT, TIME_SIMULATION_PER_DAY } from "../../../Constants/DataConstants";
import { FORMAT_DAY, FORMAT_DAY_ZULU } from "../../../Constants/DateConstants";
import { NB_DAYS, NB_TILES, NB_WEEKS } from "./GstaCalendar.constants";
//#endregion

//#region hooks
import "./GstaCalendar.style.scss";
//#endregion

const GstaCalendar = ({ year, month, activities, simulatorOrStudent, overrideLegend }) => {
  //#region style hooks
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const getDaysInMonth = (selectedMonth, selectedYear) => {
    const date = new Date(selectedYear, selectedMonth - 1, 1);
    let daysInSelectedMonth = [];
    while (date.getMonth() === selectedMonth - 1) {
      daysInSelectedMonth.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return daysInSelectedMonth;
  };

  const arrayDayTiles = (days, startDayOfMonth) => {
    let dayTiles = [];

    for (let i = 0; i < NB_TILES; i++) {
      if (i >= startDayOfMonth && i < days.length + startDayOfMonth) {
        dayTiles[i] = days[i - startDayOfMonth];
      } else {
        dayTiles[i] = null;
      }
    }

    return dayTiles;
  };

  const createCalendar = (dayTiles) => {
    let week = [];
    for (let indexWeek = 0; indexWeek < NB_WEEKS; indexWeek++) {
      let days = [];
      for (let indexDay = 0; indexDay < NB_DAYS; indexDay++) {
        let day = dayTiles[indexDay + NB_DAYS * indexWeek];
        let className = "calendar-day";
        let dayContent = [];
        if (day) {
          const formatDay = moment(day).format(FORMAT_DAY);
          const formatDayZulu = moment(day).format(FORMAT_DAY_ZULU);
          let time = getTranslations("common_default_time", translations);
          if (activities && simulatorOrStudent === SIMULATOR) {
            const jsonActivities = activities[SIMULATOR][TIME_SIMULATION_PER_DAY];
            if (jsonActivities[formatDay]) {
              time = formatSecondToStringTime(
                jsonActivities[formatDay][CURRENT_VALUE],
                translations,
                FORMAT_TIMESTRING.HHMM
              );
            }
          } else if (activities && simulatorOrStudent === STUDENT) {
            for (let index = 0; index < activities.length; index++) {
              if (activities[index].activityDate === formatDayZulu || activities[index].activityDate === formatDay) {
                time = formatSecondToStringTime(activities[index].duration, translations, FORMAT_TIMESTRING.HHMM);
              }
            }
          }
          if (time !== "0s" && time !== getTranslations("common_default_time", translations)) {
            className += " highlight";
          }
          dayContent.push(
            <div
              key={`number-${indexDay}`}
              className="number"
            >
              {day.getDate()}
            </div>
          );
          dayContent.push(
            <div
              key={`activity-${indexDay}`}
              className="activity"
            >
              {time}
            </div>
          );
        } else {
          className = "calendar-day-none";
        }

        days.push(
          <div
            key={`day-${indexDay}`}
            className={className}
          >
            {dayContent}
          </div>
        );
      }
      week.push(
        <div
          key={`week-${indexWeek}`}
          className="calendar-week-number"
        >
          {days}
        </div>
      );
    }

    return week;
  };

  const getCalendarLegendClass = () => {
    return simulatorOrStudent === SIMULATOR ? "calendar-legend" : "calendar-legend-student";
  };
  //#endregion

  //#region constants
  const _days = getDaysInMonth(month, year);
  const _startDayOfMonth = moment(_days[0])._d.getDay() === 0 ? 6 : moment(_days[0])._d.getDay() - 1;
  const _dayTiles = arrayDayTiles(_days, _startDayOfMonth);
  const _week = createCalendar(_dayTiles);
  //#endregion

  return (
    <div className={simulatorOrStudent === SIMULATOR ? "calendar" : "calendar-student"}>
      <div>
        <div className="calendar-header">
          <span>{getTranslations("calendar_monday", translations)}</span>
          <span>{getTranslations("calendar_tuesday", translations)}</span>
          <span>{getTranslations("calendar_wednesday", translations)}</span>
          <span>{getTranslations("calendar_thursday", translations)}</span>
          <span>{getTranslations("calendar_friday", translations)}</span>
          <span>{getTranslations("calendar_saturday", translations)}</span>
          <span>{getTranslations("calendar_sunday", translations)}</span>
        </div>
        <div>{_week}</div>
      </div>
      {overrideLegend ? (
        <br />
      ) : (
        <div className={getCalendarLegendClass()}>
          <div className="calendar-legend-label">{getTranslations("calendar_legend", translations)}</div>
          <div className="legend-detail">
            <div className="calendar-day highlight legend-icon"></div>
            <span>{getTranslations("calendar_simulator_connected", translations)}</span>
          </div>
          <div className="legend-detail">
            <div className="calendar-day legend-icon"></div>
            <span>{getTranslations("calendar_simulator_unconnected", translations)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

GstaCalendar.prototypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  activities: PropTypes.object,
  simulatorOrStudent: PropTypes.string,
};

export default GstaCalendar;
