//#region react import
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region custom import
import Patty from "../Patty/Patty.component";
import Footer from "../Footer/Footer.component";
//#endregion

//#region function import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import { setConnected, setTrainer } from "../../../ReduxStore/connexionSlice";
import { getTranslations } from "../../../Helper/TranslationController";
import { authenticate } from "../../../Services/AuthenticateService";
import { getTrainerCenterDto } from "../../../Services/TrainerService";
import { logIn, logOut } from "../../../Helper/logInLogOut";
//#endregion

//#region constants import
import { INPUT_INPUT, INPUT_PASSWORD, INPUT_TEXT } from "../../../Components/GstaInput/GstaInput.constants";
import { REQUIRED_FIELD, WRONG_PASSWORD_OR_LOGIN } from "../../../Constants/ErrorMessage";
import { SetDataTestId } from "../../../Helper/DataTestId";
//#endregion

//#region style import
import "./SideFormGstaConnexion.style.scss";
//#endregion

const SideFormGstaConnexion = () => {
  //#region useState
  const [loginError, setLoginError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordType, setShowPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [starting] = useState(true);

  //#region others uses
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);
  const connected = useSelector((state) => state.connexionSlice.connected);
  const token = useSelector((state) => state.connexionSlice.token);
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  //#endregion

  //#region functions
  const TestLogin = (login) => {
    const loginCorrectlySet = login.length !== 0;
    setLoginError(loginCorrectlySet ? " " : getTranslations(REQUIRED_FIELD, translations));
    return loginCorrectlySet;
  };

  const TestPassword = (password) => {
    const passwordCorrectlySet = password.length !== 0;
    setPasswordError(passwordCorrectlySet ? " " : getTranslations(REQUIRED_FIELD, translations));
    return passwordCorrectlySet;
  };

  const getType = (type) => {
    if (type !== INPUT_PASSWORD) {
      return type;
    } else {
      return showPasswordType;
    }
  };

  const handleShowPassword = (disabled) => {
    if (!disabled) {
      setShowPasswordType(showPasswordType === INPUT_PASSWORD ? INPUT_INPUT : INPUT_PASSWORD);
    }
  };

  const renderinput = (id, disabled, valid, type, label, errorMessage, dataTestId, onBlur) => {
    return (
      <div className={"gsta-text-field field "}>
        <input
          id={id}
          name={id}
          disabled={disabled}
          type={getType(type)}
          data-testid={SetDataTestId(dataTestId)}
          placeholder={" "}
          onBlur={onBlur}
          valid={valid.toString()}
        />
        {type === INPUT_PASSWORD && (
          <i
            className={
              (getType(type) === INPUT_PASSWORD ? "icon-eye" : "icon-blind") +
              " show-password-icon" +
              (disabled ? " disabled" : "")
            }
            onClick={() => handleShowPassword(disabled)}
          />
        )}
        <label
          htmlFor={id}
          className="form__label"
        >
          {label}
        </label>
        <div className="error-message">{errorMessage}</div>
      </div>
    );
  };

  const HandleConnexion = async (formData) => {
    let loginCorrectlySet = true;
    loginCorrectlySet = TestLogin(formData.uname);
    loginCorrectlySet = TestPassword(formData.psw) && loginCorrectlySet;
    if (loginCorrectlySet) {
      setLoading(true);
      try {
        const apiResponse = await authenticate(formData.uname, formData.psw);
        if (apiResponse.powerlevel !== "TRAINER") {
          throw new Error(getTranslations("error_user_power_level", translations));
        }
        localStorage.setItem("token", apiResponse.token);
        dispatch(
          setConnected({
            connected: true,
            apiUserId: apiResponse.id,
            token: apiResponse.token,
            tokenValidityDate: apiResponse.validityDate,
            login: apiResponse.login,
            hashedSecret: formData.psw,
          })
        );
        const trainer = await getTrainerCenterDto(apiResponse.id);

        dispatch(setTrainer({ trainer }));
        return;
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          setLoginError(getTranslations(WRONG_PASSWORD_OR_LOGIN, translations));
          setPasswordError(getTranslations(WRONG_PASSWORD_OR_LOGIN, translations));
        } else {
          dispatch(setErrorNotification(error));
        }
        setLoading(false);
      }
    }
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (connected && trainer && token) logIn(dispatch, navigate, location.pathname);
  }, [connected, dispatch, location.pathname, navigate, trainer, token]);

  useEffect(() => {
    logOut(dispatch, navigate);
    localStorage.removeItem("token");
  }, [dispatch, navigate]);
  //#endregion

  return (
    <section className={"side-connexion-main-container"}>
      <i
        className="icon-Logo_GSTA login-logo"
        visible={starting.toString()}
      />
      <h1 className="connexion-title">{getTranslations("gsta_connexion_welcome", translations)}</h1>
      <h2 className="connexion-sub-title">{getTranslations("gsta_connexion_connect", translations)}</h2>
      <form
        name="connexion_form"
        autoComplete="on"
        action="/"
        onSubmit={async (e) => {
          e.preventDefault();
          const data = new FormData(e.target);
          const obj = {};
          data.forEach((value, key) => (obj[key] = value));
          await HandleConnexion(obj);
        }}
      >
        {renderinput(
          "uname",
          loading,
          loginError.trim().length > 0,
          INPUT_TEXT,
          getTranslations("gsta_connexion_login", translations),
          loginError,
          "login-input",
          (event) => TestLogin(event.target.value)
        )}

        {renderinput(
          "psw",
          loading,
          passwordError.trim().length > 0,
          INPUT_PASSWORD,
          getTranslations("gsta_connexion_password", translations),
          passwordError,
          "password-input",
          (event) => TestPassword(event.target.value)
        )}

        <input
          name="login-button"
          className={"gsta-button connexion-button"}
          disabled={loading}
          type="submit"
          value={getTranslations("gsta_connexion_connect_button", translations)}
        />
      </form>
      <Patty />
      <Footer />
    </section>
  );
};

SideFormGstaConnexion.propTypes = {};

export default SideFormGstaConnexion;
