import axios from "axios";
import { URI } from "../Constants/ApiConstants";
import { SIMULATOR_PIN_CODE } from "../Constants/DataConstants";
import { MEASURING_SYSTEM_CODE, SHOW_NOTATION_CODE, TYPE_NOTATION_CODE } from "../Constants/SettingsConstants";
import {
  responseCustomPdfTemplate,
  responseMock2,
  responseMock3,
  responseMockNote,
  responseMockSimulatorPinCode,
} from "./MockService/SettingsServiceMock";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";

export const getCenterSettings = async (centerId, settingCode) => {
  if (process.env.NODE_ENV === "test") {
    if (settingCode === SHOW_NOTATION_CODE) {
      return responseMockNote;
    } else if (settingCode === TYPE_NOTATION_CODE) {
      return responseMock2;
    } else if (settingCode === MEASURING_SYSTEM_CODE) {
      return responseMock3;
    } else if (settingCode === SIMULATOR_PIN_CODE) {
      return responseMockSimulatorPinCode;
    } else {
      return;
    }
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${settingCode}`
  );
  return data;
};

export const setCenterSettings = async (centerId, value, settingCode) => {
  if (process.env.NODE_ENV === "test") {
    if (settingCode === SHOW_NOTATION_CODE) {
      return { dataModified: "false" };
    } else if (settingCode === TYPE_NOTATION_CODE) {
      return { dataModified: 2 };
    } else if (settingCode === MEASURING_SYSTEM_CODE) {
      return { dataModified: 2 };
    } else if (settingCode === SIMULATOR_PIN_CODE) {
      return { dataModified: "0000" };
    } else {
      return;
    }
  }

  const { data } = await axios.put(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${settingCode}`,
    {
      value: value,
    }
  );
  return data;
};

export const getUserSettings = async (centerId, userId, settingCode) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Users}/${userId}/${URI.Entity.Settings
    }/${settingCode}`
  );
  return data;
};

export const setUserSettings = async (value, centerId, userId, settingCode) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  const { data } = await axios.put(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Users}/${userId}/${URI.Entity.Settings
    }/${settingCode}`,
    {
      value: value,
    }
  );
  return data;
};

export const getAvailableLanguage = async () => {
  return await fetch(`${process.env.PUBLIC_URL}/GSTA/availableLanguage.json`)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
};

export const getTemplatePdf = async (centerId) => {
  if (process.env.NODE_ENV === "test") {
    return [responseCustomPdfTemplate];
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${URI.Property.template}`
  );
  return data;
};

export const putTemplatePdf = async (centerId, templateResult) => {
  if (process.env.NODE_ENV === "test") {
    return responseCustomPdfTemplate;
  }
  const { data } = await axios.put(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${URI.Property.template}`,
    templateResult
  );
  return data;
};

export const postTemplatePdf = async (centerId, templateResult) => {
  if (process.env.NODE_ENV === "test") {
    return responseCustomPdfTemplate;
  }
  const { data } = await axios.post(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${URI.Property.template}`,
    templateResult
  );
  return data;
};

export const deleteTemplatePdf = async (centerId, templateId) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  await axios.delete(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.Settings}/${URI.Property.template
    }/${templateId}`
  );
};

export const getImage = async () => {
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { data } = await axios.get(`${getApiServerProPath()}/images`);
  return data;
};

export const GetPdfInfos = async (centerId) => {
  if (process.env.NODE_env === "test") {
    return { image: "", pdfFooter: "MyCustomPdfFooter\nMycustomPdfFooter" };
  }
  const { data } = await axios.get(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.pdfCustomInfos}`
  );
  return data;
};

export const setCustomLogo = async (centerId, pdfCustomLogo) => {
  if (process.env.NODE_env === "test") {
    return { image: "", pdfFooter: "MyCustomPdfFooter\nMycustomPdfFooter" };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.pdfCustomLogo}`,
    {
      dataToModify: pdfCustomLogo,
    }
  );
  return data;
};

export const setCustomPdfFooter = async (centerId, pdfCustomFooter) => {
  if (process.env.NODE_env === "test") {
    return { dataModified: "MyCustomPdfFooter\nMycustomPdfFooter" };
  }
  const { data } = await axios.patch(
    `${getApiServerProPath()}/${URI.Entity.Centers}/${centerId}/${URI.Entity.pdfCustomFooter}`,
    {
      dataToModify: pdfCustomFooter,
    }
  );
  return data;
};
