//#region react import
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region store import
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region fonctions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { ONE_MO_FILE_IN_BYTE } from "./ImageSelector.constant";
//#endregion

//#region style import
import "./ImageSelector.style.scss";
//#endregion

/**
 * A component that allow the user to create
 */
const ImageSelector = ({ image, setImage }) => {
  //#region useState
  const [imageStyle, setImageStyle] = useState({ height: "150px", width: "470px" });
  //#endregion

  //#region others use...
  const componentRef = useRef();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const onChangeImage = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    event.target.value = null;
    if (!file || file.size > ONE_MO_FILE_IN_BYTE) {
      dispatch(setErrorNotification({ message: getTranslations("file_too_big", translations) }));
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = (onLoadEvent) => {
      setImage(onLoadEvent.target.result);
    };
  };

  const calculateImageSize = (imageSize) => {
    if (!image || !componentRef?.current || !imageSize) {
      setImageStyle({ height: "150px", width: "470px" });
      return;
    }
    const ratio = imageSize.width / imageSize.height;
    const imageHeight = componentRef?.current?.parentElement.offsetWidth / 6;
    const imageWidth = ratio * imageHeight;
    if (imageWidth > componentRef?.current?.parentElement.offsetWidth) {
      return { height: "auto", width: componentRef.current.parentElement.offsetWidth };
    }
    setImageStyle({ height: imageHeight, width: imageWidth });
  };

  const handleImageLoad = (event) => {
    calculateImageSize({ width: event.target.naturalWidth, height: event.target.naturalHeight });
  };

  const handleDeleteImage = async () => {
    await setImage();
    calculateImageSize();
  };

  const renderImage = () => {
    if (image) {
      return (
        <img
          onClick={openFileSelector}
          src={image}
          alt="custom-logo"
          style={imageStyle}
          onLoad={handleImageLoad}
        />
      );
    } else {
      return (
        <div
          className="placeholder"
          style={imageStyle}
          onClick={openFileSelector}
        >
          <span>+</span>
        </div>
      );
    }
  };

  const openFileSelector = () => {
    inputRef.current.click();
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <main
      className="image-selector"
      ref={componentRef}
    >
      <h1>{getTranslations("import_logo", translations)}</h1>
      <h2>{getTranslations("image_logo_size", translations)}</h2>
      <input
        ref={inputRef}
        onChange={onChangeImage}
        type="file"
        accept="image/png, image/jpeg"
      />
      <section>
        {renderImage()}
        {image && (
          <div>
            <button
              className={`gsta-button-outlined`}
              onClick={handleDeleteImage}
              disabled={!image}
            >
              <span>{getTranslations("delete", translations)}</span>
              <i className="icon-poubelle" />
            </button>
            <button
              className="gsta-button-fill"
              onClick={openFileSelector}
            >
              <span>
                {image ? getTranslations("replace", translations) : getTranslations("import_logo", translations)}
              </span>
              <i className="icon-update" />
            </button>
          </div>
        )}
        {!image && (
          <div>
            <button
              className="gsta-button-fill"
              onClick={openFileSelector}
            >
              <span>{`${getTranslations("import_logo", translations)}`}</span>
              <i className="icon-fail rotate-45" />
            </button>
          </div>
        )}
      </section>
    </main>
  );
};
export default ImageSelector;
