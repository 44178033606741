//#region react import
import { Document, Page, View, StyleSheet, Font, Text } from "@react-pdf/renderer";
//#endregion

//#region custom import
import PdfDriverProfile from "./PdfDriverProfile";
import PdfFooter from "./PdfFooter";
import PdfHeader from "./PdfHeader";
import PdfResultByMachine from "./PdfResultByMachine";
import Roboto from "./Roboto-Medium.ttf";
import RobotoBold from "./Roboto-Bold.ttf";
import RobotoItalic from "./Roboto-Italic.ttf";
import OpenDyslexicRegular from "./OpenDyslexic-Regular.ttf";
import { Fragment } from "react";
//#endregion

//#region constants import
//#endregion

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
    {
      src: RobotoItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
Font.register({
  family: "OpenDyslexic",
  fonts: [
    {
      src: OpenDyslexicRegular,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    paddingTop: 80,
    paddingBottom: 80,
    fontSize: 12,
  },
  content: {
    paddingHorizontal: 35,
  },
});

// Create Document Component
const BasicDocument = ({
  notationType,
  pdfData,
  translations,
  accessStart,
  accessEnd,
  manageWithDates,
  customFields,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.body}
        wrap={true}
      >
        <PdfHeader
          firstName={pdfData.firstName}
          lastName={pdfData.lastName}
          center={pdfData.center}
          translations={translations}
          accessStart={accessStart}
          accessEnd={accessEnd}
          manageWithDates={manageWithDates}
        />
        <PdfFooter translations={translations} />
        <View style={styles.content}>
          <PdfDriverProfile
            pdfData={pdfData}
            translations={translations}
            accessStart={accessStart}
            accessEnd={accessEnd}
            manageWithDates={manageWithDates}
            customFields={customFields}
          />
        </View>
        {pdfData.machineResults.map((machineResult, index) => {
          if (machineResult.trainingResults.find((trainingResult) => trainingResult.currentStepOrder > 0)) {
            return (
              <Fragment key={`resultByMachine-${index}`}>
                {index !== 0 && <Text key={`break-${index}`} />}
                <View style={styles.content}>
                  <PdfResultByMachine
                    notationType={notationType}
                    machineResult={machineResult}
                    translations={translations}
                  />
                </View>
              </Fragment>
            );
          } else {
            return <Fragment key={`resultByMachine-${index}`} />;
          }
        })}
      </Page>
    </Document>
  );
};

export default BasicDocument;
