//#region react import
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
//#endregion

//#region components import
import CenterParameters from "../../Pages/Center/CenterParameters/CenterParameters.component";
import GroupedButton from "../../Components/GroupedButton/GroupedButton.component";
import UndisplayFeature from "../../Components/UndisplayFeature/UndisplayFeature.component";
//#endregion

//#region services import
import { getCenterSettings, setCenterSettings } from "../../Services/SettingsService";
import { setNotationType } from "../../ReduxStore/settingSlice";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import {
  TYPE_NOTATION_CODE,
  MEASURING_SYSTEM_CODE,
  SHOW_NOTATION_CODE,
  EDIT_STUDENT_CONFIG_COMMAND,
  MODIFY_COMMAND,
  MODIFY_EXAMINE_MODULES,
  EXAMINE_MODULES_EXTENDED,
  TOKEN_VALIDITY_TIME,
  TOKEN_VALIDITY_TIME_VALUES,
} from "../../Constants/SettingsConstants";
//#endregion

//#region style import
import "./CenterParametersLayout.style.scss";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import PdfSettingSection from "../../Components/PdfSettingSection/PdfSettingSection.component";
//#endregion

/**
 * Layout of the center parameters page
 */

const CenterParametersLayout = ({ title }) => {
  //#region router
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translationDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);
  const [simulatorNotation, setSimulatorNotation] = useState(false);
  const [simulatorNotationType, setSimulatorNotationType] = useState("");
  const [simulatorMeasuringSystem, setSimulatorMeasuringSystem] = useState("");
  const [configCommand, setConfigCommand] = useState("");
  const [examineModules, setExamineModules] = useState("");
  const [tokenValidityTime, setTokenValidityTime] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleChangeShowSimulatorNotationValue = (newValue) => {
    setSimulatorNotation(newValue === "true");
  };

  const handleChangeShowSimulatorNotation = () => {
    handleChangeSetting(SHOW_NOTATION_CODE, !simulatorNotation, handleChangeShowSimulatorNotationValue);
  };

  const handleChangeSimulatorNotationType = (notationType) => {
    handleChangeSetting(TYPE_NOTATION_CODE, notationType, setSimulatorNotationType, setNotationType);
  };

  const handleChangeSimulatorMeasuringSystem = (measuringSystem) => {
    handleChangeSetting(MEASURING_SYSTEM_CODE, measuringSystem, setSimulatorMeasuringSystem);
  };

  const handleChangeConfigCommand = (configCommand) => {
    handleChangeSetting(EDIT_STUDENT_CONFIG_COMMAND, configCommand, setConfigCommand);
  };

  const handleChangeTokenValidityTime = (tokenValidityTimeNewValue) => {
    handleChangeSetting(TOKEN_VALIDITY_TIME, tokenValidityTimeNewValue, setTokenValidityTime);
  };

  const handleChangeSetting = async (settingCode, settingValue, setSettingValue, dispatchSetting) => {
    try {
      const newValue = (await setCenterSettings(centerId, settingValue, settingCode)).dataModified;
      setSettingValue(newValue);
      dispatchSetting && dispatch(dispatchSetting(newValue));
      dispatch(setSuccessNotification(getTranslations("notification_parameter_updated", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleChangeExamineModulesAuthorization = (newValue) => {
    handleChangeSetting(EXAMINE_MODULES_EXTENDED, newValue, setExamineModules);
  };

  const checkInstalledMachines = () => {
    return !translationDictionariesToLoad.some((machine) => machine.acronym === "SYL" || machine.acronym === "LOG");
  };

  //#endregion

  //#region useEffect
  useEffect(() => {
    const getSettings = async () => {
      try {
        const settingsShowNote = await getCenterSettings(centerId, SHOW_NOTATION_CODE);
        const settingsNotationType = await getCenterSettings(centerId, TYPE_NOTATION_CODE);
        const settingsMeasuringSystem = await getCenterSettings(centerId, MEASURING_SYSTEM_CODE);
        const settingsExaminesModules = await getCenterSettings(centerId, EXAMINE_MODULES_EXTENDED);
        const settingsConfigCommand = await getCenterSettings(centerId, EDIT_STUDENT_CONFIG_COMMAND);
        const tokenValidity = await getCenterSettings(centerId, TOKEN_VALIDITY_TIME);
        setSimulatorNotation(settingsShowNote.value === "true");
        setSimulatorNotationType(settingsNotationType.value);
        setSimulatorMeasuringSystem(settingsMeasuringSystem.value);
        setExamineModules(settingsExaminesModules.value);
        setConfigCommand(settingsConfigCommand.value);
        setTokenValidityTime(tokenValidity.value);
        dispatch(setNotationType(settingsNotationType.value));
        setLoading(false);
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    };
    getSettings();
  }, [centerId, dispatch]);
  //#endregion

  return (
    <div>
      <ScrollToTopController />
      {loading && <GstaLoaderPage />}
      <h2 className="center-parameters-title page-title">{title}</h2>
      <div className="center-parameters-content">
        <h1>{getTranslations("center_notations", translations)}</h1>
        <hr className="hr-without-margin-top" />
        <div className="notation-grid">
          <CenterParameters
            handleChangeShowSimulatorNotation={handleChangeShowSimulatorNotation}
            handleChangeSimulatorMeasuringSystem={handleChangeSimulatorMeasuringSystem}
            handleChangeSimulatorNotationType={handleChangeSimulatorNotationType}
            simulatorNotation={simulatorNotation}
            simulatorNotationType={simulatorNotationType}
            simulatorMeasuringSystem={simulatorMeasuringSystem}
          />
        </div>
      </div>
      <PdfSettingSection />
      <div className="center-parameters-content">
        <h1>{getTranslations("center_settings_modules_authorization", translations)}</h1>
        <hr className="hr-without-margin-top" />
        <div className="modules-authorization_content">
          <div>
            <GroupedButton
              label={getTranslations("center_settings_examine_modules_authorization", translations)}
              arrayToMap={MODIFY_EXAMINE_MODULES}
              onClick={handleChangeExamineModulesAuthorization}
              selected={examineModules}
              layoutClass={"radio-buttons"}
            />
          </div>
          <UndisplayFeature isLocal={checkInstalledMachines()}>
            <GroupedButton
              label={getTranslations("student_command_config_modification", translations)}
              arrayToMap={MODIFY_COMMAND}
              onClick={handleChangeConfigCommand}
              selected={configCommand}
              layoutClass={"radio-buttons"}
            />
          </UndisplayFeature>
          <GroupedButton
            label={getTranslations("token_validity_time", translations)}
            arrayToMap={TOKEN_VALIDITY_TIME_VALUES}
            onClick={handleChangeTokenValidityTime}
            selected={tokenValidityTime}
            layoutClass={"radio-buttons"}
          />
        </div>
      </div>
    </div>
  );
};

CenterParametersLayout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CenterParametersLayout;
