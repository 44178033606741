export const SHOW_NOTATION_CODE = "SHOW_NOTATION";
export const TYPE_NOTATION_CODE = "TYPE_NOTATION";
export const MEASURING_SYSTEM_CODE = "MEASURING_SYSTEM";
export const LANGUAGE = "LANGUAGE";
export const MANAGE_STUDENTS_ACCESS_WITH_DATES = "MANAGE_STUDENTS_ACCESS_WITH_DATES";
export const EXAMINE_MODULES = "EXAMINE_MODULES";
export const EXAMINE_MODULES_EXTENDED = "EXAMINE_MODULES_EXTENDED";
export const SHOW_STUDENTS_MACHINES = "SHOW_STUDENTS_MACHINES";
export const SHOW_STUDENTS_LAST_ACTIVITY = "SHOW_STUDENTS_LAST_ACTIVITY";
export const EDIT_STUDENT_CONFIG_COMMAND = "EDIT_STUDENT_CONFIG_COMMAND";
export const TOKEN_VALIDITY_TIME = "TOKEN_VALIDITY_TIME";

//#region notationType value
export const PERCENTAGE = {
  label: "100%",
  value: "1",
};
export const TEN = {
  label: "10/10",
  value: "2",
};
export const TWENTY = {
  label: "20/20",
  value: "3",
};
export const TYPE_NOTATION = [PERCENTAGE, TEN, TWENTY];
//#endregion

export const MEASURING_SYSTEM = [
  { label: "center_notation_internationnal", value: "1" },
  { label: "center_notation_imperial", value: "2" },
];

export const MODIFY_COMMAND = [
  { label: "common_no", value: "1" },
  { label: "change_configuration_already_exist", value: "2" },
  { label: "change_configuration_create", value: "3" },
];

export const TOKEN_VALIDITY_TIME_VALUES = [
  { label: "60_m", value: "60" },
  { label: "120_m", value: "120" },
  { label: "180_m", value: "180" },
  { label: "240_m", value: "240" },
];

export const MODIFY_EXAMINE_MODULES = [
  { label: "common_no", value: "1" },
  { label: "examine_modules_access_training_page", value: "2" },
  { label: "examine_modules_access_training_connexion_page", value: "3" },
];
