//#region react import
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region component import
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
//#endregion

//#region function import
import { getTranslations, reloadEnginesTranslations, reloadGSTATranslations } from "../../Helper/TranslationController";
import { buildJsonToConvert } from "./LoadingPage.constants";
//#endregion

//#region store import
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import { setSetting } from "../../ReduxStore/settingSlice";
import { reset, setLanguage } from "../../ReduxStore/translationSlice";
//#endregion

//#region service import
import { getVersions } from "../../Services/PatchNotesService";
import { getCenterSettings, getUserSettings } from "../../Services/SettingsService";
//#endregion

//#region constants import
import {
  LANGUAGE,
  MANAGE_STUDENTS_ACCESS_WITH_DATES,
  SHOW_STUDENTS_LAST_ACTIVITY,
  SHOW_STUDENTS_MACHINES,
} from "../../Constants/SettingsConstants";
import { CURRENT_ACTIVITY, LOGIN, SELECT_ACTIVE_CENTER } from "../../Routing/PageNames";
//#endregion

const LoadingPage = () => {
  //#region state
  const [isUserSettingsQuerySucessfull, setIsUserSettingsQuerySucessfull] = useState(false);
  //#endregion

  //#region others use...
  const navigate = useNavigate();
  const connexionSlice = useSelector((state) => state.connexionSlice);
  const language = useSelector((state) => state.translationSlice.language);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region constants
  const centerId = connexionSlice.trainer.activeCenter?.id;
  const userId = connexionSlice.trainer?.id;
  //#endregion

  //#region functions
  const onGetUserSettingsSuccess = async (userSettings) => {
    dispatch(setLanguage(userSettings.value));
    await reloadEnginesTranslations(dispatch, centerId, userSettings);
    await reloadGSTATranslations(dispatch, userSettings);
  };

  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["userSettings"],
    queryFn: async () => {
      const languageSetting = await getUserSettings(centerId, userId, LANGUAGE);
      onGetUserSettingsSuccess(languageSetting);
      setIsUserSettingsQuerySucessfull(true);
    },
  });

  const { data: patchNotes, isSuccess: patchNotesLoaded } = useQuery({
    queryKey: ["patchNotes"],
    enabled: isUserSettingsQuerySucessfull,
    queryFn: async () => {
      const versions = await getVersions();
      const patchNotes = await buildJsonToConvert(versions, language);
      return patchNotes;
    },
  });

  const { isSuccess: isShowStudentMachinesQuerySuccessfull } = useQuery({
    queryKey: ["showStudentMachines"],
    queryFn: async () => {
      try {
        const showStudentMachines = await getCenterSettings(centerId, SHOW_STUDENTS_MACHINES);
        dispatch(setSetting({ settingName: SHOW_STUDENTS_MACHINES, settingValue: showStudentMachines.value }));
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
  });

  const { isSuccess: isManageStudentsWithAccessDateQuerySucessfull } = useQuery({
    queryKey: ["manageStudentsWithAccessDate"],
    queryFn: async () => {
      try {
        const manageStudentsWithAccessDate = await getCenterSettings(centerId, MANAGE_STUDENTS_ACCESS_WITH_DATES);
        dispatch(
          setSetting({
            settingName: MANAGE_STUDENTS_ACCESS_WITH_DATES,
            settingValue: manageStudentsWithAccessDate.value,
          })
        );
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
  });

  const { isSuccess: isShowStudentLastActivityQuerySuccessfull } = useQuery({
    queryKey: ["showStudentLastActivity"],
    queryFn: async () => {
      try {
        const showStudentLastActivity = await getCenterSettings(centerId, SHOW_STUDENTS_LAST_ACTIVITY);
        dispatch(setSetting({ settingName: SHOW_STUDENTS_LAST_ACTIVITY, settingValue: showStudentLastActivity.value }));
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (
      isUserSettingsQuerySucessfull &&
      isShowStudentMachinesQuerySuccessfull &&
      isShowStudentLastActivityQuerySuccessfull &&
      isManageStudentsWithAccessDateQuerySucessfull &&
      patchNotesLoaded &&
      getTranslations("120_m", translations) !== "120_m"
    ) {
      if (location.state === LOGIN.url) {
        navigate(CURRENT_ACTIVITY.url, { state: { patchNotes: patchNotes } });
      } else {
        navigate(SELECT_ACTIVE_CENTER.url);
      }
    }
  }, [
    navigate,
    isManageStudentsWithAccessDateQuerySucessfull,
    isShowStudentMachinesQuerySuccessfull,
    isUserSettingsQuerySucessfull,
    isShowStudentLastActivityQuerySuccessfull,
    patchNotesLoaded,
    patchNotes,
    translations,
    location.state,
  ]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  //#endregion

  return (
    <div>
      <GstaLoaderPage />
    </div>
  );
};

export default LoadingPage;
