//#region react import
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosTabs from "../AcreosTabs/AcreosTabs.component";
import ExportPdfModalList from "../ExportPdfModalList/ExportPdfModalList.component";
import ExportPdfParameters from "../ExportPdfParameters/ExportPdfParameters.component";
import GroupedButton from "../GroupedButton/GroupedButton.component";
import GstaInput from "../GstaInput/GstaInput.component";
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region service import
import { getStudentResultsByTemplate } from "../../Services/StudentService";
//#endregion

//#region functions import
import { PrepareCustomPdf, PrepareGroupedPdf } from "../../Helper/ExportPdf";
import { getTranslations } from "../../Helper/TranslationController";
import { getEndPeriod, getStartPeriod } from "../../Pages/Student/StudentPage/StudentPage.constants";
//#endregion

//#region constants import
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import { EXPORT_TYPE } from "./ExportPdfModal.constants";
//#endregion

//#region style import
import "./ExportPdfModal.style.scss";
//#endregion

const ExportPdfModal = ({
  studentsSelected,
  modalOpen,
  setModalOpen,
  defaultTabSelected,
  pdfTemplates,
  setPdfTemplates,
  selectedPeriod,
  setSelectedPeriod,
  selectedPdfTemplate,
  setSelectedPdfTemplate,
  personalizedStartDate,
  setPersonalizedStartDate,
  personalizedEndDate,
  setPersonalizedEndDate,
}) => {
  //#region state
  const [groupName, setGroupName] = useState("");
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedPersonalizeFieldRender, setSelectedPersonalizeFieldRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [groupExportPeriod, setGroupExportPeriod] = useState(0);
  //#endregion

  //#region selector
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  const students = useSelector((state) => state.studentSlice.students);
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const centerName = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.name);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  //#endregion

  //#region consts
  const renderPersonalizeField = [
    { label: getTranslations("render_personalize_field_info", translations), value: true },
    { label: getTranslations("no_render_personalize_field", translations), value: false },
  ];
  //#endregion

  //#region functions
  const getStudentsSelected = () => {
    return students.filter((student) => studentsSelected.includes(student.id));
  };
  const getMachinesSubscribes = (studentsFiltered) => {
    return [...new Set(studentsFiltered.reduce((acc, array) => acc.concat(...array.machineSubscribes), []))];
  };

  const removeDuplicatedMachinesSubscribes = (studentsMachineList) => {
    let studentsFilteredMachineList = [];
    studentsMachineList.forEach((machine) => {
      if (studentsFilteredMachineList.find((machineAdded) => machine.id === machineAdded.id)) {
      } else studentsFilteredMachineList.push(machine);
    });
    return studentsFilteredMachineList;
  };

  const checkStudentsSessionDates = () => {
    return getStudentsSelected().every(
      (student) =>
        student.accessStart === getStudentsSelected()[0].accessStart &&
        student.accessEnd === getStudentsSelected()[0].accessEnd
    );
  };
  const buildMachineList = () => {
    const studentsFiltered = getStudentsSelected();

    const studentsMachineList = getMachinesSubscribes(studentsFiltered);

    return removeDuplicatedMachinesSubscribes(studentsMachineList);
  };

  const buildArrayToMap = () => {
    let arrayToMap = [{ label: getTranslations("since_student_creation", translations), value: 0 }];
    if (sessionDates === "true" && checkStudentsSessionDates())
      arrayToMap.push({ label: getTranslations("session_dates", translations), value: 1 });
    else arrayToMap.push({ label: getTranslations("session_dates", translations), value: 1, disabled: true });
    return arrayToMap;
  };

  const renderSessionDates = () => {
    if (sessionDates === "true" && checkStudentsSessionDates()) {
      return (
        <div className="session-dates">
          <span>{`${getTranslations("start_date", translations)} ${new Date(
            getStudentsSelected()[0]?.accessStart
          ).toLocaleDateString()}`}</span>
          <span>{`${getTranslations("end_date", translations)} ${new Date(
            getStudentsSelected()[0]?.accessEnd
          ).toLocaleDateString()}`}</span>
        </div>
      );
    } else if (sessionDates === "false" && checkStudentsSessionDates()) {
      return "";
    } else {
      return (
        <span className="session-dates-not-selectables">
          {getTranslations("session_date_not_selectable", translations)}
        </span>
      );
    }
  };
  const exportPdfModalTabs = () => {
    return {
      individual: (
        <>
          <hr />
          <ExportPdfParameters
            pdfTemplates={pdfTemplates}
            setPdfTemplates={setPdfTemplates}
            selectedTemplatePdf={selectedPdfTemplate}
            setSelectedTemplatePdf={setSelectedPdfTemplate}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            personalizedStartDate={personalizedStartDate}
            setPersonalizedStartDate={setPersonalizedStartDate}
            personalizedEndDate={personalizedEndDate}
            setPersonalizedEndDate={setPersonalizedEndDate}
          />
        </>
      ),
      group: (
        <>
          <hr />
          <span className="mandatory-field">
            {getTranslations("student_create_student_required_field", translations)}
          </span>
          <GstaInput
            label={getTranslations("group_name", translations)}
            placeholder={getTranslations("ex_export_grouped_pdf", translations)}
            value={groupName}
            setValue={setGroupName}
            isValid={true}
            type={"text"}
            appearanceClass={"gsta-input_column"}
          />
          <div className="period-export">
            <GroupedButton
              label={getTranslations("period_use_export", translations)}
              arrayToMap={buildArrayToMap()}
              layoutClass={"radio-buttons"}
              selected={groupExportPeriod}
              onClick={setGroupExportPeriod}
            />
            {renderSessionDates()}
          </div>
          <GroupedButton
            label={getTranslations("render_personalize_field", translations)}
            arrayToMap={renderPersonalizeField}
            layoutClass={"radio-buttons"}
            selected={selectedPersonalizeFieldRender}
            onClick={setSelectedPersonalizeFieldRender}
          />
          <ExportPdfModalList
            machineList={buildMachineList()}
            selectedMachines={selectedMachines}
            setSelectedMachines={setSelectedMachines}
          />
        </>
      ),
    };
  };

  const getKeys = () => {
    return Object.keys(exportPdfModalTabs() ?? {});
  };
  //#endregion

  //#region state
  const [selectedTab, setSelectedTab] = useState(defaultTabSelected ? defaultTabSelected : getKeys()[0]);
  //#endregion
  //#region function
  const pdfExport = async () => {
    if (selectedTab === EXPORT_TYPE.INDIVIDUAL) {
      studentsSelected.forEach(async (studentSelected) => {
        const student = students.find((student) => student.id === studentSelected);
        const studentResult = await getStudentResultsByTemplate(centerId, studentSelected, {
          templateId: selectedPdfTemplate.id,
          startPeriod: getStartPeriod(student, sessionDates, selectedPeriod, personalizedStartDate),
          endPeriod: getEndPeriod(student, sessionDates, selectedPeriod, personalizedEndDate),
        });
        studentResult.customValues = student.customValues;
        studentResult.name = student.name;
        studentResult.firstName = student.firstName;
        await PrepareCustomPdf(
          notationType,
          translations,
          getStartPeriod(student, sessionDates, selectedPeriod, personalizedStartDate),
          getEndPeriod(student, sessionDates, selectedPeriod, personalizedEndDate),
          sessionDates,
          customFields,
          selectedPdfTemplate,
          studentResult,
          centerId
        );
      });
    } else {
      const groupExportData = {
        groupName: groupName,
        startGroup: !!groupExportPeriod ? getStudentsSelected()[0].accessStart : undefined,
        endGroup: !!groupExportPeriod ? getStudentsSelected()[0].accessEnd : undefined,
        students: studentsSelected,
        machines: selectedMachines,
        showCustomFields: selectedPersonalizeFieldRender,
      };
      await PrepareGroupedPdf(
        centerId,
        groupExportData,
        notationType,
        translations,
        !!groupExportPeriod,
        customFields,
        centerName
      );
    }
    setLoading(false);
  };

  const launchPdfExport = async () => {
    setLoading(true);
    await pdfExport();
    setModalOpen(false);
  };
  //#endregion

  return (
    <GstaModal
      modalOpen={modalOpen}
      validText={
        selectedTab === EXPORT_TYPE.INDIVIDUAL
          ? getTranslations("student_grouped_actions_pdf_export", translations)
          : getTranslations("export_group_pdf", translations)
      }
      className={"grouped-pdf-modal"}
      cancelText={getTranslations("common_cancel", translations)}
      validIcon={"icon-pdf"}
      loading={loading}
      loadingText={getTranslations("exporting", translations)}
      title={getTranslations("export_pdf_parameters", translations)}
      validateDisabled={selectedTab === EXPORT_TYPE.GROUP && (selectedMachines.length === 0 || groupName.length === 0)}
      handleValidate={async () => await launchPdfExport()}
      handleCloseModal={() => setModalOpen(false)}
    >
      <AcreosTabs
        tabs={exportPdfModalTabs()}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        keys={getKeys()}
        title={getTranslations("export_type", translations)}
      />
    </GstaModal>
  );
};

export default ExportPdfModal;
