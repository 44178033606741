//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import TrainingRow from "../TrainingRow/TrainingRow.component";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region style import
import "./TrainingTable.style.scss";
//#endregion

const TrainingTable = ({ setModalOpen, deleteJourney, trainingsIds }) => {
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  const translations = useSelector((state) => state.translationSlice.translations);

  const handleAddJourneyClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <div className="create-student-training-table">
      <div className="create-student-training-table_header">
        <h2>{getTranslations("student_create_student_training_steps", translations)}</h2>
        <button
          className="gsta-button-outlined"
          onClick={handleAddJourneyClick}
          data-testid="open-modal-add-journey"
          type="button"
        >
          <i className="icon-add_file" />
          <span>{getTranslations("student_create_student_add_training", translations)}</span>
        </button>
      </div>
      <hr />
      <table className={trainingsIds.length > 0 ? "gsta-table" : "gsta-table-empty"}>
        <thead>
          <tr>
            <th>{getTranslations("common_training_name", translations)}</th>
            <th>{getTranslations("common_machine", translations)}</th>
            <th>{getTranslations("common_duration", translations)}</th>
            <th>{getTranslations("add_training_modal_steps_number", translations)}</th>
            <th>{getTranslations("common_action", translations)}</th>
          </tr>
        </thead>
        <tbody>
          {trainings
            .filter((training) => trainingsIds.includes(training.id))
            .map((training) => {
              return (
                <TrainingRow
                  key={training.id}
                  deleteJourney={deleteJourney}
                  training={training}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TrainingTable;
