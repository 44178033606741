//#region react import
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getTranslations } from "../Helper/TranslationController";
//#endregion

//#region layout import
import CenterGlobalLayout from "../Layout/CenterGlobalLayout/CenterGlobalLayout.component";
import CenterParametersLayout from "../Layout/CenterParametersLayout/CenterParametersLayout.component";
import ChangeActiveCenterLayout from "../Layout/ChangeActiveCenterLayout/ChangeActiveCenterLayout.component";
import GstaConnexionLayout from "../Layout/GstaConnexionLayout/GstaConnexionLayout.component";
import SimulatorsLayout from "../Layout/SimulatorsLayout/SimulatorsLayout.component";
//#endregion

//#region page import
import UndisplayFeature from "../Components/UndisplayFeature/UndisplayFeature.component";
import CreateStudentLayout from "../Layout/CreateStudentLayout/CreateStudentLayout.component";
import HomeGlobalLayout from "../Layout/HomeGlobalLayout/HomeGlobalLayout.component";
import StudentDetailsGlobalLayout from "../Layout/StudentDetailsGlobalLayout/StudentDetailsGlobalLayout";
import ArchivedTrainingPage from "../Pages/ArchivedTrainingPage/ArchivedTrainingPage.component";
import InscriptionManagementPage from "../Pages/Center/InscriptionManagementPage/InscriptionManagementPage.component";
import DesignValidationPage from "../Pages/DesignValidationPage/DesignValidationPage.component";
import UnexpectedError from "../Pages/GstaConnexion/UnexpectedError/UnexpectedError.component";
import DashBoard from "../Pages/Home/Dashboard/Dashboard.component";
import MonthActivity from "../Pages/Home/MonthActivity/MonthActivity.component";
import LoadingPage from "../Pages/LoadingPage/LoadingPage.component";
import ProfilHome from "../Pages/Profil/ProfileHome/ProfileHome.component";
import ArchivedStudentStudentPage from "../Pages/Student/ArchivedStudentPage/ArchivedStudentPage.component";
import StudentHome from "../Pages/Student/StudentHome/StudentHome.component";
import StudentPersonnalInformations from "../Pages/Student/StudentPersonnalInformation/StudentPersonnalInformations.component";
import StudentTraining from "../Pages/Student/StudentTraining/StudentTraining.component";
import ChooseModulePage from "../Pages/Training/ChooseModulePage/ChooseModulePage.component";
import CreateCustomTrainingMainInformationPage from "../Pages/Training/CreateCustomTrainingMainInformationPage/CreateCustomTrainingMainInformationPage.component";
import TrainingHome from "../Pages/Training/TrainingHome/TrainingHome.component";
import TrainingHomeDetail from "../Pages/Training/TrainingHomeDetail/TrainingHomeDetail.component";
//#endregion

//#region component import
import ConnectedRoute from "./RoutesCustom/ConnectedRoute";
//#endregion

//#region services
//endregion

//#region constants
import CustomPdfViewer from "../Components/Pdf/CustomPdf/CustomPdfViewer.component";
import {
  CENTER,
  CENTER_SETTINGS,
  CENTER_SIMULATORS,
  CURRENT_ACTIVITY,
  CUSTOM_FIELDS,
  DESIGN_VALDIATION,
  HOME,
  LOADING,
  LOGIN,
  MONTH_ACTIVITY,
  PROFIL,
  SELECT_ACTIVE_CENTER,
  STUDENTS,
  STUDENTS_ARCHIVED,
  STUDENTS_CREATE,
  STUDENTS_DETAILS,
  STUDENTS_PERSONNAL_INFORMATIONS,
  STUDENTS_TRAINING,
  TRAININGS,
  TRAININGS_ARCHIVED,
  TRAININGS_CHOOSE_MACHINE,
  TRAININGS_CHOOSE_MODULE,
  TRAININGS_DETAILS,
} from "./PageNames";

//#endregion
const MainRouting = () => {
  const getLastElementOfPath = (url) => {
    const splited = url.split("/");
    return splited[splited.length - 1];
  };

  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <Routes>
      <Route
        path={LOGIN.url}
        element={<GstaConnexionLayout />}
      />
      <Route
        path="/unexpectedError"
        element={<UnexpectedError />}
      />
      <Route
        path={LOADING.url}
        element={
          <ConnectedRoute>
            <LoadingPage />
          </ConnectedRoute>
        }
      />
      <Route path={HOME.url}>
        <Route
          path={getLastElementOfPath(CURRENT_ACTIVITY.url)}
          element={
            <HomeGlobalLayout>
              <DashBoard />
            </HomeGlobalLayout>
          }
        />
        <Route
          path={getLastElementOfPath(MONTH_ACTIVITY.url)}
          element={
            <ConnectedRoute>
              <HomeGlobalLayout>
                <MonthActivity />
              </HomeGlobalLayout>
            </ConnectedRoute>
          }
        />
      </Route>
      <Route
        path={STUDENTS_ARCHIVED.url}
        element={
          <ConnectedRoute>
            <ArchivedStudentStudentPage />
          </ConnectedRoute>
        }
      />
      <Route
        path={STUDENTS.url}
        element={
          <ConnectedRoute>
            <StudentHome />
          </ConnectedRoute>
        }
      />
      <Route
        path={STUDENTS_CREATE.url}
        element={
          <ConnectedRoute>
            <CreateStudentLayout />
          </ConnectedRoute>
        }
      />
      <Route path={STUDENTS_DETAILS.url}>
        <Route
          path={getLastElementOfPath(STUDENTS_PERSONNAL_INFORMATIONS.url)}
          element={
            <ConnectedRoute>
              <StudentDetailsGlobalLayout
                icon="helmet"
                title={STUDENTS.pageTraductionKey}
              >
                <StudentPersonnalInformations />
              </StudentDetailsGlobalLayout>
            </ConnectedRoute>
          }
        />
        <Route
          path={getLastElementOfPath(STUDENTS_TRAINING.url)}
          element={
            <ConnectedRoute>
              <StudentDetailsGlobalLayout
                icon="helmet"
                title={STUDENTS.pageTraductionKey}
              >
                <StudentTraining />
              </StudentDetailsGlobalLayout>
            </ConnectedRoute>
          }
        />
      </Route>
      <Route
        path={TRAININGS.url}
        element={
          <ConnectedRoute>
            <TrainingHome />
          </ConnectedRoute>
        }
      />
      <Route
        path={TRAININGS_DETAILS.url}
        element={
          <ConnectedRoute>
            <TrainingHomeDetail />
          </ConnectedRoute>
        }
      />
      <Route path={CENTER.url}>
        <Route
          path={getLastElementOfPath(SELECT_ACTIVE_CENTER.url)}
          element={
            <UndisplayFeature isLocal={process.env.REACT_APP_LOCAL}>
              <ConnectedRoute>
                <CenterGlobalLayout>
                  <ChangeActiveCenterLayout />
                </CenterGlobalLayout>
              </ConnectedRoute>
            </UndisplayFeature>
          }
        />
        <Route
          path={getLastElementOfPath(CENTER_SIMULATORS.url)}
          element={
            <ConnectedRoute>
              <CenterGlobalLayout>
                <SimulatorsLayout />
              </CenterGlobalLayout>
            </ConnectedRoute>
          }
        />
        <Route
          path={getLastElementOfPath(CUSTOM_FIELDS.url)}
          element={
            <ConnectedRoute>
              <CenterGlobalLayout>
                <InscriptionManagementPage />
              </CenterGlobalLayout>
            </ConnectedRoute>
          }
        />
        <Route
          path={getLastElementOfPath(CENTER_SETTINGS.url)}
          element={
            <ConnectedRoute>
              <CenterGlobalLayout>
                <CenterParametersLayout title={getTranslations(CENTER_SETTINGS.pageTraductionKey, translations)} />
              </CenterGlobalLayout>
            </ConnectedRoute>
          }
        />
      </Route>
      <Route
        path={PROFIL.url}
        element={
          <ConnectedRoute>
            <ProfilHome />
          </ConnectedRoute>
        }
      />
      <Route
        path={TRAININGS_CHOOSE_MACHINE.url}
        element={<CreateCustomTrainingMainInformationPage />}
      />
      <Route
        path={TRAININGS_CHOOSE_MODULE.url}
        element={<ChooseModulePage />}
      />
      <Route
        path={TRAININGS_ARCHIVED.url}
        element={
          <ConnectedRoute>
            <ArchivedTrainingPage />
          </ConnectedRoute>
        }
      />
      {process.env.NODE_ENV !== "production" && (
        <Route
          path={"pdf"}
          element={<CustomPdfViewer />}
        />
      )}
      <Route
        path="*"
        element={<Navigate to={LOGIN.url} />}
      />
      {process.env.NODE_ENV !== "production" && (
        <Route
          path={DESIGN_VALDIATION.url}
          element={<DesignValidationPage />}
        />
      )}
    </Routes>
  );
};

export default MainRouting;
