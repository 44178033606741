//#region react import
import React from "react";
import { useSelector } from "react-redux";
//#endregion

//#region custom import
import SwitchWithText from "../../../Components/SwitchWithText/SwitchWithText.component";
import GroupedButton from "../../../Components/GroupedButton/GroupedButton.component";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { TYPE_NOTATION, MEASURING_SYSTEM } from "../../../Constants/SettingsConstants";
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
//#endregion

//#region style import
import "./CenterParameters.style.scss";
//#endregion

const CenterParameters = ({
  handleChangeShowSimulatorNotation,
  handleChangeSimulatorMeasuringSystem,
  handleChangeSimulatorNotationType,
  simulatorNotation,
  simulatorNotationType,
  simulatorMeasuringSystem,
}) => {
  const translations = useSelector((state) => state.translationSlice.translations);

  return (
    <div className="simulator-notation">
      <SwitchWithText
        label={getTranslations("center_show_simulator_notation", translations)}
        dataTestId={"switch-show-note"}
        overrideClass={"slider"}
        onText={getTranslations(YES, translations)}
        offText={getTranslations(NO, translations)}
        checked={simulatorNotation}
        handleChange={handleChangeShowSimulatorNotation}
      />

      <GroupedButton
        label={getTranslations("center_notation_type", translations)}
        arrayToMap={TYPE_NOTATION}
        onClick={handleChangeSimulatorNotationType}
        selected={simulatorNotationType}
        dataTestId={"button-mapped"}
      />

      <GroupedButton
        label={getTranslations("center_measuring_system", translations)}
        arrayToMap={MEASURING_SYSTEM}
        onClick={handleChangeSimulatorMeasuringSystem}
        selected={simulatorMeasuringSystem}
      />
    </div>
  );
};

export default CenterParameters;
