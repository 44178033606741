//#region react import
import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import ActionsGrouped from "../ActionsGrouped/ActionsGrouped.component";
//#endregion

//#region store import
import {
  setErrorNotification,
  setSuccessNotification,
} from "../../ReduxStore/notificationSlice";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

const DownloadNoticeGroupedActions = () => {
  //#region redux store
  const translations = useSelector(
    (state) => state.translationSlice.translations
  );
  const language = useSelector((state) => state.translationSlice.language);
  const GstaNotice = "GstaPro";
  const DeltaOsNotice = "DeltaOs";
  const dispatch = useDispatch();
  //#endregion

  const saveNotice = (codeNotice) => {
    fetch(
      process.env.PUBLIC_URL +
        "/Notices/notice_" +
        codeNotice +
        "_" +
        language +
        ".pdf"
    ).then((response) => {
      if (response.ok) {
        FileSaver.saveAs(
          process.env.PUBLIC_URL +
            "/Notices/notice_" +
            codeNotice +
            "_" +
            language +
            ".pdf",
          codeNotice
        );
      } else {
        FileSaver.saveAs(
          process.env.PUBLIC_URL + "/Notices/notice_" + codeNotice + "_en.pdf",
          codeNotice
        );
      }
    });
  };

  const handleDownloadGstaClick = async () => {
    try {
      saveNotice(GstaNotice);
      dispatch(
        setSuccessNotification(
          getTranslations("notification_notice_downloaded", translations)
        )
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleDownloadDeltaOsClick = async () => {
    try {
      saveNotice(DeltaOsNotice);
      dispatch(
        setSuccessNotification(
          getTranslations("notification_notice_downloaded", translations)
        )
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const actionList = [
    {
      action: handleDownloadGstaClick,
      iconClass: "icon-download",
      text: getTranslations("notice_gstaPro", translations),
    },
    {
      action: handleDownloadDeltaOsClick,
      iconClass: "icon-download",
      text: getTranslations("notice_deltaOs", translations),
    },
  ];

  //#endregion

  return (
    <ActionsGrouped
      appearanceClass={"gsta-notice-button"}
      content={<i className="icon-help_documentation text_icon" />}
      actionsList={actionList}
      toolTipContent={getTranslations("download_notices", translations)}
    />
  );
};
export default DownloadNoticeGroupedActions;
