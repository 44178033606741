//#region react import
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region function import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import { getMonthNameTroncate } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./AcreosDatePicker.style.scss";
//#endregion

//#region constants import
import { MONTH_NAME_TRONCATE_LENGTH, ONE_HOUR_MILISECONDS } from "../../Constants/DateConstants";
import { DATEPICKER_TYPE, DATE_PICKER_RANGE_TYPE, DATE_PICKER_TYPE_SELECTION } from "./AcreosDatePicker.constants";
//#endregion

/**
 *
 *
 *
 */
const AcreosDatePicker = ({
  children,
  type,
  months,
  days,
  selectRange,
  rangeType,
  selectedDateTime,
  setSelectedDateTime,
  selectedDateTimes,
  setSelectedDateTimes,
  disabledFutureDate,
  datePickerOpen,
  setDatePickerOpen,
  refOpenDatePicker,
  modifierClass,
}) => {
  //#region useState
  const [typeSelected, setTypeSelected] = useState();
  const [workingDate, setWorkingDate] = useState(selectedDateTime ?? new Date());

  const currentDate = new Date(new Date().toGMTString());
  currentDate.setHours(0, 0, 0, 0);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const ref = useRef();
  //#endregion

  //#region functions
  const handleAddButtonClick = (event) => {
    event.preventDefault();
    changeYearAddRemove(true);
  };

  const handleRemoveButtonClick = (event) => {
    event.preventDefault();
    changeYearAddRemove(false);
  };

  const changeYearAddRemove = (add) => {
    if (
      type === DATEPICKER_TYPE.SELECT_MONTH ||
      typeSelected === DATE_PICKER_TYPE_SELECTION.MONTH ||
      typeSelected === DATE_PICKER_TYPE_SELECTION.YEAR
    ) {
      changeYear(workingDate.getFullYear() + (add ? 1 : -1));
    } else {
      const newDate = new Date(
        workingDate.getFullYear(),
        workingDate.getMonth() + (add ? 1 : -1),
        workingDate.getDate()
      );
      setWorkingDate(newDate);
    }
  };

  const closeDatePicker = () => {
    setDatePickerOpen && setDatePickerOpen(false);
    setWorkingDate();
  };

  const changeYear = (newYear) => {
    const newDate = new Date(new Date(newYear, workingDate.getMonth(), workingDate.getDate(), 1).toGMTString());
    setWorkingDate(newDate);
  };

  const handleSelectRangeDateClick = (newDate) => {
    const newSelectedDateTimes = [];
    if (selectedDateTimes.length === 0) {
      newSelectedDateTimes.push(newDate);
      setSelectedDateTimes(newSelectedDateTimes);
    } else if (rangeType === DATE_PICKER_RANGE_TYPE.MODIFY_LAST_DATE && newDate.getTime() < selectedDateTimes[0]) {
      setSelectedDateTimes([newDate]);
    } else {
      let minDate;
      let maxDate;
      if (rangeType === DATE_PICKER_RANGE_TYPE.CREATE) {
        if (selectedDateTimes.length >= 2) {
          setSelectedDateTimes([newDate]);
          return;
        } else {
          minDate = new Date(Math.min(newDate.getTime(), selectedDateTimes[0].getTime()));
          maxDate = new Date(Math.max(newDate.getTime(), selectedDateTimes[selectedDateTimes.length - 1].getTime()));
        }
      } else {
        minDate = new Date(selectedDateTimes[0]?.getTime() ?? newDate.getTime());
        maxDate = new Date(newDate.getTime());
      }
      setSelectedDateTimes([minDate, maxDate]);
    }
  };

  const handleDateClick = async (event, day) => {
    event.preventDefault();
    const newDate = new Date(new Date(workingDate.getFullYear(), workingDate.getMonth(), day));
    if (selectRange) {
      handleSelectRangeDateClick(newDate);
    } else {
      await setSelectedDateTime(newDate);
      closeDatePicker();
    }
  };

  const handleClickYear = (event, year) => {
    event.preventDefault();
    setTypeSelected(DATE_PICKER_TYPE_SELECTION.MONTH);
    setWorkingDate(new Date(year, workingDate.getMonth(), 1, 1));
  };

  const handleClickMonth = async (event, newMonth) => {
    event.preventDefault();
    if (type === DATEPICKER_TYPE.SELECT_DAY) {
      const newDate = new Date(new Date(workingDate.getFullYear(), newMonth, workingDate.getDate(), 1).toGMTString());
      setWorkingDate(newDate);
      setTypeSelected(DATE_PICKER_TYPE_SELECTION.DAY);
    } else if (type === DATEPICKER_TYPE.SELECT_MONTH) {
      const newSelectedDateTime = new Date(workingDate.getFullYear(), newMonth, 1);
      closeDatePicker();
      await setSelectedDateTime(newSelectedDateTime);
    }
  };

  const renderYear = () => {
    const yearToRender = [];
    const selectedYear = workingDate.getFullYear();
    for (let year = selectedYear - 6; year < selectedYear + 6; year++) {
      yearToRender.push(
        <button
          className="date_button"
          key={year}
          data-testid={process.env.NODE_ENV === "test" && "month-button-" + year}
          active={(selectedYear === year).toString()}
          disabled={disabledFutureDate && year > currentDate.getFullYear()}
          onClick={(event) => {
            handleClickYear(event, year);
          }}
        >
          {year}
        </button>
      );
    }
    return yearToRender;
  };

  const manageDateButtonClass = (buttonDate) => {
    let array = "";

    const lastDate = selectedDateTimes[selectedDateTimes.length - 1];
    const firstDate = selectedDateTimes[0];
    if (
      buttonDate.getDate() === lastDate?.getDate() &&
      buttonDate.getMonth() === lastDate?.getMonth() &&
      buttonDate.getFullYear() === lastDate?.getFullYear()
    ) {
      if (
        selectedDateTimes.length === 1 ||
        (firstDate?.getDate() === lastDate?.getDate() &&
          firstDate?.getMonth() === lastDate?.getMonth() &&
          firstDate?.getFullYear() === lastDate?.getFullYear())
      )
        return " single";
      array += " last";
    } else if (
      buttonDate.getDate() === firstDate?.getDate() &&
      buttonDate.getMonth() === firstDate?.getMonth() &&
      buttonDate.getFullYear() === firstDate?.getFullYear()
    ) {
      array += " first";
    } else if (
      buttonDate.getTime() / ONE_HOUR_MILISECONDS > selectedDateTimes[0]?.getTime() / ONE_HOUR_MILISECONDS &&
      buttonDate.getTime() / ONE_HOUR_MILISECONDS <
        selectedDateTimes[selectedDateTimes.length - 1]?.getTime() / ONE_HOUR_MILISECONDS
    ) {
      array += " middle";
    }
    return array;
  };

  const renderDays = () => {
    const daysToRender = days.map((day, index) => <span key={index}>{day.name[0].toUpperCase()}</span>);
    // add empty case if the first element isn't monday
    for (
      let day = 0;
      day < (6 + new Date(workingDate?.getFullYear(), workingDate?.getMonth(), 1).getDay()) % 7;
      day++
    ) {
      daysToRender.push(<span />);
    }

    let buttonDate;
    if (!workingDate) return;
    // add button
    for (
      let index = 1;
      index <=
      new Date(
        new Date(workingDate.getFullYear(), workingDate.getMonth() + 1, 0).toISOString().slice(0, -1)
      ).getDate() +
        1;
      index++
    ) {
      buttonDate = new Date(workingDate.getFullYear(), workingDate.getMonth(), index);
      const array = selectRange ? manageDateButtonClass(buttonDate) : "";
      daysToRender.push(
        <button
          key={"day" + rangeType + index}
          className={"date_button " + array}
          current_day={(buttonDate.getTime() === currentDate.getTime()).toString()}
          onClick={async (event) => await handleDateClick(event, index)}
          active={(
            buttonDate.getDate() === selectedDateTime?.getDate() &&
            buttonDate.getMonth() === selectedDateTime?.getMonth() &&
            buttonDate.getFullYear() === selectedDateTime?.getFullYear()
          ).toString()}
          disabled={
            disabledFutureDate &&
            workingDate.getFullYear() >= currentDate.getFullYear() &&
            workingDate.getMonth() >= currentDate.getMonth() &&
            index > currentDate.getDate()
          }
        >
          <span key={`day-${index}`}>{index}</span>
        </button>
      );
    }
    return daysToRender;
  };

  const getDateString = (date) => {
    if (!date) return "";
    const monthLabel = months.find((month) => month?.number === date.getMonth() + 1)?.name;
    const dayLabel = days.find((day) => day.number % 7 === date.getDay())?.name;
    return `${dayLabel} ${date.getDate()} ${monthLabel} ${date.getFullYear()}`;
  };

  const getTitle = () => {
    if (selectRange) {
      const monthStartDate = getMonthNameTroncate(selectedDateTimes[0], months, MONTH_NAME_TRONCATE_LENGTH);
      const monthEndDate = getMonthNameTroncate(
        selectedDateTimes[selectedDateTimes.length - 1],
        months,
        MONTH_NAME_TRONCATE_LENGTH
      );
      return selectedDateTimes.length > 0 && selectedDateTimes[0] && selectedDateTimes[selectedDateTimes.length - 1]
        ? `${selectedDateTimes[0]?.getDate()} ${monthStartDate} ${getTranslations(
            "session_date_to",
            translations
          )} ${selectedDateTimes[selectedDateTimes.length - 1]?.getDate()} ${monthEndDate}`
        : "-";
    } else if (type === DATEPICKER_TYPE.SELECT_DAY) {
      return getDateString(workingDate);
    } else {
      return;
    }
  };

  const handleSelectType = (event, newType) => {
    event.preventDefault();
    if (typeSelected === newType) {
      setTypeSelected();
    } else {
      setTypeSelected(newType);
    }
  };
  //#endregion

  //#region useQuery
  //#endregion
  //#region useEffect
  useClickOutside([ref, refOpenDatePicker], true, closeDatePicker);

  useEffect(() => {
    if (!workingDate) {
      !workingDate && setWorkingDate(selectedDateTime ?? new Date());
    }
    if (datePickerOpen)
      ref.current.scrollIntoView &&
        ref.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }, [datePickerOpen, selectedDateTime, selectRange, workingDate]);
  //#endregion

  return (
    <section className="acreos-date-picker">
      {children}
      {datePickerOpen && (
        <article
          className={`date-picker_container ${modifierClass ?? ""}`}
          ref={ref}
        >
          <h1>{getTitle()}</h1>
          <article className="change_date-select">
            <button
              onClick={handleRemoveButtonClick}
              data-testid={process.env.NODE_ENV === "test" && "remove-month-year"}
            >
              <i className="icon-left_arrow" />
            </button>
            {type === DATEPICKER_TYPE.SELECT_DAY && (
              <div>
                <button
                  onClick={(event) => handleSelectType(event, DATE_PICKER_TYPE_SELECTION.MONTH)}
                  active={(typeSelected === DATE_PICKER_TYPE_SELECTION.MONTH).toString()}
                >
                  <span>{months.find((month) => month?.number === workingDate?.getMonth() + 1)?.name}</span>
                  <i className="icon-triangle_down" />
                </button>
                <button
                  onClick={(event) => handleSelectType(event, DATE_PICKER_TYPE_SELECTION.YEAR)}
                  active={(typeSelected === DATE_PICKER_TYPE_SELECTION.YEAR).toString()}
                >
                  <span>{workingDate?.getFullYear()}</span>
                  <i className="icon-triangle_down" />
                </button>
              </div>
            )}
            {type === DATEPICKER_TYPE.SELECT_MONTH && (
              <span className="acreos-date-picker_year-span">{workingDate.getFullYear()}</span>
            )}

            <button
              onClick={handleAddButtonClick}
              disabled={
                disabledFutureDate &&
                ((typeSelected === DATE_PICKER_TYPE_SELECTION.YEAR &&
                  workingDate.getFullYear() >= currentDate.getFullYear()) ||
                  ((!typeSelected || typeSelected === DATE_PICKER_TYPE_SELECTION.MONTH) &&
                    workingDate.getFullYear() >= currentDate.getFullYear() &&
                    workingDate.getMonth() >= currentDate.getMonth()))
              }
              data-testid={process.env.NODE_ENV === "test" && "add-month-year"}
            >
              <i className="icon-right_arrow" />
            </button>
          </article>
          <article
            className={
              type === DATEPICKER_TYPE.SELECT_DAY && (!typeSelected || typeSelected === DATE_PICKER_TYPE_SELECTION.DAY)
                ? "days_grid"
                : "month-year_grid"
            }
          >
            {((type === DATEPICKER_TYPE.SELECT_MONTH && !typeSelected) ||
              typeSelected === DATE_PICKER_TYPE_SELECTION.MONTH) &&
              months.map((month) => {
                return (
                  <button
                    className="date_button"
                    key={month.number}
                    data-testid={process.env.NODE_ENV === "test" ? "month-button-" + month.number : null}
                    active={(workingDate.getMonth() === month.number - 1).toString()}
                    current={(
                      currentDate.getFullYear() === workingDate.getFullYear() &&
                      currentDate.getMonth() === month.number - 1
                    ).toString()}
                    disabled={
                      disabledFutureDate &&
                      workingDate.getFullYear() >= new Date().getFullYear() &&
                      month.number > new Date().getMonth() + 1
                    }
                    onClick={async (event) => {
                      await handleClickMonth(event, month.number - 1);
                    }}
                  >
                    {month.name}
                  </button>
                );
              })}
            {typeSelected === DATE_PICKER_TYPE_SELECTION.YEAR && renderYear()}
            {type === DATEPICKER_TYPE.SELECT_DAY &&
              (!typeSelected || typeSelected === DATE_PICKER_TYPE_SELECTION.DAY) &&
              renderDays()}
          </article>
        </article>
      )}
    </section>
  );
};
AcreosDatePicker.propTypes = {
  /**the children where the datePicker is linked */
  children: PropTypes.any.isRequired,
  /**  define if the datepicker select a day or a month*/
  type: PropTypes.oneOf([DATEPICKER_TYPE.SELECT_MONTH, DATEPICKER_TYPE.SELECT_DAY]).isRequired,
  /** definition of the month with translations*/
  months: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.number,
    })
  ),
  // definition of the days with translations
  days: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.number,
    })
  ),
  /** if it's true, then the datepicker select not one but multiple dates */
  selectRange: PropTypes.bool,
  /** define if the datepicker modify all the dates or only the last date */
  rangeType: PropTypes.oneOf([DATE_PICKER_RANGE_TYPE.CREATE, DATE_PICKER_RANGE_TYPE.MODIFY_LAST_DATE]),
  // if not sslectrange, it represent the date selected
  selectedDateTime: PropTypes.object,
  /** function to modify the selectedDateTime */
  setSelectedDateTime: PropTypes.func,
  // all the datetimes selected
  selectedDateTimes: PropTypes.arrayOf(PropTypes.object),
  /** set the selected dateTime */
  setSelectedDateTimes: PropTypes.func,
  /** define if the date superior to the current date is enabled or not */
  disabledFutureDate: PropTypes.bool,
};

export default AcreosDatePicker;
