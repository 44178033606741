//#region react import
import { useState } from "react";
import PropTypes from "prop-types";
//#endregion

//#region functions import
import { stringFormatterOverload } from "../../Helper/StringFormatter";
import { SetDataTestId } from "../../Helper/DataTestId";
//#endregion

//#region components import
import AcreosCheckBox from "../AcreosCheckBox/AcreosCheckBox.component";
//#endregion

//#region services import
//#endregion

//#region constants import
import { STANDARD_FORMAT_STRING_LENGTH } from "../../Constants/StringConstants";
//#endregion

/**
 * The filter of the gsta table
 */

const GstaTableHeaderFilter = ({ modifyFilter, columnDefinition, filter, getColumnValues }) => {
  //#region state
  const [open, setOpen] = useState(false);
  //#endregion

  //#region constants
  const columnValues = getColumnValues(columnDefinition);
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    setOpen(!open);
  };

  const handleClickFilter = (value) => {
    modifyFilter(columnDefinition.columnName, value);
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div>
      <div className="gsta-table-complete-header">
        {columnValues.length > 0 && (
          <button
            className="icon-button"
            onClick={handleOpenMenu}
            data-testid={SetDataTestId("open-filter-" + columnDefinition.columnName)}
          >
            <i className="icon-funnel"></i>
            {filter.length !== 0 && <span className="icon-button-filter-count">{filter.length}</span>}
          </button>
        )}

        {open && (
          <div>
            <div
              className="gsta-table-complete-header-filter_back"
              onClick={handleClickAway}
            ></div>
            <div className="gsta-table-complete-header-filter">
              <div>
                {columnValues.map((value, index) => (
                  <div
                    key={index}
                    className="gsta-table-complete-header-filter_value"
                    onClick={() => handleClickFilter(value)}
                  >
                    <AcreosCheckBox
                      checked={filter.includes(value)}
                      dataTestId={SetDataTestId("filter-checkBox-" + value)}
                    />
                    <div>
                      {stringFormatterOverload(value, STANDARD_FORMAT_STRING_LENGTH, STANDARD_FORMAT_STRING_LENGTH)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
GstaTableHeaderFilter.propTypes = {
  modifyFilter: PropTypes.func,
  columnDefinition: PropTypes.object,
  filter: PropTypes.array,
  getColumnValues: PropTypes.func,
};
export default GstaTableHeaderFilter;
