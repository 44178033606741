//#region react import
import PropTypes from "prop-types";
//#endregion

//#region custom import
import StepProgress from "../StepProgress/StepProgress.component";
//#endregion

//#region constants import
import { STEP_PROGRESS_STATE } from "../StepProgress/StepProgress.constants";
//#endregion

//#region style import
import "./StepProgressTimeline.style.scss";
//#endregion

const StepProgressTimeline = ({ data, nbDisplay, toolTips }) => {
  if (!data.steps) {
    return <></>;
  }

  let startIndex = 0;
  let endIndex = data.steps.length;
  let haveMoreStep = false;
  let haveLessStep = false;
  let currentIndex = 0;

  if (nbDisplay) {
    currentIndex = data.steps.findIndex((step) => step.stepState === STEP_PROGRESS_STATE.CURRENT);
    if (currentIndex > 1) {
      // cette partie gère le cas ou on a une étape en cours, l'étape en cours est toujours au milieu des steps qui sont affichées.
      // par exemple on peut avoir 4 steps d'afficher de la 3 à la 6 car la step 4 est en cours.
      let midIndex;
      if (nbDisplay % 2 === 0) {
        midIndex = Math.floor(nbDisplay / 2);
        endIndex = Math.min(currentIndex + midIndex + 1, data.steps.length);
        startIndex = Math.max(currentIndex - midIndex + 1, 0);
      } else {
        midIndex = Math.floor(nbDisplay / 2) + 1;
        endIndex = Math.min(currentIndex + midIndex, data.steps.length);
        startIndex = Math.max(currentIndex - midIndex, 0);
      }
      haveLessStep = startIndex > 0;
      haveMoreStep = endIndex < data.steps.length;
    } else {
      // si aucune étape en cours alors on regarde la dernière étape pas faite.
      // si différent de 0 alors on prend l'etape d'avant et on centre sur cette étape.
      // si différent de -1 alors toutes les étapes ont été faite alors on affiche les X dernière
      // sinon on affiche à partir de la première step
      currentIndex = data.steps.findIndex((step) => step.stepState === STEP_PROGRESS_STATE.UNKNOWN);
      if (currentIndex === -1) {
        startIndex = data.steps.length - nbDisplay;
        haveLessStep = data.steps.length > nbDisplay;
      } else {
        endIndex = nbDisplay;
        haveMoreStep = endIndex < data.steps.length;
      }
    }
  }

  return (
    <section className={`timeLine ${haveLessStep ? "lessStep" : ""} ${haveMoreStep ? "moreStep" : ""}`}>
      {data.steps &&
        data.steps.slice(startIndex, endIndex).map((step, i) => {
          return (
            <StepProgress
              key={i}
              data={{ step: step, subscriptionId: data.subscriptionId, student: data.student }}
              toolTips={toolTips}
            />
          );
        })}
    </section>
  );
};

StepProgressTimeline.propTypes = {
  data: PropTypes.shape({
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        averagePercent: PropTypes.number,
        customDescription: PropTypes.string,
        customName: PropTypes.string,
        languageKey: PropTypes.string,
        stepState: PropTypes.number,
        totalSecondsElapsed: PropTypes.number,
        trainingStepId: PropTypes.number,
        trainingStepOrder: PropTypes.number,
        trainingStepType: PropTypes.string,
      })
    ),
    subscriptionId: PropTypes.number,
  }),
  // display a number of steps
  nbDisplay: PropTypes.number,
  // true for active tooltips else false
  toolTips: PropTypes.bool,
};

export default StepProgressTimeline;
