//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
//#endregion

//#region component import
import StepProgressTimeline from "../../../Components/StepProgressTimeline/StepProgressTimeline.component";
//#endregion

//#region functions import
import { ConvertNote } from "../../../Helper/NoteConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { formatSecondToStringTime, FORMAT_TIMESTRING } from "../../../Helper/TimeConverter";
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import useIsVisible from "../../../Helper/CustomHook/IsVisible";
//#endregion

//#region style import
import "./TrainingResult.style.scss";
//#endregion

const TrainingResult = ({ trainingResult }) => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);

  const dispatch = useDispatch();
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  useNotationType(dispatch, centerId, notationType);

  const getSpanInProgress = () => {
    let spanClass;
    let spanTranslation;
    if (trainingResult.isActive) {
      spanClass =
        trainingResult.currentStepOrder === trainingResult.stepCount ? "gsta-span-disabled" : "gsta-span-outlined";
      spanTranslation =
        trainingResult.currentStepOrder === trainingResult.stepCount
          ? getTranslations("student_detail_finished", translations)
          : getTranslations("student_detail_in_progress", translations);
    } else {
      spanClass = "gsta-span-outlined-invalid";
      spanTranslation = getTranslations("archived_training", translations);
    }
    return <span className={spanClass}>{spanTranslation}</span>;
  };

  return (
    <div
      ref={ref}
      className="result-per-training"
    >
      {isVisible ? (
        <div className="result-per-training_steps">
          <div className="result-per-training_steps_title">
            <h1>
              {`${getTranslations("student_detail_training_training", translations)} ${getTranslations(
                trainingResult.customName ?? trainingResult.languageKey,
                translations
              )}`}
            </h1>
            <div className="title-part">
              <span className="gsta-span">
                <i className="icon-hourglass" />
                {formatSecondToStringTime(trainingResult.totalSecondElapsed, translations, FORMAT_TIMESTRING.HHMMSS)}
              </span>
              <span className="span-title">
                {getTranslations("student_detail_training_training_total_time_elapsed", translations)}
              </span>
            </div>
            <div className="title-part">
              <span className={trainingResult.averagePercent > 50 ? "gsta-span-valid" : "gsta-span-invalid"}>
                <i className="icon-Gauge" />
                {ConvertNote(trainingResult.averagePercent, notationType)}
              </span>
              <span className="span-title">
                {getTranslations("student_detail_training_training_average", translations)}
              </span>
            </div>
            <div className={"title-part-last"}>{getSpanInProgress()}</div>
          </div>
          <hr className="student-training--separator" />
          <div className="result-per-training_steps_container">
            <b>{getTranslations("pdf_result_per_machine_progress", translations)}</b>
            <StepProgressTimeline
              data={{ steps: trainingResult.stepAverages, subscriptionId: trainingResult.subscriptionId }}
              toolTips
            />
          </div>
        </div>
      ) : (
        <div className="placholder-animation" />
      )}
    </div>
  );
};

export default TrainingResult;
