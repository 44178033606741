//#region style import
import "./PageWithTitle.style.scss";
//#endregion

const PageWithTitle = ({ header, title, children }) => {
  return (
    <div className="margin-left-right">
      <header className="page-title">{header ? header : <h1>{title}</h1>}</header>
      <div className="padding-bottom-3rem">{children}</div>
    </div>
  );
};

export default PageWithTitle;
