export const createStudentValidResponse = {
  data: {
    user: {
      id: 39,
      firstName: "lastname",
      lastName: "firstname",
      birthday: null,
      password: null,
      guid: "00000000-0000-0000-0000-000000000000",
      email: null,
    },
    archived: true,
    authorId: 27,
  },
  metaDatas: [],
};

export const getStudentResponse0 = {
  datas: [
    {
      id: 1,
      guid: "GUID1",
      name: "name1",
      firstName: "firstName1",
      lastConnectionDate: "2022-08-24T00:00:00",
      lastActivityDate: null,
      machineSubscribes: [
        {
          id: 0,
          code: "BU2",
          version: "2.2.1",
          languageKey: "BU2_name",
        },
      ],
      isActive: false,
      pinCode: "0001",
      customValues: [
        {
          id:1,
          name:"string",
          value:"test string student 1",
          fieldType:1
        },
        {
          id:2,
          name:"list",
          value:"value 1",
          fieldType:3
        }
      ]
    },
  ],
  totalNumberOfElements:31,
  pageNumber: 0,
  elementPerPage: 1,
  metaDatas: [
    {
      rel: "NextPage",
      href: "http://localhost:5003/v1.0/Centers/2/Students?pageNumber=1&numberOfElementPerPage=10",
    },
  ],
};

export const getStudentResponse1 = {
  datas: [
    {
      id: 2,
      guid: "GUID2",
      name: "name2",
      firstName: "firstName2",
      lastConnectionDate: "2022-08-23T00:00:00",
      lastActivityDate: null,
      machineSubscribes: [],
      isActive: true,
      pinCode: "0002",
      customValues: [
        {
          id:1,
          name:"string",
          value:"test string student 2",
          fieldType:1
        },
        {
          id:2,
          name:"list",
          value:"value 2",
          fieldType:3
        }
      ]
    },
  ],
  totalNumberOfElements:11,
  pageNumber: 0,
  elementPerPage: 1,
  metaDatas: [],
};

export const getStudentResults = {
  genericSkills: [],
  machineResults: [
    {
      machine: {
        id: 121,
        code: "VL1",
        version: "1.1.10",
        languageKey: "VL1_name",
      },
      totalSecondElapsed: 3600,
      averagePercent: null,
      machineSkills: [],
      trainingResults: [
        {
          subscriptionId: 14,
          trainingId: 546,
          studentId: 730,
          languageKey: "jrn_100_journey_name",
          subscriptedMachine: {
            id: 121,
            code: "VL1",
            version: "1.1.10",
            languageKey: "VL1_name",
          },
          totalSecondElapsed: 3600,
          averagePercent: null,
          stepAverages: [
            {
              trainingStepId: 5849,
              trainingStepOrder: 1,
              languageKey: "stp_100_1_step_name",
              trainingStepType: "ENTRAINEMENT",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: null,
              customDescription: null,
            },
            {
              trainingStepId: 5850,
              trainingStepOrder: 2,
              languageKey: "stp_100_2_step_name",
              trainingStepType: "ENTRAINEMENT",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: null,
              customDescription: null,
            },
          ],
          customDescription: null,
          customName: null,
          stepCount: 7,
          currentStepOrder: 0,
        },
        {
          subscriptionId: 15,
          trainingId: 554,
          studentId: 730,
          languageKey: "jrn_10_journey_name",
          subscriptedMachine: {
            id: 121,
            code: "VL1",
            version: "1.1.10",
            languageKey: "VL1_name",
          },
          totalSecondElapsed: 3660,
          averagePercent: null,
          stepAverages: [
            {
              trainingStepId: 5889,
              trainingStepOrder: 1,
              languageKey: "stp_10_1_step_name",
              trainingStepType: "ENTRAINEMENT",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: null,
              customDescription: null,
            },
            {
              trainingStepId: 5890,
              trainingStepOrder: 2,
              languageKey: "stp_10_2_step_name",
              trainingStepType: "ENTRAINEMENT",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: null,
              customDescription: null,
            },
          ],
          customDescription: null,
          customName: null,
          stepCount: 4,
          currentStepOrder: 0,
        },
      ],
    },
    {
      machine: {
        id: 154,
        code: "CH2",
        version: "1.4.5",
        languageKey: "CH2_name",
      },
      totalSecondElapsed: 0,
      averagePercent: null,
      machineSkills: [],
      trainingResults: [
        {
          subscriptionId: 17,
          trainingId: 671,
          studentId: 730,
          languageKey: "jrn_007_journey_name",
          subscriptedMachine: {
            id: 154,
            code: "CH2",
            version: "1.4.5",
            languageKey: "CH2_name",
          },
          totalSecondElapsed: null,
          averagePercent: null,
          stepAverages: [
            {
              trainingStepId: 7255,
              trainingStepOrder: 1,
              languageKey: "stp_0071_step_name",
              trainingStepType: "OBLIGATOIRE",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: "Conduite en vue exterieure",
              customDescription: null,
            },
            {
              trainingStepId: 7256,
              trainingStepOrder: 2,
              languageKey: "stp_0072_step_name",
              trainingStepType: "OBLIGATOIRE",
              totalSecondsElapsed: null,
              averagePercent: null,
              stepState: 0,
              customName: "Manoeuvres et stationnements",
              customDescription: null,
            },
          ],
          customDescription: "débuter ",
          customName: "Parcours pour les nuls",
          stepCount: 2,
          currentStepOrder: 0,
        },
      ],
    },
  ],
};

export const getStudentPreviews = [
  {
    id: 121,
    code: "VL1",
    version: "1.1.10",
    languageKey: "VL1_name",
    subscriptions: [
      {
        machine: null,
        totalSecondElapsed: 3600,
        averagePercent: null,
        languageKey: "jrn_100_journey_name",
        trainingResults: [
          {
            subscriptionId: 14,
            trainingId: 546,
            studentId: 730,
            
            subscriptedMachine: {
              id: 121,
              code: "VL1",
              version: "1.1.10",
              languageKey: "VL1_name",
            },
            totalSecondElapsed: 3600,
            averagePercent: null,
            stepAverages: [
              {
                trainingStepId: 5849,
                trainingStepOrder: 1,
                languageKey: "stp_100_1_step_name",
                trainingStepType: "ENTRAINEMENT",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: null,
                customDescription: null,
              },
              {
                trainingStepId: 5850,
                trainingStepOrder: 2,
                languageKey: "stp_100_2_step_name",
                trainingStepType: "ENTRAINEMENT",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: null,
                customDescription: null,
              },
            ],
            customDescription: null,
            customName: null,
            stepCount: 7,
            currentStepOrder: 0,
          },
          {
            subscriptionId: 15,
            trainingId: 554,
            studentId: 730,
            languageKey: "jrn_10_journey_name",
            subscriptedMachine: {
              id: 121,
              code: "VL1",
              version: "1.1.10",
              languageKey: "VL1_name",
            },
            totalSecondElapsed: 3660,
            averagePercent: null,
            stepAverages: [
              {
                trainingStepId: 5889,
                trainingStepOrder: 1,
                languageKey: "stp_10_1_step_name",
                trainingStepType: "ENTRAINEMENT",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: null,
                customDescription: null,
              },
              {
                trainingStepId: 5890,
                trainingStepOrder: 2,
                languageKey: "stp_10_2_step_name",
                trainingStepType: "ENTRAINEMENT",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: null,
                customDescription: null,
              },
            ],
            customDescription: null,
            customName: null,
            stepCount: 4,
            currentStepOrder: 0,
          },
        ],
      },
    ]
  },
  {
    id: 154,
    code: "CH2",
    version: "1.4.5",
    languageKey: "CH2_name",
    subscriptions: [
      {
        machine: null,
        totalSecondElapsed: 0,
        averagePercent: null,
        languageKey: "jrn_10_journey_name",
        trainingResults: [
          {
            subscriptionId: 17,
            trainingId: 671,
            studentId: 730,
            
            subscriptedMachine: {
              id: 154,
              code: "CH2",
              version: "1.4.5",
              languageKey: "CH2_name",
            },
            totalSecondElapsed: null,
            averagePercent: null,
            stepAverages: [
              {
                trainingStepId: 7255,
                trainingStepOrder: 1,
                languageKey: "stp_0071_step_name",
                trainingStepType: "OBLIGATOIRE",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: "Conduite en vue exterieure",
                customDescription: null,
              },
              {
                trainingStepId: 7256,
                trainingStepOrder: 2,
                languageKey: "stp_0072_step_name",
                trainingStepType: "OBLIGATOIRE",
                totalSecondsElapsed: null,
                averagePercent: null,
                stepState: 0,
                customName: "Manoeuvres et stationnements",
                customDescription: null,
              },
            ],
            customDescription: "débuter ",
            customName: "Parcours pour les nuls",
            stepCount: 2,
            currentStepOrder: 0,
          },
        ],
      },
    ]
  }
  ]

export const studentCalendar =
  '{"STUDENT":{"PER_ENTITY":{"6d3a21af-0780-4508-897c-9aa6a16ca8dd":{"PER_DATE":{"2022-09-01T00:00:00":{"TIME_SIMULATION_PER_STUDENT_PER_DAY":{"CurrentValue":0.0,"Type":"TIME"}}}}}}}';
