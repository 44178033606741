//#region react import
import { Document, Page, StyleSheet, Font, View } from "@react-pdf/renderer";
import Roboto from "../Pdf/Roboto-Medium.ttf";
import RobotoBold from "../Pdf/Roboto-Bold.ttf";
import RobotoItalic from "../Pdf/Roboto-Italic.ttf";
import OpenDyslexicRegular from "../Pdf/OpenDyslexic-Regular.ttf";
//#endregion

//#region component import
import GroupedPdfHeader from "./GroupedPdfHeader";
import GroupedPdfInformation from "./GroupedPdfInformation.component";
import GroupedPdfContent from "./GroupedPdfContent.component";
import PdfFooter from "../Pdf/PdfFooter";
//#endregion

//#region constants import
//#endregion

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: Roboto,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
    {
      src: RobotoItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
Font.register({
  family: "OpenDyslexic",
  fonts: [
    {
      src: OpenDyslexicRegular,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  body: {
    fontFamily: "Roboto",
    paddingTop: 80,
    paddingBottom: 80,
    fontSize: 10,
  },
  content: {
    paddingHorizontal: 35,
  },
});

// Create Document Component
const GroupedPdf = ({
  notationType,
  groupInfos,
  groupedPdfData,
  translations,
  groupWithDate,
  customFields,
  centerName,
  pdfInfos,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.body}
        wrap={true}
      >
        <GroupedPdfHeader
          groupInfos={groupInfos}
          groupWithDate={groupWithDate}
          translations={translations}
          centerName={centerName}
          pdfInfos={pdfInfos}
        />
        <PdfFooter
          translations={translations}
          pdfInfos={pdfInfos}
        />
        <View style={styles.content}>
          <GroupedPdfInformation
            groupInfos={groupInfos}
            translations={translations}
            groupWithDate={groupWithDate}
            groupedPdfData={groupedPdfData}
            customFields={customFields}
          />
          <GroupedPdfContent
            groupedPdfData={groupedPdfData}
            translations={translations}
            notationType={notationType}
          />
        </View>
      </Page>
    </Document>
  );
};

export default GroupedPdf;
