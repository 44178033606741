//#region react import
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatePicker from "../AcreosDatePicker/AcreosDatePicker.component";
//#endregion

//#region functions import
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { DAYS, MONTHS } from "../../Constants/DateConstants";
import { DATEPICKER_TYPE } from "../AcreosDatePicker/AcreosDatePicker.constants";
//#endregion

//#region style import
import "./AcreosDatePickerInput.style.scss";
//#endregion

/**
 *
 *
 *
 */

const AcreosDatepickerInput = ({
  styleOverride,
  label,
  selectedDateTime,
  setSelectedDateTime,
  selectedDateTimes,
  setSelectedDateTimes,
  errorMessage,
  isValid,
  disabledFutureDate,
  selectRange,
  rangeType,
  first,
  actionOnClickOutside,
  modifierClass,
}) => {
  //#region useState
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDateTimeString, setSelectedDateTimeString] = useState("");
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const refButton = useRef();
  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const getInputClass = () => {
    let className = "acreos-date-picker_input ";
    if (datePickerOpen) {
      className += " enabled ";
    }
    if (!isValid) {
      className += " error ";
    }
    return className;
  };

  const manageDateTimeStringFormat = (newValue, previousValue, month, dateTimeFromString) => {
    if (newValue.length > previousValue.length && newValue.length === 4) {
      newValue = newValue.substring(0, 4) + "-";
    }
    if (
      (newValue.length === 6 && newValue.length < previousValue.length) ||
      (newValue.length > 4 && newValue.length < 6)
    ) {
      if (parseInt(newValue[4]) > 1) {
        newValue = newValue.substring(0, 4) + "-0" + newValue.substring(4, newValue.length);
      } else if (month <= 12) {
        newValue = newValue.substring(0, 4) + "-" + newValue.substring(4, newValue.length);
      }
    } else if (newValue.length > 6 || (newValue.length > previousValue.length && newValue.length === 6)) {
      if (parseInt(newValue[6]) > dateTimeFromString.getDate().toString()[0]) {
        newValue =
          newValue.substring(0, 4) + "-" + newValue.substring(4, 6) + "-0" + newValue.substring(6, newValue.length);
      } else {
        newValue =
          newValue.substring(0, 4) + "-" + newValue.substring(4, 6) + "-" + newValue.substring(6, newValue.length);
      }
    }
    return newValue;
  };

  const handleChangeDateTimeString = (e) => {
    let previousValue = selectedDateTimeString?.replaceAll("-", "") ?? "";
    let newValue = e.target.value;
    newValue = newValue.replaceAll("-", "");
    if (
      selectedDateTimeString &&
      selectedDateTimeString.length > e.target.value.length &&
      selectedDateTimeString[selectedDateTimeString.length - 1] === "-"
    ) {
      newValue = newValue.substring(0, newValue.length - 1);
    }

    const year = parseInt(newValue.substring(0, 4));
    const month = parseInt(newValue.substring(4, 6));
    const day = parseInt(newValue.substring(6, 8));

    const dateTimeFromString = new Date(year ?? 0, month ?? 0, 0);

    // manage errors
    if (month > 12) return; // check month is in [0,12]
    if (day > dateTimeFromString.getDate()) return; // check day correspond to the number of day of the moth/year selected
    if (!newValue.match(/^\d{0,8}$/)) return; // only accept digital

    // place the "-" character at the right time
    newValue = manageDateTimeStringFormat(newValue, previousValue, month, dateTimeFromString);

    // create the dateTime from the string
    let newDate;
    if (
      newValue.length === 10 &&
      !newValue.substring(5, newValue.length).includes("00-00") &&
      !newValue.substring(5, newValue.length - 3).includes("00") &&
      !newValue.substring(8, newValue.length).includes("00")
    ) {
      newDate = new Date(newValue);
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0);
    } else if (newValue.length === 10) {
      dispatch(setErrorNotification({ message: getTranslations("incorrect_date", translations) }));
      newValue = "";
      newDate = null;
    }
    setSelectedDateTimeString(newValue);

    // manage the range dateTime
    let newSelectedDatetimes = [];
    if (selectRange && first) {
      newSelectedDatetimes.push(newDate);
      selectedDateTimes.length > 1
        ? newSelectedDatetimes.push(selectedDateTimes[selectedDateTimes.length - 1])
        : newSelectedDatetimes.push(null);
      setSelectedDateTimes(newSelectedDatetimes);
    } else if (selectRange && !first) {
      newSelectedDatetimes.push(newDate);
      selectedDateTimes.length > 0
        ? newSelectedDatetimes.unshift(selectedDateTimes[0])
        : newSelectedDatetimes.unshift(null);
      setSelectedDateTimes(newSelectedDatetimes);
    }

    if (newValue.length === 0) {
      setSelectedDateTime(null);
    }
    if (!newDate) {
      return;
    }
    newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    setSelectedDateTime(newDate);
  };

  const handleOpenModal = () => {
    setModalOpen(!datePickerOpen);
  };

  const setModalOpen = (newValue) => {
    setDatePickerOpen(newValue);
    if (datePickerOpen && !newValue && actionOnClickOutside) actionOnClickOutside();
  };

  const onInputBlur = () => {
    if (selectedDateTimes && selectedDateTimes?.length === 2 && actionOnClickOutside) {
      actionOnClickOutside();
    }
  };

  const isInputFocused = useCallback(() => {
    return document.activeElement === document.getElementById(label);
  }, [label]);

  const placeHolder =
    (selectedDateTimeString ?? "") +
    getTranslations("date_picker_field_name", translations).substring(selectedDateTimeString?.length ?? 0, 10);

  //#endregion

  //#region useEffect
  useEffect(() => {
    if (!selectRange) {
      if (selectedDateTime) setSelectedDateTimeString(DateTimeToIsoString(selectedDateTime));
      else setSelectedDateTimeString("");
    } else {
      if (selectedDateTimes?.length === 1 && !first) setSelectedDateTimeString("");
      if (selectedDateTimes?.length === 0) setSelectedDateTimeString("");
      if (selectedDateTimes?.length > 0 && first && selectedDateTime && !isInputFocused()) {
        setSelectedDateTimeString(DateTimeToIsoString(selectedDateTime));
      }
      if (selectedDateTimes?.length > 1 && !first && selectedDateTime && !isInputFocused())
        setSelectedDateTimeString(DateTimeToIsoString(selectedDateTime));
    }
  }, [selectedDateTime, selectRange, selectedDateTimes, first, isInputFocused]);
  //#endregion

  return (
    <AcreosDatePicker
      type={DATEPICKER_TYPE.SELECT_DAY}
      disabledFutureDate={disabledFutureDate}
      months={MONTHS.map((month) => ({
        name: getTranslations(month.traductionKey, translations),
        number: month.number,
      }))}
      days={DAYS.map((day) => ({
        name: getTranslations(day.traductionKey, translations),
        number: day.number,
      }))}
      selectedDateTime={selectedDateTime}
      setSelectedDateTime={setSelectedDateTime}
      selectedDateTimes={selectedDateTimes}
      setSelectedDateTimes={setSelectedDateTimes}
      setDatePickerOpen={setModalOpen}
      datePickerOpen={datePickerOpen}
      selectRange={selectRange}
      rangeType={rangeType}
      refOpenDatePicker={refButton}
      modifierClass={modifierClass}
    >
      <div className={getInputClass() + styleOverride}>
        <section>
          {label && (
            <label htmlFor={label}>
              <strong>{label}</strong>
            </label>
          )}
          <div data-placeholder={placeHolder}>
            <input
              id={label}
              value={selectedDateTimeString}
              onChange={handleChangeDateTimeString}
              onBlur={onInputBlur}
            />
            <i
              className={"icon-date_field"}
              onClick={handleOpenModal}
              date-picker-open={datePickerOpen.toString()}
              ref={refButton}
            />
          </div>
        </section>
        {errorMessage && <span className="full-width gsta-input-error-message">{!isValid ? errorMessage : " "}</span>}
      </div>
    </AcreosDatePicker>
  );
};
AcreosDatepickerInput.propTypes = {
  /** a style to complete or override the basic style of the input */
  styleOverride: PropTypes.string,
  /** the label of the input */
  label: PropTypes.string,
  /** the date selected by the datePicker */
  selectedDateTime: PropTypes.instanceOf(Date),
  /** function to set the date */
  setSelectedDateTime: PropTypes.func.isRequired,
  /** error message to display if the input isn't valid */
  errorMessage: PropTypes.string,
  /** a boolean to indiq if the input is correct or not */
  isValid: PropTypes.bool,
};
export default AcreosDatepickerInput;
