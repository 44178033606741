//#region react import
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosStepper from "../AcreosStepper/AcreosStepper.component";
import CreateCustomPdfStep1 from "../CreateCustomPdfStep1/CreateCustomPdfStep1.component";
import CreateCustomPdfStep3 from "../CreateCustomPdfStep3/CreateCustomPdfStep3.component";
import CreateCustomPdfStep4 from "../CreateCustomPdfStep4/CreateCustomPdfStep4.component";
import CreatePdfTemplateStep2Form from "../CreatePdfTemplateStep2/CreatePdfTemplateStep2.component";
import GstaLoaderPage from "../GstaLoaderPage/GstaLoaderPage.component";
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region services import
import { postTemplatePdf, putTemplatePdf } from "../../Services/SettingsService";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";

//#region constants import
import { CREATE_TEMPLATE_STEP_COUNT, CUSTOM_PDF_TEMPLATE } from "./CreateCustomPdfModal.constants";
//#endregion

//#region styles import
import "./CreateCustomPdfModal.styles.scss";
//#endregion

/**
 * The modal that allow to navigate the four step of the creation of a pdf template
 */
const CreateCustomPdfModal = ({
  modalOpen,
  setModalOpen,
  selectedPdfTemplate,
  setSelectedPdfTemplate,
  addPdfTemplate,
  updatePdfTemplate,
  pdfTemplates,
}) => {
  //#region useState
  const [currentStep, setCurrentStep] = useState(1);
  const [pdfTemplate, setPdfTemplate] = useState(CUSTOM_PDF_TEMPLATE);
  const [loading, setLoading] = useState(false);
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer.activeCenter.id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (modalOpen) {
      setCurrentStep(1);
      selectedPdfTemplate ? setPdfTemplate(selectedPdfTemplate) : setPdfTemplate(CUSTOM_PDF_TEMPLATE);
    }
  }, [modalOpen, selectedPdfTemplate]);
  //#endregion

  //#region functions
  const validateModalAction = () => {
    if (currentStep <= 3) setCurrentStep(currentStep + 1);
    else selectedPdfTemplate ? updatePdfModal() : createPdfTemplate();
  };

  const previousState = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
  };
  const renderCreateTemplateModalContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <CreateCustomPdfStep1
            pdfTemplate={pdfTemplate}
            setPdfTemplate={setPdfTemplate}
            nameIsCorrect={
              !pdfTemplates?.find((template) => template.id !== pdfTemplate.id && template.name === pdfTemplate.name)
            }
          />
        );
      case 2:
        return (
          <CreatePdfTemplateStep2Form
            pdfTemplate={pdfTemplate}
            setPdfTemplate={setPdfTemplate}
          />
        );
      case 3:
        return (
          <CreateCustomPdfStep3
            pdfTemplate={pdfTemplate}
            setPdfTemplate={setPdfTemplate}
          />
        );
      case 4:
        return (
          <CreateCustomPdfStep4
            pdfTemplate={pdfTemplate}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return <></>;
    }
  };

  const createPdfTemplate = async () => {
    setLoading(true);
    try {
      const newPdfTemplate = await postTemplatePdf(centerId, pdfTemplate);
      addPdfTemplate(newPdfTemplate);
      setModalOpen(false);
      dispatch(setSuccessNotification(getTranslations("success_create_pdf_template", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setLoading(false);
  };

  const updatePdfModal = async () => {
    setLoading(true);
    try {
      const newPdfTemplate = await putTemplatePdf(centerId, pdfTemplate);
      let currentPdfDefault = pdfTemplates.find((pdfTemplate) => pdfTemplate.isDefault);
      if (currentPdfDefault && pdfTemplate.isDefault && currentPdfDefault.id !== pdfTemplate.id) {
        let currentDefaultPdf = copyObject(currentPdfDefault);
        currentDefaultPdf.isDefault = !currentDefaultPdf.isDefault;
        updatePdfTemplate([newPdfTemplate, currentDefaultPdf]);
      } else {
        updatePdfTemplate([newPdfTemplate]);
      }
      dispatch(setSuccessNotification(getTranslations("success_update_pdf_template", translations)));
      setModalOpen(false);
    } catch (e) {
      console.error(e);
      dispatch(setSuccessNotification(getTranslations("error_update_pdf_template", translations)));
    }
    setLoading(false);
  };

  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <div>
      {loading && <GstaLoaderPage />}
      <GstaModal
        modalOpen={modalOpen}
        handleCloseModal={() => {
          setModalOpen(false);
          setSelectedPdfTemplate();
        }}
        title={
          selectedPdfTemplate
            ? getTranslations("eddit_custom_pdf_template", translations)
            : getTranslations("add_custom_template", translations)
        }
        cancelText={getTranslations("common_cancel", translations)}
        validText={
          currentStep < CREATE_TEMPLATE_STEP_COUNT
            ? getTranslations("next_button", translations)
            : getTranslations("common_validate", translations)
        }
        validIcon={currentStep < CREATE_TEMPLATE_STEP_COUNT ? "icon-arrow" : "icon-check"}
        handleValidate={validateModalAction}
        className={"create-template_modal"}
        validateDisabled={pdfTemplate.name.length === 0}
      >
        <div
          className={`back-button ${currentStep === 1 ? "disabled" : ""}`}
          onClick={previousState}
        >
          <i className="icon-navigation_back" />
          <span>{getTranslations("import_student_modal_go_back", translations)}</span>
        </div>
        <AcreosStepper
          stepperTitle={getTranslations("step_progress_step", translations)}
          currentStep={currentStep}
          stepCount={CREATE_TEMPLATE_STEP_COUNT}
          setCurrentStep={setCurrentStep}
        />
        {renderCreateTemplateModalContent()}
      </GstaModal>
    </div>
  );
};
CreateCustomPdfModal.propTypes = {};
export default CreateCustomPdfModal;
