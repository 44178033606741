//#region react import
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaTooltip from "../GstaToolTip/GstaToolTip.component";
//#endregion

//#region services import
//#endregion

//#region constants import
//#endregion

//#region style import
import "./SkillsCell.style.scss";
//#endregion

/**
 * Render skills of a step
 */

const SkillsCell = ({ value }) => {
  //#region state
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region constants
  const handledSkills = [...new Map(value.skills.map((skill) => [skill.skillNameKey, skill])).values()];
  //#endregion

  //#region functions
  const getSkillNames = () => {
    return handledSkills.map((skill, index) => (
      <span
        key={`skills-${index}`}
        id={`skills-${index}`}
      >
        <i className="icon-star_full" />
        {getTranslations(skill.skillNameKey, translations)}
      </span>
    ));
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="skills-cell">
      {handledSkills <= 3 || !value.skills ? (
        getSkillNames()
      ) : (
        <GstaTooltip toolTipContent={getSkillNames()}>
          <span>
            <i className="icon-star_full" /> {`${handledSkills.length} ${getTranslations("skills", translations)}`}
          </span>
        </GstaTooltip>
      )}
    </div>
  );
};
SkillsCell.propTypes = { value: PropTypes.object };
export default SkillsCell;
